import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import uniqBy from "lodash/uniqBy";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectCheckboxSearchableDropdown(props) {
  const { isEditMode, showSelectAllOption } = props;
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const isSelectAllOption = (option) => option.value === "select-all";
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const newValue = isChecked ? props.options : [];
    setSelectAllChecked(isChecked);
    props.setData(newValue);
  };

  const optionsWithSelectAll =
    showSelectAllOption && props.options.length > 0
      ? [{ label: "Select All", value: "select-all" }, ...props.options]
      : props.options;

  const noOptionsMessage =
    (!props.multiple && props.options.length === 0) ||
    (props.multiple &&
      props.value &&
      props.value.length === props.options.length)
      ? "No new subscriber to associate for selected content(s)"
      : "No new subscriber to associate";

  return (
    <Autocomplete
      id={props.id}
      clearIcon={props.clearIcon}
      limitTags={2}
      disablePortal={!props.multiple}
      disabled={props.disabled}
      multiple={props.multiple}
      options={optionsWithSelectAll}
      value={props.value}
      noOptionsText={noOptionsMessage}
      onChange={(event, newValue) => {
        let values = newValue;
        /*istanbul ignore next */
        if (props.from === "subscriber") {
          if (newValue.some((option) => option.value === "select-all")) {
            values = props.options;
            setSelectAllChecked(true);
          } else {
            values =
              props.multiple && props.value
                ? uniqBy(newValue, (obj) => obj.value)
                : newValue;
            setSelectAllChecked(false);
          }
        }

        props.setData(values);
      }}
      className={
        !isEditMode
          ? `disable-searchable-dropdown  ${props.className}`
          : `searchable-dropdown ${props.className}`
      }
      data-testid={props.id}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.label}
      renderOption={(prop, option, { selected }) => (
        <li
          {...prop}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ cursor: "default" }}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8, cursor: "pointer" }}
            checked={
              selected || (selectAllChecked && isSelectAllOption(option))
            }
            onChange={() => {
              if (isSelectAllOption(option)) {
                handleSelectAllChange({
                  target: { checked: !selectAllChecked },
                });
              } else {
                const selectedOptions = props.value || [];
                const newOptions = selectedOptions.includes(option)
                  ? selectedOptions.filter((item) => item !== option)
                  : [...selectedOptions, option];

                setSelectAllChecked(newOptions.length === props.options.length);
                props.setData(newOptions);
              }
            }}
          />
          {option.label}
        </li>
      )}
      sx={{
        width: props.width || 300,
        "&:hover": {
          cursor: "pointer",
        },
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={props.placeholder} />
      )}
    />
  );
}
