import { createContext } from "react";
import { MenuListItems } from "../common/Sidebar/MenuListItems";

export const AuthInit = {
  currentUser: null,
  setCurrentUser(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUser = user;
  },
  getCurrentUser() {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      return JSON.parse(localStorage.getItem("currentUser"));
    }
  },
  setRole(role) {
    localStorage.setItem("selectedRole", role.roleTypes[0].userType);
    localStorage.setItem("roleId", role.roleId);
    localStorage.setItem("roleName", role.name);
  },
  getRole() {
    const role = localStorage.getItem("selectedRole");
    return role ? Number(role) : 0;
  },
  setInternalRole(internalRole) {
    localStorage.setItem("selectedInternalRole", internalRole);
  },
  getPermissionId() {
    const role = Number(localStorage.getItem("selectedRole") || 0);
    const internalRole = Number(
      localStorage.getItem("selectedInternalRole") || 0
    );
    const whenNotRoles_3_5 = role === 4 && internalRole ? 6 : role;
    const permissionId = role === 3 && internalRole ? 5 : whenNotRoles_3_5;
    return permissionId ? Number(permissionId) : 0;
  },
  setModules(modules) {
    let modName = {
      59: "Manage Documents",
      64: "Content",
    };
    let commonModules = MenuListItems.map((item) => {
      item.features = modules.find((mod) =>
        [59, 64].includes(item.moduleId)
          ? mod.name === modName[item.moduleId]
          : mod.name === item.name
      )?.features;
      return item;
    });
    let parentModules = commonModules.filter(
      (item) => item.pModuleId === 0 && item.features
    );
    let childModules = commonModules.filter((item) => item.pModuleId !== 0);

    localStorage.setItem("rolesNavigation", parentModules);
    localStorage.setItem("rolesChildModules", childModules);

    childModules.forEach((child) => {
      parentModules.forEach((parent) => {
        if (parent.name === child.parentModuleName && !child.isSubMenu) {
          parent.childMenu.push(child);
          parent.features.push();
        } else if (parent.name === child.parentModuleName && child.isSubMenu) {
          parent.subMenu.push(child);
          parent.features.push();
        }
      });
    });

    parentModules.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
    localStorage.setItem(
      "modulePermissions",
      JSON.stringify([MenuListItems[0], ...parentModules])
    );
  },
  getModules() {
    return JSON.parse(localStorage.getItem("modulePermissions"));
  },
  setBrandImage(imageUrl) {
    localStorage.setItem("brandImage", imageUrl);
  },
  getBrandImage() {
    return localStorage.getItem("brandImage");
  },
};

export const AuthContext = createContext(AuthInit);
