import { useEffect, useMemo, useState } from "react";
import "../../../Dashboard/Dashboard.css";
import ApiServices from "../../../../services/ApiServices";
import moment from "moment";
import {
  ADMIN_USER,
  CONTENT,
  CONTENT_FAVORITE,
  CONTENT_STATUS,
  MASS_UPLOAD_CONTENT,
  MASS_UPLOAD_CONTENT_DOWNLOAD_PROCESSED_REQUEST,
} from "../../../../constants/api-constants";
import { toast } from "react-toastify";
import { useLoading } from "../../../../hooks/useLoader";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import DataTable from "../../../Tables/DataTable/DataTable";
import TableColumnModal from "../../../../common/TableColumnModal/TableColumnModal";
import { useHistory } from "react-router";
import {
  ContentPublishStatus,
  DocumentType,
  contentTypeOptions,
  typeOfContentOptions,
} from "../../constants";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { useContentContext } from "../Content.context";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AssociateSubscribers from "../../AssociateSubscribers/AssociateSubscribers";
import { usePageMode } from "../../../../hooks/usePageMode";
import UploadUserModal from "../../../../common/Modal/UploadUserModal";
import { useAuth } from "../../../../hooks/useAuth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExportToExcelHandler from "../../../../utilities/ExportToExcelHandler";
import RefreshIcon from "@mui/icons-material/Refresh";
import { SystemUsersEnum } from "../../../../constants/SystemUsers";

const ContentList = (props) => {
  const history = useHistory();
  const page = usePageMode();
  const auth = useAuth();
  const { showLoader } = useLoading();
  const contentContext = useContentContext();
  const { isMassUploadPermission } = props;
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const query = useMemo(() => {
    return props.query;
  }, [props.query]);
  const [userRole, setUserRole] = useState(null);
  const [curentUserId, setCurentUserId] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showEditAction, setShowEditAction] = useState(true);
  const [showFavoriteAction, setShowFavoriteAction] = useState(true);
  const [showAssociateSubscriberModal, setShowAssociateSubscriberModal] =
    useState(false);
  const [profileUserId, setProfileUserId] = useState(null);
  const [content, setContent] = useState(null);
  const [action, setAction] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setAction(actionName);
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const [openMassReuqestDownloader, setOpenMassReuqestDownloader] =
    useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [isLoadingProcessedData, setIsLoadinngProcessedData] = useState(false);
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  // SuperAdmin/Admin and Distributor user can have mass upload access
  // SuperAdmin/Admin only if they have Distributor role, Distributor has by default access -  (only for their own profiles only)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: "#fff",
    border: "1px solid #000",
    boxShadow: 24,
    p: 0,
  };

  useEffect(() => {
    if (
      [SystemUsersEnum.ADMIN, SystemUsersEnum.SUPERADMIN].includes(
        auth.getRole()
      )
    ) {
      userDetails();
    }
    ClearSelectionData();
  }, []);

  useEffect(() => {
    setTableColumnModalVisibility(props.showColumnPopup);
  }, [props.showColumnPopup]);

  useEffect(() => {
    getContentList();
    setSelectionColumns();
    if (history.location.pathname.includes("enterprisegroup")) {
      setShowEditAction(false);
      setShowFavoriteAction(false);
    }
  }, [query]);

  useEffect(() => {
    if (props.permissions?.includes("Update")) {
      setShowEditAction(true);
    } else {
      setShowEditAction(false);
    }
  }, [props.permissions]);

  useEffect(() => {
    page.setIsContentIdsSelected(GetAllSelectedIds("contentId").length > 0);
  }, [adminTable, page]);

  const onEditSuccess = (data) => {};

  const handleChangePageNumber = (event, newPage) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  /*istanbul ignore next */
  const userDetails = async () => {
    try {
      const user = await ApiServices.httpGet(ADMIN_USER);
      setUserRole(user?.result?.user?.userTypeName);
      setCurentUserId(user?.result?.user?.userId);
      setUserRoleId(user?.result?.user?.userTypeId);
    } catch (e) {
      console.log(e);
    }
  };

  const onSuccessToggle = (val) => {};
  /*istanbul ignore next */
  const getContentList = async (from = "") => {
    try {
      if (from === "openRequestPopup") {
        setOpenMassReuqestDownloader(true);
      }
      showLoader(true);
      let firstParam = 0;
      let secondParam = 0;
      if (
        window.location.pathname.includes("distributor") ||
        auth.getRole() === 3
      ) {
        firstParam = props.id;
        setProfileUserId(firstParam);
      } else if (
        window.location.pathname.includes("enterprisegroup") ||
        auth.getRole() === 5
      ) {
        firstParam = -1;
        secondParam = props.id;
        setProfileUserId(secondParam);
      }
      const adminData = await ApiServices.httpGet(
        `${CONTENT}/${firstParam}/${secondParam}/${encodeURIComponent(
          JSON.stringify(query)
        )}`
      );
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("contentId");
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.contentId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          ele.typeOfContentName =
            typeOfContentOptions.find(
              (item) => item.value === ele.typeOfContent
            )?.name || "";

          ele.contentTypeName =
            contentTypeOptions.find((item) => item.value === ele.contentType)
              ?.name || "";

          ele.tages = ele.contentTagList
            ?.flatMap((item) => item.tag)
            .join(", ");

          return ele;
        });
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
      showLoader(false);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      props.setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
          PageNumber: 1,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    props.setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    } else {
      let newData = { [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(newData));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
    props.onCancelTableColumnSelection(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let contentCols = selectedCols?.contents || [];
    if (contentCols) {
      setSelectedColumns(contentCols);
    }
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setAdminTableData((items) => {
        SelectionFilter(items, "multiple", checked, "contentId");
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setAdminTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };
  /*istanbul ignore next */
  const onFavoriteHandler = async (val, isFavorite) => {
    try {
      const response = await ApiServices.httpPut(CONTENT_FAVORITE, {
        contentId: val.contentId,
        isFavorite: !isFavorite,
      });

      if (response.responseStatus.isSuccess) {
        getContentList();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onDeleteHandler = (e, contentName) => {
    setContent(contentName);
    handleConfirmationOpen(
      "Delete",
      "Are you sure you want to delete this content?"
    );
  };
  /*istanbul ignore next */
  const actionHandler = async (actionName, val, isFavorite) => {
    switch (actionName) {
      case "Add to Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Remove Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Delete":
        if (val.publishStatus === ContentPublishStatus.DRAFT) {
          setContent(val);
          handleConfirmationOpen(
            "Delete",
            "Are you sure you want to delete this content?"
          );
        }
        break;
      case "Copy":
        props.onEdit(val, DocumentType.CONTENT);
        contentContext.copyContent(val);
        break;
      default:
    }
  };

  const deleteContent = async (e) => {
    try {
      const response = await ApiServices.httpDelete(CONTENT, {
        contentId: content.contentId,
        userId: content.userId,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getContentList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    contentName
  ) => {
    setContent(contentName);
    if (contentName.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the content as Inactive?"
      );
    } else {
      // No confirmation while Activate user
      changeContentStatus(contentName, "", 1);
    }
  };
  /*istanbul ignore next */
  const changeContentStatus = async (contentObj, reason, status) => {
    try {
      const response = await ApiServices.httpPut(CONTENT_STATUS, {
        contentId: contentObj.contentId,
        userId: contentObj.userId,
        status: status,
        reason: reason,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getContentList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  /*istanbul ignore next */
  useEffect(() => {
    if (openMassReuqestDownloader) {
      getRequests();
    }
  }, [openMassReuqestDownloader]);

  /*istanbul ignore next */
  const getRequests = async () => {
    try {
      setProcessedData([]);
      setIsLoadinngProcessedData(true);
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${MASS_UPLOAD_CONTENT}/${profileUserId}`
      );
      if (response?.responseStatus?.isSuccess) {
        setProcessedData(response?.result);
        setIsLoadinngProcessedData(false);
      } else {
        setProcessedData([]);
        setIsLoadinngProcessedData(false);
      }
      getContentList();
      showLoader(false);
    } catch (err) {
      showLoader(false);
      setIsLoadinngProcessedData(false);
      console.log("err", err);
    }
  };

  /*istanbul ignore next */
  const downloadProcessedRequests = async (requestId, isError) => {
    try {
      showLoader(true);
      let payload = {
        userId: profileUserId,
        requestId: requestId,
        isError: isError,
      };
      const response = await ApiServices.httpPost(
        MASS_UPLOAD_CONTENT_DOWNLOAD_PROCESSED_REQUEST,
        payload
      );
      if (response?.responseStatus?.isSuccess) {
        ExportToExcelHandler(
          response.result.file.fileContents,
          response.result.file.fileDownloadName
        );
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
    } catch (err) {
      showLoader(false);
      console.log("err", err);
    }
  };

  return (
    <div className="dashboard-main">
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="associate-subscribers">
            {isMassUploadPermission && (
              <Button
                data-testid="view-processed-requests-button-content"
                className="action-button"
                type="button"
                variant="contained"
                onClick={() => {
                  setOpenMassReuqestDownloader(true);
                }}
              >
                View Procesed Requests
              </Button>
            )}
            {isMassUploadPermission && (
              <UploadUserModal
                from="mass-upload-content"
                userId={profileUserId}
                title={"Mass Upload Content"}
                bulkUploadTemplate={"/templates/Content_Mass_Upload.xlsx"}
                getContentList={getContentList}
                massRequestDownloader={(status) => {
                  setOpenMassReuqestDownloader(status);
                }}
              />
            )}
            <Button
              disabled={!GetAllSelectedIds("contentId")?.length}
              data-testid="associate-save-button-content"
              className="action-button"
              type="button"
              variant="contained"
              onClick={() => {
                setShowAssociateSubscriberModal(true);
              }}
            >
              Associate Subscribers
            </Button>
            {/* )} */}
          </div>

          {showAssociateSubscriberModal && (
            <AssociateSubscribers
              selectedContentIds={GetAllSelectedIds("contentId")}
              clearSelection={() => {
                ClearSelectionData();
                getContentList();
              }}
              setClose={() => {
                setShowAssociateSubscriberModal(false);
              }}
              contentId={props.contentId}
            />
          )}
          <DataTable
            uniqueKey="contentId"
            onCheckboxChange={onCheckboxChangeHandler}
            userRoleId={userRoleId}
            columns={selectedColumns}
            currentUserId={curentUserId}
            query={query}
            handleChangePage={handleChangePageNumber}
            success={onSuccessToggle}
            adminTable={adminTable}
            onEditSuccess={onEditSuccess}
            totalPages={totalPages}
            pageSize={pageSize}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            userRole={userRole}
            actionHandler={actionHandler}
            showFavorite={showFavoriteAction}
            showView={true}
            showEdit={showEditAction}
            showDelete={true}
            onDelete={onDeleteHandler}
            onView={props.onView}
            onEdit={props.onEdit}
            onFavorite={onFavoriteHandler}
            onToggle={onToggleHandler}
            page={query.PageNumber}
          />
          {tableColumnModalVisibility && (
            <TableColumnModal
              componentName="contents"
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            showReason={action === "Inactivate"}
            onConfirm={async (e, reason) => {
              if (action === "Delete") {
                deleteContent(e);
              } else if (action === "Activate" || action === "Inactivate") {
                changeContentStatus(content, reason);
              }

              handleConfirmationClose();
            }}
            onCancel={() => {
              handleConfirmationClose();
            }}
          />

          {/* Download Processed Request Modal starts here */}
          <Modal
            open={openMassReuqestDownloader}
            onClose={() => {
              setOpenMassReuqestDownloader(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style} className="custom-modal">
              <div className="modal-header">
                <div className="modal-header-left">Processed Requests</div>
                <div className="modal-header-right">
                  <CloseOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenMassReuqestDownloader(false);
                    }}
                  />
                </div>
              </div>
              <div className="modal-body file-upload-body">
                <div className="modal-body downloader-request">
                  <div className="refresh-mod">
                    <Tooltip placement="bottom" title="Refresh list">
                      <RefreshIcon
                        onClick={() => {
                          getRequests();
                        }}
                      />
                    </Tooltip>
                  </div>
                  <Table aria-label="simple table" className="processed-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>FILE NAME</TableCell>
                        <TableCell>DATE</TableCell>
                        <TableCell>TOTAL CONTENT</TableCell>
                        <TableCell>SUCCESS CONTENT</TableCell>
                        <TableCell>ERROR CONTENT</TableCell>
                        <TableCell>STATUS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoadingProcessedData && !processedData?.length && (
                        <TableRow>
                          <TableCell className="no-data-found" colSpan={5}>
                            No data found
                          </TableCell>
                        </TableRow>
                      )}
                      {processedData?.map((item) => {
                        return (
                          <TableRow key="1">
                            <TableCell>{item.fileName}</TableCell>
                            <TableCell>
                              {item.createdOn === null
                                ? ""
                                : moment(item.createdOn).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>{item.totalRecords}</TableCell>
                            <TableCell>
                              {item.successCount ? (
                                <Tooltip placement="bottom" title="Download">
                                  <span
                                    className="download-link"
                                    onClick={() => {
                                      downloadProcessedRequests(
                                        item.requestId,
                                        false
                                      );
                                    }}
                                  >
                                    {item.successCount}
                                  </span>
                                </Tooltip>
                              ) : (
                                item.successCount
                              )}
                            </TableCell>
                            <TableCell>
                              {item.failCount ? (
                                <Tooltip placement="bottom" title="Download">
                                  <span
                                    className="error-link"
                                    onClick={() => {
                                      downloadProcessedRequests(
                                        item.requestId,
                                        true
                                      );
                                    }}
                                  >
                                    {item.failCount}
                                  </span>
                                </Tooltip>
                              ) : (
                                item.failCount
                              )}
                            </TableCell>
                            <TableCell>
                              <span
                                className={
                                  item.status ? "processed" : "in-processing"
                                }
                              >
                                {item.status ? "PROCESSED" : "PROCESSING"}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="modal-footer my-modal-footer"></div>
            </Box>
          </Modal>
          {/* Download Processed Request Modal ends here */}
        </div>
      </div>
    </div>
  );
};

export default ContentList;
