export const typeOfContentOptions = [
  {
    value: 7,
    name: "AUDIO",
    fileType: "",
    accept: [
      "audio/AAC",
      "audio/FLAC",
      "audio/M4A",
      "audio/MP4",
      "audio/MP3",
      "audio/WAV",
    ],
  },
  {
    value: 5,
    name: "EXCEL",
    fileType: "xlsx",
    accept: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
  },
  {
    value: 2,
    name: "FORMS_XFDL",
    fileType: "",
    accept: "application/xfdl",
  },
  { value: 8, name: "GIF", fileType: "gif", accept: "image/gif" },
  { value: 3, name: "JPG", fileType: "jpg", accept: "image/jpeg" },
  {
    value: 1,
    name: "PDF",
    fileType: "pdf",
    accept: "application/pdf",
  },
  { value: 9, name: "PNG", fileType: "png", accept: "image/png" },
  {
    value: 6,
    name: "PPT",
    fileType: "pptx",
    accept: [
      "application/vnd.ms-powerpoint",
      "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
  },
  {
    value: 10,
    name: "VIDEO",
    fileType: "",
    accept: ["video/mp4", "video/MOV", "video/F4V", "video/MKV", "video/WEBM"],
  },
  { value: 11, name: "WEBLINK", fileType: "", accept: "image/tiff" },
  {
    value: 4,
    name: "WORD",
    fileType: "docx",
    accept: [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
];

export const allAcceptedExtentionsDocuments = [
  "audio/M4A",
  "audio/FLAC",
  "audio/ALAC",
  "audio/mp3",
  "audio/MP4",
  "audio/WAV",
  "audio/WMA",
  "audio/AAC",
  "audio/DSD",
  "audio/PCM",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xfdl",
  "image/gif",
  "image/jpeg",
  "application/pdf",
  "image/png",
  "application/vnd.ms-powerpoint",
  "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
  "video/mp4",
  "video/MOV",
  "video/WMV",
  "video/AVCHD",
  "video/FLV",
  "video/F4V",
  "video/SWF",
  "video/MKV",
  "video/WEBM",
  "video/HTML5",
  "video/MPEG-2",
  "image/tiff",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const brandLogoAcceptedFormats = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
];

export const TypeofConstantENUM = {
  WEBLINK: "11",
};

export const contentTypeOptions = [
  { value: 1, name: "Free" },
  { value: 2, name: "Paid" },
];

export const previewTypeOptions = [
  { value: 1, name: "For 1 Page" },
  { value: 2, name: "For All page" },
  { value: 3, name: "Marketing Description" },
];

export const confirmationNoDocAttached =
  "Nothing will Preview. Are you sure you want continue?";
export const cofirmationToPublish = "Are you sure to publish this content?";
export const exitConfirmationMessage =
  "You have not saved your work. Are you sure you want to discard the changes?";
export const deleteSubscriptionConfirmationMessage =
  "Are you sure you want to delete the subscription details?";
export const deleteSubscriptionConfirmationMessages =
  "Are you sure you want to delete the document(s) ?";
export const publishCatalogConfirmationMessage =
  "The catalog/bundle consists of some unpublished item. Are you sure you want to proceed?";
export const SUBSCRIPTION_ENUM = {
  1: "Monthly",
  2: "Quarterly",
  3: "Semi-Annual",
  4: "Annual",
};

export const DocumentType = {
  CONTENT: 1,
  CATALOG: 2,
  BUNDLE: 3,
};

export const BreadCrumbRoutes = {
  "basic-information": "Basic Information",
  "internal-users": "User/Contacts",
  content: "Content List",
  ContentInformation: "Content Information",
  ContentPreview: "Content Preview",
  catalog: "Catalog List",
  CatalogInformation: "Catalog Information",
  ContentList: "Content List",
  bundle: "Bundle List",
  BundleInformation: "Bundle Information",
  CatalogList: "Catalog List",
  ManageSubscribers: "Manage Subscribers",
};

export const ContentPublishStatus = {
  DRAFT: 0,
  PUBLISHED: 1,
  MODIFIED: 2,
};

export const UserAddOnType = {
  HIGHLIGHTING_DURING_SHARE: 1,
};
