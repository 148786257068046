import { Switch } from "@mui/material";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {
  const { activeLabelText, inactiveLabelText, state, onChange, disabled, id } =
    props;
  return (
    <div className="toggle-switch-root">
      <Switch
        checkedIcon={<img src="/images/active.png" height={28} alt="active" />}
        icon={<img src="/images/inactive.png" height={28} alt="inactive" />}
        checked={state}
        onChange={onChange}
        disabled={disabled}
        name={id}
        id={id}
        data-testid={props.dataTestId ? props.dataTestId : "activeCheckbox"}
      />
      <div className="status-label">
        {state ? activeLabelText : inactiveLabelText}
      </div>
    </div>

    // <div className={props.state ? "toggle-switch-1" : "toggle-switch"}>
    //   <div style={{ width: "100px", height: "12px" }}>
    //     <input
    //       onChange={handleChange}
    //       checked={props.state}
    //       type="checkbox"
    //       className="toggle-switch-checkbox"
    //       id={props.id}
    //       data-testid="activeCheckbox"
    //     />
    //     <label
    //       className={`${
    //         props.state ? "toggle-switch-label" : "toggle-switch-label-1"
    //       } ${props.disabled ? "" : "enabled"}`}
    //       htmlFor={props.id}
    //     >
    //       <span className="toggle-switch-inner"></span>

    //       <span
    //         className={
    //           props.state ? "toggle-switch-switch" : "toggle-switch-switch-1"
    //         }
    //       ></span>
    //     </label>
    //     <span
    //       style={{
    //         marginLeft: "48px",
    //         position: "absolute",
    //         top: "-2px",
    //         left: "-10px",
    //         color: "#fff",
    //         fontSize: "12px",
    //         fontWeight: 500,
    //       }}
    //     >
    //       {props.state
    //         ? props.associate !== decision[1]
    //           ? status[1]
    //           : decision[1]
    //         : props.notassociate !== decision[2]
    //         ? status[2]
    //         : decision[2]}
    //     </span>
    //     {(props.associate === status[1] ||
    //       props.notassociate === status[2]) && (
    //       <div className="associate-overlay"></div>
    //     )}
    //   </div>
    // </div>
  );
};

export default ToggleSwitch;

// {props.state
//   ? props.associate !== decision[1]
//     ? "ACTIVE"
//     : "YES"
//   : props.associate !== decision[0]
//   ? "INACTIVE"
//   : "NO"}
// </span>
// {(props.associate === status[1] ||
// props.notassociate === status[0]) && (
// <div className="associate-overlay"></div>
// )}
