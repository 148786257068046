import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import "../Dashboard/Dashboard.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CreateAdminModal from "../../common/Modal/CreateAdminModal";
import ApiServices from "../../services/ApiServices";
import Tooltip from "../../common/workarounds/tooltip/PatchTooltip";
import AdminDataTable from "../Tables/AdminDataTable/AdminDataTable";
import {
  ADMIN_USER,
  ADMIN_USERS,
  ADMIN_EXPORT,
  // MASTER_DATA,
} from "../../constants/api-constants";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
  GetSelectedColumns,
} from "../../utilities/SelectionFilter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import {
  PermissionService,
  PermissionEnum,
  getModuleId,
} from "../../constants/permissionService";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import EditAdminModal from "../../common/Modal/EditAdmin/EditAdminModal";
import { useMasterData } from "../../hooks/useMasterData";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const fromProfileId = props.location?.pathname?.split("/");
  const { showLoader } = useLoading();
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [roleOptions, setRoleOptions] = useState([]);
  const [adminProfileData, setAdminProfileData] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState([]);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      userTypes: [1, 2, 3, 4, 5, 6, 7],
      userStatus: [0, 1],
      roles: [],
      AccountStatus: ["false", "true"],
    },
  });

  const [userRole, setUserRole] = useState("");
  const [curentUserId, setCurentUserId] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState("false");
  const masterDataContext = useMasterData();
  const masterData = masterDataContext.masterDataValue;
  const permissions = PermissionService(getModuleId(SystemUsersEnum.ADMIN));
  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    loadFilterOptions();
    userDetails();
    adminDetails();
    ClearSelectionData();
  }, [query]);

  const onSuccess = (data) => {
    if (data === 200) {
      adminDetails();
    }
  };

  const onEditSuccess = (data) => {
    if (data) {
      adminDetails();
    }
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const userDetails = async () => {
    try {
      const user = await ApiServices.httpGet(ADMIN_USER);
      setAdminProfileData(user?.result?.user);
      setUserRole(user?.result?.user?.userTypeName);
      setCurentUserId(user?.result?.user?.userId);
      setUserRoleId(user?.result?.user?.userTypeId);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const loadFilterOptions = () => {
    try {
      setRoleOptions(
        masterData?.result.roles.map((r) => {
          return {
            value: r.roleId,
            roleId: r.roleId,
            label: r.name,
          };
        })
      );
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const onSuccessToggle = (val) => {
    adminDetails();
  };

  const adminDetails = async () => {
    if (permissions && !permissions.includes(PermissionEnum.LIST)) {
      history.push("/dashboard");
      return;
    }
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${ADMIN_USERS}?query=${JSON.stringify(query)}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("userId");
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.userId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          return ele;
        });
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
      setSelectionColumns();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("userId");
      let queryState = query;
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.filter.selectedColumns = GetSelectedColumns(1);
      const exportedContent = await ApiServices.httpGet(
        `${ADMIN_EXPORT}?query=${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
      ClearSelectionData();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onFilterRoleChangeHandler = (values) => {
    setSelectedRoles(values);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };

  const onFilterAccountStatusChangeHandler = (values) => {
    setSelectedAccountStatus(values);
  };

  const applyFilterHandler = () => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        filter: {
          ...prevState.filter,
          userStatus: selectedStatus,
          userTypes: [],
          AccountStatus: selectedAccountStatus,
          roles: selectedRoles.map((r) => {
            return { roleId: r };
          }),
        },
      };
    });
  };

  const clearFilterHandler = () => {
    setSelectedRoles([]);
    setSelectedStatus([]);
    setSelectedAccountStatus([]);
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols.admin ? selectedCols.admin : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  return (
    <div className="dashboard-main">
      <div
        className="menu-bar"
        style={{
          display: "flex",

          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="welcome-main">
            <div className="welcome-message">
              <h5>Users</h5>
            </div>
            <div className="welcome-widgets">
              <div>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <div className="expanded-filter">
                    <div className="filter-options">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                          >
                            Filters will be applied to your grid
                          </ListSubheader>
                        }
                      >
                        <Accordion
                          expanded={expanded === "role"}
                          onChange={filterAccordianHandleChange("role")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Role
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="role"
                              options={roleOptions}
                              onChange={onFilterRoleChangeHandler}
                              selectedValues={selectedRoles}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "status"}
                          onChange={filterAccordianHandleChange("status")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Status
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="status"
                              options={[
                                { value: 1, label: "Active" },
                                { value: 0, label: "Inactive" },
                              ]}
                              onChange={onFilterStatusChangeHandler}
                              selectedValues={selectedStatus}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "accountStatus"}
                          onChange={filterAccordianHandleChange(
                            "accountStatus"
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Account Status
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="accountStatus"
                              options={[
                                { value: "true", label: "Locked" },
                                { value: "false", label: "Unlocked" },
                              ]}
                              onChange={onFilterAccountStatusChangeHandler}
                              selectedValues={selectedAccountStatus}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </List>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outlined"
                        className="action-button mr-4"
                        onClick={clearFilterHandler}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        className="action-button"
                        onClick={() => {
                          applyFilterHandler();
                          handleClose();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>

              {permissions && permissions.includes(PermissionEnum.CREATE) && (
                <CreateAdminModal
                  userRoleId={userRoleId}
                  userRole={userRole}
                  adminDetail={adminDetails}
                  onClick={props.onClick}
                  onSuccess={onSuccess}
                  masterData={masterData}
                />
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Filter">
                  <FilterAltOutlinedIcon onClick={handleClick} />
                </Tooltip>
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Change View">
                  <BorderColorIcon
                    data-testid="TableColumnSelectionIcon"
                    onClick={() => {
                      setTableColumnModalVisibility(true);
                    }}
                  />
                </Tooltip>
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Export to Excel">
                  <IosShareRoundedIcon
                    data-testid="IosShareRoundedIcon"
                    onClick={handleExport}
                  />
                </Tooltip>
              )}
            </div>
          </div>

          {permissions && permissions.includes(PermissionEnum.LIST) && (
            <AdminDataTable
              onCheckboxChange={(e, value) => {
                const { checked } = e.target;
                if (value === "all") {
                  setAdminTableData((items) => {
                    SelectionFilter(items, "multiple", checked);
                    return items.map((data) => {
                      return {
                        ...data,
                        isChecked: checked,
                      };
                    });
                  });
                } else {
                  setAdminTableData((items) => {
                    return items.map((user) => {
                      let userRecord = { ...user };
                      if (user.userId === value) {
                        SelectionFilter(user, "single", checked);
                        userRecord.isChecked = checked;
                      }
                      return userRecord;
                    });
                  });
                }
              }}
              userRoleId={userRoleId}
              currentUserId={curentUserId}
              query={query}
              handleChangePage={handleChangePageNumber}
              success={onSuccessToggle}
              adminTable={adminTable}
              onEditSuccess={onEditSuccess}
              totalPages={totalPages}
              pageSize={pageSize}
              onSearch={onSearchHandler}
              onSort={sortHandler}
              onPageSizeChange={pageSizeChangeHandler}
              userRole={userRole}
              columns={selectedColumns}
              permissions={permissions}
              PermissionEnum={PermissionEnum}
              masterData={masterData}
            />
          )}

          {tableColumnModalVisibility && (
            <TableColumnModal
              componentName="admin"
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          {fromProfileId?.length === 3 && adminProfileData && (
            <EditAdminModal
              userRoleId={userRoleId}
              isEditMode={true}
              userRole={userRole}
              data={adminProfileData}
              onEditSuccess={props.onEditSuccess}
              masterData={masterData}
              fromSettings={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
