import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import "../../..//Dashboard/Dashboard.css";
import ApiServices from "../../../../services/ApiServices";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  BUNDLE,
  BUNDLE_ASSOCIATED_CONTENT,
} from "../../../../constants/api-constants";
import { useAuth } from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import {
  GetAllSelectedIds,
  SelectionFilter,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import { useLoading } from "../../../../hooks/useLoader";
import DataTable from "../../../Tables/DataTable/DataTable";
import AddContentDialog from "../AddContentDialog/AddContentDialog";
import { TABS, MODE, COLUMN_TYPES } from "../../../../constants/common";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { useHistory } from "react-router";
import { useBundleContext } from "../Bundle.context";
import { ContentPublishStatus } from "../../constants";
import { SystemUsersEnum } from "../../../../constants/SystemUsers";
import TableColumnModal from "../../../../common/TableColumnModal/TableColumnModal";

const ListContent = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const { userId, contentId, catalogPublishStatus } = props;
  const { showLoader } = useLoading();
  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: true,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {},
  });
  const [listContentRecords, setListContentRecords] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const bundleContext = useBundleContext();
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [isRemove, setIsRemove] = useState(false);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);

  useEffect(() => {
    setSelectionColumns();
    listContentDetails();
    ClearSelectionData();
  }, []);

  useEffect(() => {
    listContentDetails();
    setSelectionColumns();
  }, [query]);

  useEffect(() => {
    setTableColumnModalVisibility(props.showColumnPopup);
  }, [props.showColumnPopup]);

  const listContentDetails = async () => {
    try {
      showLoader(true);
      let distId = 0;
      if (history.location.pathname.includes("enterprisegroup")) {
        distId = bundleContext.userId ? bundleContext.userId : props.distId;
      } else {
        distId = userId;
      }

      const listContentResponse = await ApiServices.httpGet(
        `${BUNDLE_ASSOCIATED_CONTENT}/${distId}/${contentId}/${JSON.stringify(
          query
        )}`
      );
      showLoader(false);
      setListContentRecords(listContentResponse?.result?.records);
      setTotalPages(listContentResponse?.result?.totalPages);
      setPageSize(listContentResponse?.result?.pageSize);
    } catch (e) {
      showLoader(false);
    }
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let listOfContentCols = selectedCols?.listcustombundledatacolumns || [];
    if (
      listOfContentCols &&
      history.location.pathname.includes("/ContentList")
    ) {
      listOfContentCols = selectedCols?.listcustombundledatacolumns || [];
    } else if (
      listOfContentCols &&
      !history.location.pathname.includes("/distributor")
    ) {
      if (!listOfContentCols.some((col) => col.fieldName === "distributor")) {
        listOfContentCols.splice(2, 0, {
          exportLabel: "Distributor",
          fieldName: "distributor",
          label: "Distributor",
          isSelected: true,
          type: COLUMN_TYPES.BASIC,
          showInSelection: true,
          sort: true,
          width: 145,
        });
      }
    }
    setSelectedColumns(listOfContentCols);
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    } else {
      let newData = { [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(newData));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const listContentDeleteHandler = async (id) => {
    try {
      let payload = {
        contentId: contentId,
        userId: userId,
        associateContentIds: [...id],
      };
      const response = await ApiServices.httpDelete(
        `${BUNDLE}/content`,
        payload
      );
      if (response?.responseStatus?.isSuccess) {
        ClearSelectionData();
        listContentDetails();
        toast.success(response?.responseStatus?.description);
      } else {
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      toast.success("Internal server error.");
    }
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setListContentRecords((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setListContentRecords((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };
  /*istanbul ignore next */
  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };
  /*istanbul ignore next */
  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  /*istanbul ignore next */
  const handleCancelClick = () => {
    bundleContext.setSelectedTab(TABS.BUNDLE);
    bundleContext.setBundleMode(MODE.LIST);

    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("bundle")
      )}${TABS.BUNDLE}`
    );
  };

  return (
    <div className="dashboard-main">
      <Grid display={"flex"} justifyContent={"space-between"}>
        <div className="welcome-main">
          <div className="welcome-message">
            <h5>List of Content/Catalog for {props.bundleName || "NA"}</h5>
          </div>
        </div>
        <div className="action-items">
          {auth.getRole() !== SystemUsersEnum.ENTERPRISE && (
            <Button
              data-testid="removeContentBtn"
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setIsRemove(true);
              }}
              disabled={
                props.publishStatus === ContentPublishStatus.PUBLISHED ||
                GetAllSelectedIds("contentId").length < 1 ||
                (bundleContext.selectedTab === TABS.CONTENT_LIST &&
                  bundleContext.catalogMode === MODE.VIEW)
              }
            >
              Remove Content/Catalog
            </Button>
          )}
          {auth.getRole() !== SystemUsersEnum.ENTERPRISE && (
            <Button
              data-testid="addContentBtn"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenDialog(true);
              }}
              disabled={
                catalogPublishStatus === ContentPublishStatus.MODIFIED ||
                (bundleContext.selectedTab === TABS.CONTENT_LIST &&
                  bundleContext.catalogMode === MODE.VIEW)
              }
            >
              Add Content/Catalog
            </Button>
          )}
          <Button
            data-testid="cancel-action-catalog"
            variant="outlined"
            className="action-button"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
      </Grid>
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="welcome-main">
            <div className="welcome-widget-main">
              <div className="welcome-widgets"></div>
            </div>
          </div>
          <div className="manage-subscriber-table">
            {openDialog && (
              <AddContentDialog
                userId={userId}
                query={query}
                open={openDialog}
                contentId={contentId}
                listContentData={listContentRecords}
                closeDialogHandler={(status) => {
                  setOpenDialog(status);
                  listContentDetails();
                  ClearSelectionData();
                }}
              />
            )}
            <DataTable
              uniqueKey="contentId"
              adminDetails={listContentDetails}
              onCheckboxChange={onCheckboxChangeHandler}
              columns={selectedColumns}
              query={query}
              adminTable={listContentRecords}
              onSearch={onSearchHandler}
              onSort={sortHandler}
              onPageSizeChange={pageSizeChangeHandler}
              handleChangePage={handleChangePageNumber}
              totalPages={totalPages}
              pageSize={pageSize}
              publishStatus={props.publishStatus}
              showEdit={true}
              showView={true}
              onView={props.onViewHandlerList}
              onEdit={props.onEditHandlerList}
            />
            {tableColumnModalVisibility && (
              <TableColumnModal
                componentName="listcustombundledatacolumns"
                isOpen={tableColumnModalVisibility}
                onCancelTableColumnSelection={onCancelTableColumnSelection}
                onSaveTableColumnSelection={onSaveTableColumnSelection}
                selection={selectedColumns}
              />
            )}
            <Confirmation
              open={isRemove}
              handleClose={() => {
                setIsRemove(false);
              }}
              title={`Are you sure you want to remove the content/catalog from the bundle?`}
              cancelButtonLabel="No"
              confirmButtonLabel="Yes"
              onConfirm={async () => {
                listContentDeleteHandler(GetAllSelectedIds("contentId"));

                setIsRemove(false);
              }}
              onCancel={() => {
                setIsRemove(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListContent;
