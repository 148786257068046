import React, { useState, useEffect } from "react";
import "./SignInWidget.css";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import ApiServices from "../../services/ApiServices";
import {
  VALIDATE_ACTIVATION_CODE,
  ACTIVATE_ACCOUNT,
} from "../../constants/api-constants";
import { toast } from "react-toastify";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ActivateUser = () => {
  const history = useHistory();
  const SCREEN_ENUM = ["loading", "activate", "invalid"];
  const [screen, setScreen] = useState(SCREEN_ENUM[0]);
  const [postSubmitLoading, setPostSubmitLoading] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!#@$%&])[a-zA-Z\d#!@$%&-]{8,25}$/;
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  useEffect(() => {
    if (query.get("code")) {
      onValidateCode(query.get("code"));
    } else {
      history.push("/login");
    }
  }, []);

  const onValidateCode = async (code) => {
    try {
      setPostSubmitLoading(true);
      let sanitizedString = code.replace(/ /g, "+");
      let encodedCode = sanitizedString;
      let payload = {
        code: encodedCode,
      };
      const response = await ApiServices.httpPost(
        VALIDATE_ACTIVATION_CODE,
        payload
      );
      if (response.responseStatus?.isSuccess && response?.result) {
        setActivationCode(encodedCode);
        setPostSubmitLoading(false);
        setScreen(SCREEN_ENUM[1]);
      } else {
        setPostSubmitLoading(true);
        setScreen(SCREEN_ENUM[2]);
        setErrMsg(response?.responseStatus?.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const onActivateAccount = async () => {
    try {
      setPostSubmitLoading(true);
      let payload = {
        code: activationCode,
        password: password,
        confirmPassword: confirmPassword,
      };
      const response = await ApiServices.httpPost(ACTIVATE_ACCOUNT, payload);
      if (response?.result) {
        setPostSubmitLoading(true);
        toast.success(response?.responseStatus?.description);
        setTimeout(() => {
          history.push("/login");
        }, 5000);
      } else {
        setPostSubmitLoading(false);
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const handleIconHover = () => {
    if (!isMobileDevice()) {
      setTooltipOpen(true);
    }
  };

  const handleIconLeave = () => {
    if (!isMobileDevice()) {
      setTooltipOpen(false);
    }
  };

  const handleIconTouch = () => {
    setTooltipOpen(!isTooltipOpen);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 50000);
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowConfirmPassword = () => {
    setConfirmShowPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  return (
    <div className="myContainer">
      {/* <div>
        <ToastContainer closeButton={true} position="top-right" />
      </div> */}
      <Container maxWidth="md" className="main-grid-container">
        <Grid
          container
          justifyContent="center"
          className={screen === SCREEN_ENUM[0] ? "" : "main-grid"}
        >
          {screen === SCREEN_ENUM[0] && (
            <div style={{ fontSize: "25px" }}>Validating the link....</div>
          )}

          {screen !== SCREEN_ENUM[0] && (
            <form className="activate-form" onSubmit={onActivateAccount}>
              <Grid item xs={5} md={5} className="grid-left">
                <img
                  src="/images/onivas-logo.png"
                  alt="logo"
                  className="side-logo logo-login-position"
                />
                <Typography className="login-para" varient="h5" paragraph>
                  Software Solutions that Simplify Insurance
                </Typography>
                <div className="cloud-container">
                  <img
                    className="cloud-container-position"
                    src="/images/login-ring.png"
                    alt="logo ring"
                  />
                  <div className="cl1"></div>
                  <div className="cl2"></div>
                  <div className="cl3"></div>
                  <div className="cl4"></div>
                  <div className="cl5"></div>
                </div>
              </Grid>

              <Grid item xs={7} md={7} className="login-form">
                {screen === SCREEN_ENUM[1] && (
                  <>
                    <div className="login-form-content">
                      <p className="reset-heading">Activate Account</p>
                    </div>
                    <div
                      id="custom-signin"
                      class="custom-auth-container"
                      style={{ display: "block" }}
                    >
                      <div class="custom-fieldset">
                        <label htmlFor="password">
                          New Password
                          <MandatoryField />
                        </label>
                        <TextField
                          autoComplete="off"
                          placeholder="Enter new password"
                          disabled={postSubmitLoading}
                          type={showPassword ? "text" : "password"}
                          id="password"
                          value={password}
                          onChange={(e) => {
                            if (e.target.value.length > 25) {
                              return;
                            }
                            setPassword(e.target.value);
                          }}
                          variant="outlined"
                          data-testid="password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={postSubmitLoading}
                                  onClick={handleShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffOutlinedIcon
                                      data-testid="hidePasswordForgot"
                                      fontSize="small"
                                    />
                                  ) : (
                                    <VisibilityOutlinedIcon
                                      data-testid="showPasswordForgot"
                                      fontSize="small"
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <InlineMessage
                          error={
                            password && !passwordRegex.test(password)
                              ? "Invalid Password"
                              : ""
                          }
                        />
                      </div>
                      <div class="custom-fieldset">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                          <MandatoryField />
                        </label>
                        <TextField
                          autoComplete="off"
                          placeholder="Enter confirm password"
                          disabled={postSubmitLoading}
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => {
                            if (e.target.value.length > 25) {
                              return;
                            }
                            setConfirmPassword(e.target.value);
                          }}
                          variant="outlined"
                          data-testid="confirmPassword"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={postSubmitLoading}
                                  onClick={handleShowConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOffOutlinedIcon
                                      data-testid="hideConfirmPasswordForgot"
                                      fontSize="small"
                                    />
                                  ) : (
                                    <VisibilityOutlinedIcon
                                      data-testid="showConfirmPasswordForgot"
                                      fontSize="small"
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <InlineMessage
                          error={
                            confirmPassword && confirmPassword !== password
                              ? "Passwords does not match"
                              : ""
                          }
                        />
                      </div>
                      <>
                        <Tooltip
                          placement="bottom-start"
                          title={
                            <div className="custom-icon-info">
                              <p>Your password must contain:-</p>
                              <ul>
                                <li>Minimum 8 characters.</li>
                                <li>At least one number.</li>
                                <li>At least one uppercase character.</li>
                                <li>
                                  At least one of the following special
                                  characters - "!", "#", "@", "$", "%", "&".
                                </li>
                              </ul>
                            </div>
                          }
                          open={isTooltipOpen}
                          onClose={() => setTooltipOpen(false)}
                          onClick={() => setTooltipOpen(!isTooltipOpen)}
                          onMouseEnter={handleIconHover}
                          onMouseLeave={handleIconLeave}
                          onTouchStart={handleIconTouch}
                          leaveTouchDelay={5000}
                        >
                          <div
                            className="help-text-width"
                            onMouseEnter={handleIconHover}
                            onMouseLeave={handleIconLeave}
                            onTouchStart={handleIconTouch}
                          >
                            <InfoOutlinedIcon className="custom-info-icon help-text-color" />
                            <div className="help-text-cus-position">
                              <span className="help-text-color">
                                Need help?
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      </>
                      <div class="custom-fieldset">
                        <Button
                          disabled={
                            !activationCode ||
                            !passwordRegex.test(password) ||
                            !passwordRegex.test(confirmPassword) ||
                            password !== confirmPassword ||
                            postSubmitLoading
                          }
                          class="sign-in"
                          id="sign-in-id"
                          type="submit"
                          variant="contained"
                          color="primary"
                          data-testid="activate-account"
                          onClick={(e) => {
                            onActivateAccount();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {screen === SCREEN_ENUM[2] && (
                  <>
                    <div
                      id="custom-signin"
                      class="custom-auth-container error-login"
                      style={{ display: "block" }}
                    >
                      <div>{errMsg}</div>
                    </div>
                  </>
                )}
              </Grid>
            </form>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ActivateUser;
