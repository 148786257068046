export const SETTING_KEYS = {
  OTP_VALID_IN_SECONDS: "OTP_VALID_IN_SECONDS",
};

export const COLUMN_TYPES = {
  BASIC: 1,
  EMAIL: 2,
  MULTI_VALUE: 3,
  STATUS_CHANGE: 4,
  ACTION: 5,
  OBJECT: 6,
  DATE: 7,
  BOOLEAN: 8,
  DYNAMIC_ACTION: 9,
  UNBLOCK_TOGGLE: 10,
  ACTIVE_INACTIVE_LABEL: 11,
  DOCTYPE: 12,
  STYLED_BASIC: 13,
  ENUM: 14,
};

export const TABS = {
  BASIC: "basic-information",
  INTERNAL_USERS: "internal-users",
  CONTENT: "content",
  CONTENT_INFORMATION: "ContentInformation",
  CONTENT_PREVIEW: "ContentPreview",
  ENROLLED_USERS: "ManageSubscribers",
  CATALOG: "catalog",
  CATALOG_INFORMATION: "CatalogInformation",
  CONTENT_LIST: "ContentList",
  BUNDLE: "bundle",
  BUNDLE_INFORMATION: "BundleInformation",
};

export const MODE = {
  ADD: "Add",
  EDIT: "Edit",
  LIST: "List",
  VIEW: "View",
};

// Filter options for role list

export const roleFilterOptions = [
  {
    value: 1,
    label: "Super Admin",
    roleId: 1,
  },
  {
    value: 2,
    label: "Admin",
    roleId: 3,
  },
  {
    label: "Distributor",
    roleId: 3,
    value: 3,
  },
  {
    value: 4,
    label: "Enterprise Group",
    roleId: 4,
  },
  {
    label: "Distributor Internal User",
    value: 5,
    roleId: 5,
  },
  {
    label: "Enterprise Internal User",
    value: 6,
    roleId: 6,
  },
  {
    label: "Subscriber",
    value: 7,
    roleId: 7,
  },
];

export const approvalFilterOptions = [
  {
    value: 1,
    label: "Monthly",
  },
  {
    value: 2,
    label: "Quarterly",
  },
  {
    label: "Semi Annual",
    value: 3,
  },
  {
    value: 4,
    label: "Annual",
  },
];

export const decision = { 1: "YES", 2: "NO" };
export const status = { 1: "ACTIVE", 2: "INACTIVE" };

export const APPROVAL_ACTION = {
  SAVE: "Save",
  APPROVE: "Approve",
  REJECT: "Reject",
};

export const execeptionMessage = "Internal server error";

export const catalogLobFilter = [
  {
    value: 13,
    lineOfBusinessId: 13,
    label: "Agency",
  },
  {
    value: 14,
    lineOfBusinessId: 14,
    label: "Applicant Information",
  },
  {
    value: 3,
    lineOfBusinessId: 15,
    label: "Auto",
  },
  {
    value: 4,
    lineOfBusinessId: 16,
    label: "Aviation",
  },
  {
    value: 5,
    lineOfBusinessId: 17,
    label: "Benefits",
  },
  {
    value: 6,
    lineOfBusinessId: 18,
    label: "BOP",
  },
  {
    lineOfBusinessId: 19,
    label: "BOR",
  },
  {
    lineOfBusinessId: 20,
    label: "Builders Risk",
  },
  {
    lineOfBusinessId: 21,
    label: "Business Auto",
  },
  {
    lineOfBusinessId: 22,
    label: "Business Income",
  },
  {
    lineOfBusinessId: 23,
    label: "Contractors",
  },
  {
    lineOfBusinessId: 24,
    label: "Crime",
  },
  {
    lineOfBusinessId: 25,
    label: "Cyber",
  },
  {
    lineOfBusinessId: 26,
    label: "D&O",
  },
  {
    lineOfBusinessId: 27,
    label: "Dental; Vision",
  },
  {
    lineOfBusinessId: 28,
    label: "DISA",
  },
  {
    lineOfBusinessId: 29,
    label: "Disclosure",
  },
  {
    lineOfBusinessId: 30,
    label: "Dwelling",
  },
  {
    lineOfBusinessId: 31,
    label: "Dwelling; Homeowners",
  },
  {
    lineOfBusinessId: 32,
    label: "Earthquake",
  },
  {
    lineOfBusinessId: 33,
    label: "Edp",
  },
  {
    lineOfBusinessId: 34,
    label: "EPLI",
  },
  {
    lineOfBusinessId: 35,
    label: "Equipment",
  },
  {
    lineOfBusinessId: 36,
    label: "Farm",
  },
  {
    lineOfBusinessId: 37,
    label: "Fcra",
  },
  {
    lineOfBusinessId: 38,
    label: "Fiduciary",
  },
  {
    lineOfBusinessId: 39,
    label: "Flood",
  },
  {
    lineOfBusinessId: 40,
    label: "Fraud",
  },
  {
    lineOfBusinessId: 41,
    label: "Garage",
  },
  {
    lineOfBusinessId: 42,
    label: "Glass",
  },
  {
    lineOfBusinessId: 43,
    label: "Homeowners",
  },
  {
    lineOfBusinessId: 44,
    label: "Homeowners; Dwelling",
  },
  {
    lineOfBusinessId: 45,
    label: "Hotel",
  },
  {
    lineOfBusinessId: 46,
    label: "Inland Marine",
  },
  {
    lineOfBusinessId: 47,
    label: "Instructions",
  },
  {
    lineOfBusinessId: 48,
    label: "International",
  },
  {
    lineOfBusinessId: 49,
    label: "Liability",
  },
  {
    lineOfBusinessId: 50,
    label: "Life",
  },
  {
    lineOfBusinessId: 51,
    label: "Liquor",
  },
  {
    lineOfBusinessId: 52,
    label: "Loss",
  },
  {
    lineOfBusinessId: 53,
    label: "Marine",
  },
  {
    lineOfBusinessId: 54,
    label: "Mobile Home",
  },
  {
    lineOfBusinessId: 55,
    label: "Mortality",
  },
  {
    lineOfBusinessId: 56,
    label: "Personal Auto",
  },
  {
    lineOfBusinessId: 57,
    label: "Professional Liability",
  },
  {
    lineOfBusinessId: 58,
    label: "Proof",
  },
  {
    lineOfBusinessId: 59,
    label: "Property",
  },
  {
    lineOfBusinessId: 60,
    label: "Property / Liability",
  },
  {
    lineOfBusinessId: 61,
    label: "Restaurant",
  },
  {
    lineOfBusinessId: 62,
    label: "Schedule",
  },
  {
    lineOfBusinessId: 63,
    label: "Survey",
  },
  {
    lineOfBusinessId: 64,
    label: "Tech E&O",
  },
  {
    lineOfBusinessId: 65,
    label: "Transportation",
  },
  {
    lineOfBusinessId: 66,
    label: "Umbrella",
  },
  {
    lineOfBusinessId: 67,
    label: "Undertaking",
  },
  {
    lineOfBusinessId: 68,
    label: "Valuable Papers",
  },
  {
    lineOfBusinessId: 69,
    label: "Watercraft",
  },
  {
    lineOfBusinessId: 70,
    label: "Wind",
  },
  {
    lineOfBusinessId: 71,
    label: "Workers Comp",
  },
];

export const catalogTypeOfForm = [
  {
    value: 1,
    typeOfFormId: 1,
    label: "Agency",
  },
  {
    value: 2,
    typeOfFormId: 2,
    label: "Agriculture",
  },
  {
    value: 3,
    typeOfFormId: 4,
    label: "BOND",
  },
  {
    value: 4,
    typeOfFormId: 5,
    label: "Commercial",
  },
  {
    value: 5,
    typeOfFormId: 6,
    label: "Commercial Specialty",
  },
  {
    value: 6,
    typeOfFormId: 7,
    label: "Flood",
  },
  {
    value: 7,
    typeOfFormId: 8,
    label: "LHA",
  },
  {
    value: 8,
    typeOfFormId: 9,
    label: "Loss ",
  },
  {
    value: 9,
    typeOfFormId: 10,
    label: "P/C",
  },
  {
    value: 10,
    typeOfFormId: 11,
    label: "Personal",
  },
  {
    value: 11,
    typeOfFormId: 12,
    label: "Proof",
  },
];

export const DistConfirmationMessage =
  "Do you want to approve this New Registration with Distributor New User Role? As this role will have only limited access.";

export const EGConfirmationMessage =
  "Do you want to approve this New Registration with Enterprise Group New User Role? As this role will have only limited access.";
