import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import MultiSelectDropDown from "../../../../common/MultiSelectDropDown/MultiSelectDropDown";
import { useFormik } from "formik";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AddEditContent.css";
import { a11yProps } from "../../../../common/TabPanel/a11yProps";
import TabPanel from "../../../../common/TabPanel/TabPanel";
import { ContentSchema } from "../../../../common/ValidationSchema/ContentSchema";
import { useLoading } from "../../../../hooks/useLoader";
import { useMasterData } from "../../../../hooks/useMasterData";
import {
  CONTENT,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
} from "../../../../constants/api-constants";
import ApiServices from "../../../../services/ApiServices";
import { toast } from "react-toastify";
import {
  getFileExtension,
  getFrequency,
  handleStateChange,
} from "../../../../utilities/common";
import DocumentViewer from "../../../../common/DocumentViewer/DocumentViewer";
import { MODE, TABS } from "../../../../constants/common";
import { useContentContext } from "../Content.context";
import { useHistory, useParams } from "react-router";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import FileUploader from "../../../../common/FileUploader/FileUploader";
import TagsInput from "../../../../common/TagsInput/TagsInput";
import ManageSubscriber from "../ManageSubscriber/ManageSubscriber";
import {
  previewTypeOptions,
  cofirmationToPublish,
  confirmationNoDocAttached,
  contentTypeOptions,
  typeOfContentOptions,
  ContentPublishStatus,
  TypeofConstantENUM,
} from "../../constants";
import XFDLViewer from "../../../../common/XFDLViewer/XFDLViewer";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { formatDate } from "../../../../utilities/formatter";
import CurrencyTextbox from "../../../../common/CurrencyTextbox/CurrencyTextbox";
import { usePageMode } from "../../../../hooks/usePageMode";
import Select from "@mui/material/Select";
import { AudioPreview } from "../../../../common/AudioPreview/AudioPreview";
import { VideoPreview } from "../../../../common/VideoPreview/VideoPreview";

function AddEditContent(props) {
  const {
    id,
    distributorId,
    contentId,
    mode,
    onEdit,
    onSave,
    onCancel,
    onNext,
    setSelectedSubTab,
    signUpApproval,
  } = props;
  const history = useHistory();
  const auth = useAuth();
  const page = usePageMode();
  const { showLoader, show } = useLoading();
  const contentContext = useContentContext();
  const { subTabId } = useParams();
  const timerId = useRef(null);
  const [selectedTab, setSelectedTab] = useState(TABS.CONTENT_INFORMATION);
  const [file, setFile] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [acceptFileType, setAcceptFileType] = useState("");
  const [showEditAction, setShowEditAction] = useState(true);
  const [fileContent, setFileContent] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const masterDataContext = useMasterData();
  const allowedContentTypes = [4, 5, 6, 1, 8, 9, 3];

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setAction(actionName);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setAction("");
    setConfirmation(false);
  };
  const handleNextButtonClick = () => {
    const requiresAttachment = formik.values.typeOfContent;
    const hasAttachment = !!formik.values.filePath;

    if (
      requiresAttachment &&
      !hasAttachment &&
      formik.values.typeOfContent !== "11" // For web link
    ) {
      // Show the warning message
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };
  const [action, setAction] = useState("");
  const dataFetchedRef = useRef(false);

  const handleCancelClick = () => {
    contentContext.setSelectedTab(TABS.CONTENT_LIST);
    contentContext.setContentMode(MODE.LIST);

    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("content")
      )}${TABS.CONTENT}`
    );
  };

  const formik = useFormik({
    initialValues: {
      contentId: 0,
      typeOfContent: "",
      formNumber: "",
      name: "",
      userId: id,
      typeOfFormList: [],
      lineOfBusinessList: [],
      countryId: 1,
      stateId: "",
      latestVersion: "",
      marketingDescription: "",
      webLink: "",
      keywords: "",
      tages: "",
      fillable: 1,
      file: null,
      fileStream: null,
      filePath: "",
      isFileDeleted: true,
      createdOn: null,
      updatedOn: null,
      numberOfEnrolledUser: 0,
      isPublic: false,
      isPreview: false,
      isDisplayPublic: false,
      contentType: 0,
      previewType: 0,
      oneTimeCost: 0,
      subscriptions: [],
      baseValue: 0,
      actualValue: 0,
      frequency: 0,
      status: 0,
      isRecurring: false,
      states: [],
      dirty: false,
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: ContentSchema,
  });

  const [typeOfFormOptions, setTypeOfFormOptions] = useState([]);
  const [lobOptions, setLobOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    stateNames &&
    stateNames.length > 0 &&
    selected.length === stateNames.length - 1;
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  const fillableOptions = [
    { value: 1, name: "Non-Fillable" },
    { value: 2, name: "Fillable" },
    { value: 3, name: "Fillable-Mapped" },
  ];
  const frequencyOptions = [
    { value: 1, name: "Monthly" },
    { value: 2, name: "Quarterly" },
    { value: 3, name: "Semi Annual" },
    { value: 4, name: "Annual" },
  ];

  useEffect(() => {
    loadDropdownOptions();
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (contentId > 0) {
      getContentDetail();

      // Hide Edit button for enterprise user
      if (history.location.pathname.includes("enterprisegroup")) {
        setShowEditAction(false);
      }
    } else {
      formik.setValues({
        ...formik.values,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
      });
      setSelectedKeywords([]);
      setSelectedTags([]);
    }
    formik.validateForm();

    if (props.permissions?.includes("Update")) {
      setShowEditAction(true);
    } else {
      setShowEditAction(false);
    }

    return () => {
      //Clearing a timeout
      clearTimeout(timerId.current);
    };
  }, [contentId]);

  useEffect(() => {
    setSelectedSubTab(subTabId);
    setSelectedTab(subTabId || TABS.CONTENT_INFORMATION);
  }, [subTabId]);

  useEffect(() => {
    if (contentContext.copy) {
      formik.setValues({
        ...formik.values,
        publishStatus: 0,
        status: 0,
      });
    }
  }, [contentContext.copy]);

  useEffect(() => {
    setIsEditMode(mode === MODE.EDIT || mode === MODE.ADD);
  }, [mode]);

  useEffect(() => {
    setAcceptFileType(
      typeOfContentOptions?.find(
        (item) => item.value === Number(formik.values.typeOfContent)
      )?.accept
    );
  }, [formik.values.typeOfContent]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        handleCancelClick();
      }
    };
  }, [history]);

  const loadDropdownOptions = async () => {
    showLoader(true);
    const response = masterDataContext.masterDataValue;
    showLoader(false);
    // Type of Forms
    setTypeOfFormOptions(
      response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          label: r.name,
        };
      })
    );

    // LOB
    setLobOptions(
      response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          label: r.name,
        };
      })
    );
    // Countries
    setCountryOptions(response.result.countries);
    // States
    setAllStateOptions(response.result.states);
    showLoader(true);
    const subscriptionTypes = await ApiServices.httpGet(
      `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${id}`
    );
    showLoader(false);

    setSubscriptionOptions(
      subscriptionTypes?.result?.map((r) => {
        return {
          value: r.distributorSubscriptionId,
          label: r.name,
        };
      }) || []
    );
  };

  const handleHandleChange = (event, newValue) => {
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("content")
      )}${TABS.CONTENT}/${newValue}`
    );
  };

  const getContentDetail = async (value) => {
    try {
      const cId = value || contentId;
      showLoader(true);
      const response = await ApiServices.httpGet(`${CONTENT}/${cId}`);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        timerId.current = setTimeout(() => {
          page.setDirty(false);
        }, 100);
        let savedStates = response.result.states?.map((el) => el.name);
        setSelected(savedStates?.length ? savedStates : ["Not Applicable"]);
        formik.setValues(fillFormikDetails(response, savedStates));
        setSelectedKeywords(
          response.result.contentKeywordList.map((item) => item.keyword)
        );
        setSelectedTags(response.result.contentTagList.map((item) => item.tag));
        // For Word, Excel & Power Point (4,5,6) Need to use gcpPath
        msContent(response);

        // For PDF & JPG (1, 3) Need to use fileStream as base64 string
        streamContent(response);

        // For XFDL (2) Need to use xfdlJson as json string
        xfdlContent(response);

        setFile();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const makeRequest = async (event, actionName) => {
    let selectedStates =
      stateOptions &&
      stateOptions.filter((el) => formik.values.states?.includes(el.name));
    let statePayload = selectedStates.includes("Not Applicable")
      ? []
      : selectedStates;

    const formData = new FormData();
    contentFormData(formData);
    formDataChangesOne(formData, statePayload);
    formData.append(
      "typeOfFormList",
      JSON.stringify(
        formik.values.typeOfFormList.map((item) => ({
          typeOfFormId: item.value,
        }))
      )
    );
    formData.append(
      "lineOfBusinessList",
      JSON.stringify(
        formik.values.lineOfBusinessList.map((item) => ({
          lineOfBusinessId: item.value,
        }))
      )
    );
    formData.append("file", file);
    if (formik.values.isFileDeleted || actionName === "Delete") {
      formData.append("file", null);
      formData.append("filePath", "");
      formik.setValues({
        ...formik.values,
        filePath: "",
      });
      formData.append("isFileDeleted", true);
    }

    formData.append("isPublic", formik.values.isPublic);
    formData.append("isPreview", formik.values.isPreview);
    formData.append("isDisplayPublic", formik.values.isDisplayPublic);
    if (Number(formik.values.contentType) > 0) {
      formData.append("contentType", formik.values.contentType);
    }
    if (Number(formik.values.previewType) > 0) {
      formData.append("previewType", formik.values.previewType);
    }

    const subscriptionsArray = formik.values.subscriptions.map((item) => {
      return { distributorSubscriptionId: item.value };
    });

    let status;
    const publishStatus = formik.values.publishStatus;

    status = handleStatusOnAction(actionName, publishStatus, status);

    formDataChangesSecond(formData, status, actionName, subscriptionsArray);

    const response = await apiCall(formData);
    return response;
  };

  const createContent = async (event, actionName) => {
    try {
      if (file) {
        let ext = getFileExtension(file.name);
        const fileType = typeOfContentOptions.find(
          (option) => option.value === Number(formik.values.typeOfContent)
        )?.fileType;
        if (!ext.toUpperCase().includes(fileType?.toUpperCase())) {
          toast.error(`Please select ${fileType?.toLowerCase()} file`);
          return;
        }
      }

      showLoader(true);
      dataFetchedRef.current = false;
      page.setDirty(false);
      const response = await makeRequest(event, actionName);
      showLoader(false);

      if (response.responseStatus.isSuccess) {
        contentContext.resetCopyContent();
        await getContentDetail(response.result.contentId);
        toast.success(response.responseStatus.description);
        if (actionName === "Next") {
          onNext(contentId > 0 ? "Update" : "Save", response.result.contentId);
        } else {
          onSave("content", response.result);
        }
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const deleteContent = async (e) => {
    try {
      showLoader(true);
      const response = await makeRequest(e, "Delete");
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        setSelectedDocument([]);
        setFileContent(null);
        toast.success("Content deleted sucessfully.");
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const uploadContent = async (e) => {
    try {
      showLoader(true);
      setSelectedDocument([]);

      const response = await makeRequest(e, "Upload");
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        await getContentDetail();
        toast.success("Document uploaded sucessfully.");
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size; // Get the file size in bytes
    const maxSizeInBytes = 2 * 1024 * 1024; // 10MB in bytes
    if (fileSize > maxSizeInBytes) {
      // File size exceeds the maximum allowed size
      toast.error(`File size > 2 MB. Please upload a file less than 2MB`);
      e.target.value = null; // Reset the file input field
      return;
    }
    let ext = getFileExtension(selectedFile.name);
    const fileType = typeOfContentOptions.find(
      (option) => option.value === Number(formik.values.typeOfContent)
    )?.fileType;
    if (!ext.toUpperCase().includes(fileType?.toUpperCase())) {
      e.target.value = "";
      toast.error(`Please select ${fileType?.toLowerCase()} file`);
      setFile(selectedFile);
      return;
    }
    formik.setValues({
      ...formik.values,
      file: e.target.files[0],
      isFileDeleted: false,
      filePath: selectedFile.name,
    });
    setFile(e.target.files[0]);
  };
  const onDeleteFileHandler = () => {
    formik.setValues({
      ...formik.values,
      file: null,
      isFileDeleted: true,
      filePath: "",
    });
    setFile(null);
  };

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
    let stateNameOptions = result && result.map((item) => item.name);
    stateNameOptions = [...stateNameOptions, "Not Applicable"];
    setStateNames(stateNameOptions);
  };

  useEffect(() => {
    populateStateOptions(formik.values.countryId || 1);
  }, [formik.values.countryId, allStateOptions]);

  const handleChangeDropDown = (event) => {
    const selectedStates = handleStateChange(
      event,
      stateNames,
      selected,
      setSelected,
      formik.values.states
    );
    formik.setFieldValue("states", selectedStates);
  };

  return (
    <div className="welcome">
      <div className="addedit-content-main">
        <Tabs
          value={selectedTab}
          onChange={handleHandleChange}
          aria-label="Distributor Tabs"
          className="content-tabs"
        >
          <Tab
            label="Content Information"
            value={TABS.CONTENT_INFORMATION}
            className="content-sub-tabs"
            {...a11yProps("Content Information", "ContentInformation")}
          />
          <Tab
            label="Content Preview"
            value={TABS.CONTENT_PREVIEW}
            className="content-sub-tabs"
            {...a11yProps("Content Preview", "ContentPreview")}
          />
          <Tab
            label="Manage Subscribers"
            value={TABS.ENROLLED_USERS}
            className="content-sub-tabs"
            {...a11yProps("Manage Subscriber", "ManageSubscribers")}
          />
        </Tabs>
        <TabPanel value={selectedTab} index="ContentInformation">
          <Grid item xs={12}>
            <div className="welcome-main">
              <div className="welcome-message">
                <Typography className="montserrat-16-600">
                  Basic Information
                </Typography>
              </div>

              <div>
                {mode === MODE.VIEW && showEditAction && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={() => onEdit(formik.values)}
                    disabled={
                      formik.values.publishStatus ===
                        ContentPublishStatus.MODIFIED &&
                      formik.values.status === 0
                    }
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  onClick={(e) => {
                    if (formik.values.isFileDeleted) {
                      handleConfirmationOpen(
                        contentId === 0 || contentContext.copy
                          ? "Save & Exit"
                          : "Update & Exit",
                        confirmationNoDocAttached
                      );
                    } else {
                      createContent(e);
                    }
                  }}
                  disabled={!formik.isValid || !isEditMode}
                >
                  {contentId === 0 || contentContext.copy ? "Save" : "Update"} &
                  Exit
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  onClick={(e) => {
                    if (formik.values.isFileDeleted) {
                      handleConfirmationOpen(
                        contentId === 0 || contentContext.copy
                          ? "Save & Next"
                          : "Update & Next",
                        confirmationNoDocAttached
                      );
                    } else {
                      createContent(e, "Next");
                    }
                  }}
                  disabled={!formik.isValid || !isEditMode}
                >
                  {contentId === 0 || contentContext.copy ? "Save" : "Update"} &
                  Next
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  onClick={(e) => {
                    handleNextButtonClick();
                    if (formik.values.isFileDeleted) {
                      handleConfirmationOpen(
                        "Publish",
                        confirmationNoDocAttached
                      );
                    } else {
                      handleConfirmationOpen("OK", cofirmationToPublish);
                    }
                  }}
                  disabled={disablePublishCondition()}
                >
                  Publish
                </Button>
                {showWarning && (
                  <div className="warning-message">
                    Please upload an attachment for the selected type of
                    content.
                  </div>
                )}
                <Button
                  variant="outlined"
                  className="action-button"
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Confirmation
                  open={confirmation}
                  handleClose={handleConfirmationClose}
                  title={confirmationMessage}
                  cancelButtonLabel="Cancel"
                  confirmButtonLabel={action}
                  onConfirm={(e) => {
                    if (
                      action === "Save & Next" ||
                      action === "Update & Next"
                    ) {
                      createContent(e, "Next");
                      handleConfirmationClose();
                    } else if (action === "Publish") {
                      handleConfirmationOpen("OK", cofirmationToPublish);
                    } else if (action === "OK") {
                      createContent(e, "Publish");
                      handleConfirmationClose();
                    } else {
                      createContent(e);
                      handleConfirmationClose();
                    }
                  }}
                  onCancel={() => {
                    handleConfirmationClose();
                  }}
                />
              </div>
            </div>
          </Grid>

          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} p={2}>
                <label htmlFor="typeOfContent">Type of Content</label>
                <MandatoryField />
                <select
                  id="typeOfContent"
                  name="typeOfContent"
                  value={formik.values.typeOfContent}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      fillable: 1,
                    });
                    formik.handleChange(e);
                    page.setDirty(true);
                    formik.setFieldValue("file", e.target.value);
                    formik.setFieldValue("filePath", "");
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                >
                  <option value="">Select Type of Content</option>
                  {typeOfContentOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              {formik.values.typeOfContent === "2" && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="formNumber">Form Number</label>
                  <MandatoryField />
                  <input
                    id="formNumber"
                    name="formNumber"
                    onChange={(e) => {
                      formik.handleChange(e);
                      page.setDirty(true);
                    }}
                    value={formik.values.formNumber}
                    className="form-label form-control border-cus-cus"
                    type="text"
                    placeholder="Enter Form Number"
                    maxLength={150}
                    autocomplete="off"
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED
                    }
                  />
                  <InlineMessage error={formik.errors.formNumber} />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="name">Content Name</label>
                <MandatoryField />
                <input
                  id="name"
                  name="name"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.name}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Content Name"
                  maxLength={150}
                  autocomplete="off"
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.name} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="userId">Owner Id</label>
                <input
                  uid="userId"
                  name="userId"
                  value={distributorId}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Owner Id"
                  maxLength={25}
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="typeOfFormList">Type of Forms</label>
                <MultiSelectDropDown
                  name="typeOfFormList"
                  id="typeOfFormList"
                  value={formik.values.typeOfFormList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      typeOfFormList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={typeOfFormOptions}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lineOfBusinessList">LOB</label>
                <MultiSelectDropDown
                  name="lineOfBusinessList"
                  id="lineOfBusinessList"
                  value={formik.values.lineOfBusinessList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      lineOfBusinessList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={lobOptions}
                  disabled={!isEditMode}
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="countryId">Country</label>
                <select
                  id="countryId"
                  name="countryId"
                  data-testid="country-dropdown"
                  value={formik.values?.countryId}
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                    setSelected([]);
                  }}
                  className="form-control form-select"
                  disabled={!isEditMode}
                >
                  {countryOptions?.map((res) => {
                    return (
                      <option key={res.countryId} value={res.countryId}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="stateId">State/Province</label>
                <Select
                  displayEmpty
                  data-testid="states-dropdown"
                  id="stateId"
                  name="stateId"
                  labelId="mutiple-select-label"
                  className={
                    !isEditMode
                      ? "form-select form-control select-user cus-cus-border-none roles-dropdown-disabled"
                      : "form-select form-control select-user cus-cus-border-none roles-dropdown"
                  }
                  disabled={!isEditMode}
                  multiple
                  onChange={(e) => {
                    handleChangeDropDown(e);
                    page.setDirty(true);
                  }}
                  value={selected}
                  renderValue={(selectedItem) => {
                    if (selected.length === 0) {
                      return "Select State";
                    }
                    return selectedItem.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < stateNames.length - 1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={isAllSelected ? "Unselect All" : "Select All"}
                    />
                  </MenuItem>
                  {stateNames.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          disabled={formik.values.states?.includes(
                            "Not Applicable"
                          )}
                          checked={selected.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="latestVersion">Latest Version</label>
                <MandatoryField />
                <input
                  id="latestVersion"
                  name="latestVersion"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.latestVersion}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Latest Version"
                  autocomplete="off"
                  maxLength={25}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.latestVersion} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="fillable">Fillable</label>
                <select
                  id="fillable"
                  name="fillable"
                  value={formik.values.fillable}
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED ||
                    formik.values.typeOfContent !== "2"
                  }
                >
                  {fillableOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              <Grid item xs={3} p={2}>
                <FormControlLabel
                  className={
                    !isEditMode
                      ? "content-checkbox-field form-label border-cus-cus form-control input-border top-pad cus-cus-fm border-cus-cus-disabled"
                      : "content-checkbox-field form-label border-cus-cus form-control input-border top-pad cus-cus-fm"
                  }
                  label={<Typography>Preview Available</Typography>}
                  checked={formik.values.isPreview}
                  name="Available Public"
                  data-testid="preview-available-checkbox"
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      isPreview: e.target.checked,
                    });
                    page.setDirty(true);
                  }}
                  control={<Checkbox disabled={!isEditMode} />}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3} p={1}>
                <label htmlFor="previewType">Preview Available</label>
                <select
                  id="previewType"
                  name="previewType"
                  data-testid="preview-type"
                  value={formik.values.previewType}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                    });
                    page.setDirty(true);
                    formik.handleChange(e);
                  }}
                  className="form-label form-control input-border custom-sel-ect cus-cus-avl"
                  disabled={!isEditMode}
                >
                  <option>Select Preview Type</option>
                  {previewTypeOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="application" className="file-upload-label">
                  <span>Upload File </span>
                  {fileActionType()}
                </label>
                <FileUploader
                  accept={acceptFileType}
                  onSelectFile={handleFileChange}
                  onDeleteFile={onDeleteFileHandler}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                {formik.values.filePath !== "" && (
                  <div>{formik.values.filePath}</div>
                )}
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="webLink">Web Link</label>
                <input
                  id="webLink"
                  name="webLink"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.webLink || ""}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Web Link"
                  autocomplete="off"
                  disabled={!isEditMode}
                />
                <InlineMessage error={formik.errors.webLink} />
              </Grid>
              {formik.values.reason && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="reason">Reason for Deactivation</label>
                  <input
                    id="reason"
                    name="reason"
                    value={formik.values.reason}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter reason"
                    autocomplete="off"
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="createdDate">Created Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.createdOn)}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedDate">Last Updated Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.updatedOn)}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              {formik.values.typeOfContent !== "2" && (
                <Grid
                  item
                  xs={3}
                  p={2}
                  className="tag-input blue-field-section"
                ></Grid>
              )}
              <Grid
                item
                xs={3}
                p={2}
                className="tag-input tag-input-search-keyword blue-field-section"
              >
                <label htmlFor="keywords">Search Keywords</label>
                <TagsInput
                  id="keywords"
                  name="keywords"
                  value={selectedKeywords}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      keywords: value.join(),
                    });
                    page.setDirty(true);
                    setSelectedKeywords(value);
                  }}
                  placeHolder="Enter Keyword then a Comma OR Enter key to add each additional keyword(s)"
                  className={!isEditMode && "disabled"}
                  disabled={!isEditMode}
                />
              </Grid>
              <Grid item xs={3} p={2} className="tag-input blue-field-section">
                <label htmlFor="tages">Content Tags/Labels</label>
                <div classNames="disabled scrollable-input-content">
                  <TagsInput
                    id="tages"
                    name="tages"
                    value={selectedTags}
                    onChange={(value) => {
                      formik.setValues({
                        ...formik.values,
                        tages: value.join(),
                      });
                      page.setDirty(true);
                      setSelectedTags(value);
                    }}
                    placeHolder="Enter Content Tag/Label then a Comma OR Enter key to add each additional Tag/Label(s)"
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="marketingDescription">
                  Marketing Description
                </label>
                <div className="textarea-container">
                  <textarea
                    id="marketingDescription"
                    name="marketingDescription"
                    onChange={(e) => {
                      formik.handleChange(e);
                      page.setDirty(true);
                    }}
                    value={formik.values.marketingDescription}
                    className="form-label form-control bs-cus scrollable-input-content"
                    type="text"
                    placeholder="Enter Marketing Description"
                    autoComplete="off"
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED
                    }
                  />
                </div>
                <InlineMessage error={formik.errors.marketingDescription} />
              </Grid>
            </Grid>
          </div>
          <div>
            {auth.getRole() !== 4 && (
              <Grid container spacing={1}>
                <Grid item xs={12} p={2}>
                  <Accordion className="accordionWrapper">
                    <AccordionSummary
                      className="accordion-content-account-information"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="montserrat-16-600">
                        Account Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div
                          className="menu-bar"
                          style={{
                            display: "flex",
                            height: "100%",
                            marginLeft: "0",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="numberOfEnrolledUsers"
                              >
                                Enrolled Users
                              </label>
                              <input
                                id="numberOfEnrolledUsers"
                                name="numberOfEnrolledUsers"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  page.setDirty(true);
                                }}
                                value={formik.values.numberOfEnrolledUser}
                                className="form-label form-control input-border"
                                type="text"
                                placeholder="Enter Last Name"
                                maxLength={25}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                label={
                                  <Typography className="montserrat-14-500">
                                    Available to Public
                                  </Typography>
                                }
                                checked={formik.values.isPublic}
                                name="Available Public"
                                data-testid="available-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isPublic: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={<Checkbox disabled={!isEditMode} />}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-control form-select input-border cus-cus-fm"
                                checked={formik.values.isDisplayPublic}
                                name="Display to Public"
                                label={
                                  <Typography className="montserrat-14-500">
                                    Display to Public
                                  </Typography>
                                }
                                data-testid="display-to-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isDisplayPublic: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={<Checkbox disabled={!isEditMode} />}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="contentType"
                              >
                                Content Type(Free/Paid)
                              </label>
                              <select
                                id="contentType"
                                name="contentType"
                                value={formik.values.contentType}
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    oneTimeCost: 0,
                                    baseValue: 0,
                                    subscriptions: [],
                                    frequency: 0,
                                    isRecurring: false,
                                  });
                                  page.setDirty(true);
                                  formik.handleChange(e);
                                }}
                                className="form-label form-control input-border custom-sel-ect cus-cus-avl"
                                disabled={!isEditMode}
                              >
                                <option>Select Content Type</option>
                                {contentTypeOptions?.map((res) => {
                                  return (
                                    <option key={res.value} value={res.value}>
                                      {res.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </Grid>
                            {Number(formik.values.contentType) === 2 && (
                              <>
                                <Grid item xs={3} p={2}>
                                  <label
                                    className="extra-padding-input montserrat-14-500 label-gap"
                                    htmlFor="oneTimeCost"
                                  >
                                    One Time Cost
                                  </label>
                                  <CurrencyTextbox
                                    id="oneTimeCost"
                                    name="oneTimeCost"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "oneTimeCost",
                                        e || 0
                                      );
                                      page.setDirty(true);
                                    }}
                                    value={formik.values.oneTimeCost}
                                    className="form-label form-control input-border"
                                    disabled={!isEditMode}
                                  />
                                </Grid>
                                <Grid item xs={3} p={2}>
                                  <label
                                    className="extra-padding-input-2 montserrat-14-500 label-gap"
                                    htmlFor="subscriptions"
                                  >
                                    Subscriptions Type
                                  </label>
                                  <MultiSelectDropDown
                                    name="subscriptions"
                                    id="subscriptions"
                                    value={formik.values.subscriptions}
                                    onChange={(value) => {
                                      formik.setValues({
                                        ...formik.values,
                                        subscriptions: value,
                                      });
                                      page.setDirty(true);
                                    }}
                                    options={subscriptionOptions}
                                    className="form-label form-control input-border"
                                    disabled={!isEditMode}
                                  />
                                </Grid>
                                <Grid item xs={3} p={2}>
                                  <label
                                    className="extra-padding-input montserrat-14-500 label-gap"
                                    htmlFor="baseValue"
                                  >
                                    Base Value
                                  </label>
                                  <CurrencyTextbox
                                    id="baseValue"
                                    name="baseValue"
                                    onChange={(e) => {
                                      formik.setFieldValue("baseValue", e || 0);
                                      page.setDirty(true);
                                    }}
                                    value={formik.values.baseValue}
                                    className=" content-checkbox-field form-label form-control input-border"
                                    disabled={!isEditMode}
                                  />
                                </Grid>
                                <Grid item xs={3} p={2}>
                                  <label
                                    className="extra-padding-input montserrat-14-500 label-gap"
                                    htmlFor="frequency"
                                  >
                                    Frequency
                                  </label>
                                  <select
                                    id="frequency"
                                    name="frequency"
                                    value={formik.values.frequency}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      page.setDirty(true);
                                    }}
                                    className="form-control form-select input-border"
                                    disabled={!isEditMode}
                                  >
                                    <option>Select Frequency</option>
                                    {frequencyOptions?.map((res) => {
                                      return (
                                        <option
                                          key={res.value}
                                          value={res.value}
                                        >
                                          {res.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Grid>
                                <Grid item xs={3} p={2}>
                                  <label
                                    className="extra-padding-input montserrat-14-500 label-gap"
                                    htmlFor="oneTimeCost"
                                  >
                                    Actual Value
                                  </label>
                                  <CurrencyTextbox
                                    id="actualValue"
                                    name="actualValue"
                                    className="form-control form-select input-border"
                                    value={(
                                      formik.values.baseValue *
                                      getFrequency(formik.values.frequency)
                                    ).toFixed(2)}
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid item xs={3} p={2} mt={1}>
                                  <FormControlLabel
                                    className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                    checked={formik.values.isRecurring}
                                    name="Display to Public"
                                    data-testid="recurring-type-checkbox"
                                    onChange={(e) => {
                                      formik.setValues({
                                        ...formik.values,
                                        isRecurring: e.target.checked,
                                      });
                                      page.setDirty(true);
                                    }}
                                    control={
                                      <Checkbox
                                        disabled={
                                          !isEditMode ||
                                          formik.values.publishStatus ===
                                            ContentPublishStatus.PUBLISHED
                                        }
                                      />
                                    }
                                    label={
                                      <Typography>Recurring Type</Typography>
                                    }
                                    labelPlacement="end"
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            )}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index="ContentPreview">
          <Grid display={"flex"} justifyContent={"space-between"}>
            <div className="welcome-main">
              <div className="welcome-message">
                <h5>Content Preview</h5>
              </div>
            </div>

            <div className="button-container">
              <label htmlFor="application">
                <span>
                  <p>{fileActionType()}</p>
                  <FileUploader
                    accept={acceptFileType}
                    onSelectFile={handleFileChange}
                    onDeleteFile={onDeleteFileHandler}
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED
                    }
                  />
                </span>
                {formik.values.filePath !== "" && (
                  <div className="text-cus">{formik.values.filePath}</div>
                )}
              </label>
              <div className="button-container">
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button"
                  disabled={
                    !isEditMode ||
                    !file ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                  onClick={uploadContent}
                >
                  Upload
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button"
                  disabled={
                    !(
                      isEditMode &&
                      (selectedDocument.length > 0 || fileContent)
                    ) ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                  onClick={() => {
                    handleConfirmationOpen("Delete", confirmationNoDocAttached);
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  className="action-button"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
              <Confirmation
                open={confirmation}
                handleClose={handleConfirmationClose}
                title={confirmationMessage}
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Delete"
                onConfirm={async (e) => {
                  deleteContent(e);
                  handleConfirmationClose();
                }}
                onCancel={() => {
                  handleConfirmationClose();
                }}
              />
            </div>
          </Grid>
          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "3rem",
            }}
          >
            <Grid container>
              <Grid item xs={12} pl={2} pr={2}></Grid>
              <Grid item xs={12} pl={2} pr={2}>
                {formik.values.typeOfContent === "7" && (
                  <AudioPreview
                    selectedDocument={selectedDocument}
                    fileName={formik.values.filePath}
                  />
                )}
                {formik.values.typeOfContent === "10" && (
                  <VideoPreview
                    selectedDocument={selectedDocument}
                    fileName={formik.values.filePath}
                  />
                )}
                {loadDocumentViewer()}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index="ManageSubscribers">
          <ManageSubscriber
            contentId={contentId}
            contentName={formik.values.name}
            bundleSubscriberStatus={formik.values.status}
            from="content"
          />
        </TabPanel>
      </div>
    </div>
  );

  function formDataChangesSecond(
    formData,
    status,
    actionName,
    subscriptionsArray
  ) {
    formData.append("publishContentId", formik.values.publishContentId || 0);
    formData.append("publishStatus", status || 0);
    formData.append("isEditAction", actionName === "Publish" ? false : true);
    formData.append("oneTimeCost", formik.values.oneTimeCost);
    formData.append("subscriptions", JSON.stringify(subscriptionsArray));
    formData.append("baseValue", formik.values.baseValue);
    if (formik.values.frequency !== 0) {
      formData.append("frequency", formik.values.frequency);
    }
    formData.append("isRecurring", formik.values.isRecurring);
  }

  async function apiCall(formData) {
    return contentId === 0 || contentContext.copy
      ? await ApiServices.httpPostFile(CONTENT, formData)
      : await ApiServices.httpPutFile(CONTENT, formData);
  }

  function handleStatusOnAction(actionName, publishStatus, status) {
    if (actionName === "Publish") {
      if (publishStatus === ContentPublishStatus.PUBLISHED) {
        status = ContentPublishStatus.MODIFIED;
      } else if (
        !publishStatus ||
        publishStatus === ContentPublishStatus.DRAFT
      ) {
        status = ContentPublishStatus.PUBLISHED;
      }
    } else {
      status = publishStatus || ContentPublishStatus.DRAFT;
    }
    return status;
  }

  function contentFormData(formData) {
    if (contentId > 0) {
      formData.append("contentId", contentId);
    } else if (contentContext.copy) {
      formData.append("contentId", contentContext.sourceContentId);
    }
  }

  function formDataChangesOne(formData, statePayload) {
    formData.append("typeOfContent", Number(formik.values.typeOfContent));
    formData.append("name", formik.values.name);
    formData.append("formNumber", formik.values.formNumber);
    formData.append("userId", formik.values.userId);
    formData.append("Keywords", formik.values.keywords);
    formData.append("tages", formik.values.tages || "");
    formData.append("countryId", Number(formik.values.countryId || 1));
    formData.append("states", JSON.stringify(statePayload));
    formData.append("latestVersion", formik.values.latestVersion);
    formData.append(
      "marketingDescription",
      formik.values.marketingDescription || ""
    );
    formData.append("webLink", formik.values.webLink || "");
    formData.append("reason", formik.values.reason);
    formData.append("fillable", Number(formik.values.fillable) || 1);
    formData.append("isFavorite", false);
  }

  function fillFormikDetails(response, savedStates) {
    return {
      ...formik.values,
      contentId: response.result.contentId,
      typeOfContent: String(response.result.typeOfContent),
      name: response.result.name,
      formNumber: response.result.formNumber,
      userId: response.result.userId,
      typeOfFormList: response.result.typeOfFormList.map((item) => {
        return { value: item.typeOfFormId, label: item.name };
      }),
      lineOfBusinessList: response.result.lineOfBusinessList.map((item) => {
        return { value: item.lineOfBusinessId, label: item.name };
      }),
      countryId: response.result.countryId[1],
      states: savedStates,
      marketingDescription: response.result.marketingDescription || "",
      webLink: response.result.webLink || "",
      reason: response.result.reason,
      latestVersion: response.result.latestVersion,
      keywords: response.result.contentKeywordList
        .map((item) => item.keyword)
        .join(","),
      tages: response.result.contentTagList.map((item) => item.tag).join(","),
      fillable: response.result.fillable || 1,
      file: response.result.file,
      filePath: response.result.filePath,
      fileStream: response.result.fileStream,
      isFileDeleted: response.result.filePath?.length === 0,
      numberOfEnrolledUser: response.result.numberOfEnrolledUser || 0,
      isPublic: response.result.isPublic,
      isPreview: response.result.isPreview,
      isDisplayPublic: response.result.isDisplayPublic,
      publishStatus: contentContext.copy
        ? false
        : response.result.publishStatus,
      publishContentId: response.result.publishContentId || 0,
      contentType: response.result.contentType,
      previewType: response.result.previewType,
      createdOn: response.result.createdOn,
      updatedOn: response.result.updatedOn,
      mode: mode,
      status: response.result.status,
      oneTimeCost: response.result?.oneTimeCost?.toFixed(2) || 0,
      baseValue: response.result?.baseValue?.toFixed(2),
      isRecurring: response.result.isRecurring,
      frequency: response.result.frequency,
      subscriptions: response.result.subscriptions.map((r) => {
        return { value: r.distributorSubscriptionId, label: r.name };
      }),
    };
  }

  function msContent(response) {
    if (
      response.result.typeOfContent === 4 ||
      response.result.typeOfContent === 5 ||
      response.result.typeOfContent === 6
    ) {
      if (response.result.gcpFilePath) {
        setSelectedDocument([
          {
            uri: response.result.gcpFilePath,
            fileName: response.result.filePath,
            fileType: typeOfContentOptions?.find(
              (item) => item.value === response.result.typeOfContent
            )?.fileType,
          },
        ]);
      } else {
        setSelectedDocument([]);
      }
    }
  }

  function streamContent(response) {
    if (
      response.result.typeOfContent === 1 ||
      response.result.typeOfContent === 8 ||
      response.result.typeOfContent === 9 ||
      response.result.typeOfContent === 3 ||
      response.result.typeOfContent === 7 || // For Audio file
      response.result.typeOfContent === 10 // For Video file
    ) {
      if (response.result.fileStream) {
        setSelectedDocument([
          {
            uri: response.result.fileStream,
            fileName: response.result.filePath,
            fileType: typeOfContentOptions?.find(
              (item) => item.value === response.result.typeOfContent
            )?.fileType,
          },
        ]);
      } else {
        setSelectedDocument([]);
      }
    }
  }

  function xfdlContent(response) {
    if (response.result.typeOfContent === 2) {
      if (response.result.xfdlJson) {
        setFileContent(JSON.parse(response.result.xfdlJson));
      } else {
        setFileContent(null);
      }
    }
  }

  function fileActionType() {
    return file || formik.values.filePath ? (
      <span className="filename">Replace File</span>
    ) : (
      <span className="filename">Choose File</span>
    );
  }

  function loadDocumentViewer() {
    const whenNotDocument = fileContent ? (
      <XFDLViewer document={fileContent} fileName={formik.values.filePath} />
    ) : (
      !show &&
      formik.values.fileStream === null && (
        <div className="no-preview">
          <img
            src="/images/no-preview-available.png"
            alt="No document attached to preview"
          />
        </div>
      )
    );
    return allowedContentTypes.includes(Number(formik.values.typeOfContent)) &&
      selectedDocument.length > 0 ? (
      <DocumentViewer
        disableHeader={true}
        tempId={Math.floor(Math.random() * 1000 + 1)} // Just for rerendering
        documents={selectedDocument}
        config={{
          noRenderer: {
            overrideComponent: <div>No Document uploaded</div>,
          },
        }}
      />
    ) : (
      whenNotDocument
    );
  }

  function disablePublishCondition() {
    return (
      (signUpApproval?.approvalId > 0 && signUpApproval?.status !== 1) ||
      !formik.isValid ||
      !isEditMode ||
      (formik.values.typeOfContent !== TypeofConstantENUM.WEBLINK &&
        !file &&
        !formik.values.filePath) ||
      (formik.values.typeOfContent === TypeofConstantENUM.WEBLINK &&
        formik.values.webLink === "")
    );
  }
}

export default AddEditContent;
