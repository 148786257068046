import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListSubheader,
  Typography,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import "../../..//Dashboard/Dashboard.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ApiServices from "../../../../services/ApiServices";
import Tooltip from "../../../../common/workarounds/tooltip/PatchTooltip";
import {
  MANAGE_SUBSCRIBER_LIST,
  MANAGE_SUBSCRIBER_LIST_CHANGE_STATUS,
  EXPORT_ASSOCIATE_SUBSCRIBER,
} from "../../../../constants/api-constants";
import Popover from "@mui/material/Popover";
import { toast } from "react-toastify";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExportToExcelHandler from "../../../../utilities/ExportToExcelHandler";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLoading } from "../../../../hooks/useLoader";
import CheckboxList from "../../../../common/CheckboxList/CheckboxList";
import DataTable from "../../../Tables/DataTable/DataTable";
import AddEditSubscriberModal from "../AddEditSubscriber/AddEditSubscriberModal";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { MODE, TABS } from "../../../../constants/common";
import { useHistory } from "react-router";
import { useDistributorProfileContext } from "../../DistributorProfile.context";
import { useContentContext } from "../Content.context";
import { useCatalogContext } from "../../Catalog/Catalog.context";
import { useBundleContext } from "../../Bundle/Bundle.context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 630,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ManageSubscriber = (props) => {
  const { publishStatuss, catalogPublishStatus } = props;
  const history = useHistory();
  const [subscriberId, setSubscriberId] = useState(null);
  const [associateStatus, setAssociateStatus] = useState(null);
  const { contentId } = props;
  const { showLoader } = useLoading();
  const distributorProfileContext = useDistributorProfileContext();
  const [adminTable, setAdminTableData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const handleConfirmationOpen = () => setConfirmation(true);
  const handleConfirmationClose = () => setConfirmation(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedApplicable, setSelectedApplicable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModlaClose = () => setOpenModal(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState("");

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {
      userStatus: [0, 1],
      ContentSubscriberStatus: [0, 1],
    },
  });
  const setContentContext = useContentContext();
  const setCatalogContext = useCatalogContext();
  const setBundleContext = useBundleContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState("false");

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const validStartDate = moment(startDate, "YYYY-MM-DD", true).isValid();
  const validLastDate = moment(endDate, "YYYY-MM-DD", true).isValid();
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const isValid = () => {
    return (
      startDate !== null &&
      startDate !== "" &&
      startDate >= currentDate &&
      validStartDate &&
      (endDate ? startDate <= endDate : true) &&
      (endDate ? validLastDate : true)
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setSelectionColumns();
    adminDetails();
    ClearSelectionData();
  }, []);

  useEffect(() => {
    adminDetails();
  }, [query]);

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const adminDetails = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${MANAGE_SUBSCRIBER_LIST}/${contentId}/${JSON.stringify(query)}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("userId");

      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.map((ele) => {
          if (checkedIds.includes(ele.userId)) {
            ele.isChecked = true;
          } else {
            ele.isChecked = false;
          }
          return ele;
        });
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };
  /*istanbul ignore next */
  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("userId");
      let queryState = query;
      queryState.SelectedIds = ids;
      showLoader(true);
      const exportedContent = await ApiServices.httpGet(
        `${EXPORT_ASSOCIATE_SUBSCRIBER}/${contentId}/${JSON.stringify(
          queryState
        )}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
      ClearSelectionData();
      adminDetails();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onFilterRoleChangeHandler = (values) => {
    setSelectedApplicable(values);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };

  const applyFilterHandler = () => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        Filter: {
          ...prevState.filter,
          ContentSubscriberStatus: selectedApplicable,
          userStatus: selectedStatus,
        },
      };
    });
  };

  const clearFilterHandler = () => {
    setSelectedApplicable([]);
    setSelectedStatus([]);
  };
  /*istanbul ignore next */
  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols =
      props.from === "content" && selectedCols.manageSubscriber
        ? selectedCols.manageSubscriber
        : selectedCols.manageSubscriberCatalogBundle;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const onCancel = () => {
    if (distributorProfileContext.selectedTab === TABS.CONTENT) {
      setContentContext.setSubSelectedTab(1);
      setContentContext.setContentMode(MODE.LIST);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("content")
        )}${TABS.CONTENT}`
      );
    } else if (distributorProfileContext.selectedTab === TABS.CATALOG) {
      setCatalogContext.setSubSelectedTab(1);
      setCatalogContext.setCatalogMode(MODE.LIST);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("catalog")
        )}${TABS.CATALOG}`
      );
    } else if (distributorProfileContext.selectedTab === TABS.BUNDLE) {
      setBundleContext.setSubSelectedTab(1);
      setBundleContext.setBundleMode(MODE.LIST);
      history.push(
        `${history.location.pathname.substring(
          0,
          history.location.pathname.indexOf("bundle")
        )}${TABS.BUNDLE}`
      );
    }
  };
  /*istanbul ignore next */
  const changeStatus = async (subscribersId, contentSubscriberStatus) => {
    const toggle = await ApiServices.httpPut(
      MANAGE_SUBSCRIBER_LIST_CHANGE_STATUS,
      {
        status: contentSubscriberStatus ? 0 : 1,
        contentId: contentId,
        subscriberId: subscribersId,
        associateFrom: startDate,
        associateTo: endDate === "" ? null : endDate,
      }
    );
    if (toggle.responseStatus.isSuccess) {
      /*istanbul ignore next */
      toast.success(toggle.responseStatus.description);
      adminDetails();
      handleModlaClose();
      setEndDate("");
    } else {
      toast.error(toggle.responseStatus.description);
    }
  };

  /*istanbul ignore next */
  const onToggle = async (
    columName,
    subscribersId,
    contentSubscriberStatus,
    event
  ) => {
    setSubscriberId(subscribersId);
    setAssociateStatus(contentSubscriberStatus);
    if (event.target.checked) {
      event.preventDefault();
      handleModalOpen();
    } else {
      setAdminTableData(() => {
        return adminTable.map((data) => {
          if (data.userId === subscribersId) {
            return {
              ...data,
              contentSubscriberStatus: data.contentSubscriberStatus ? 0 : 1,
            };
          } else return data;
        });
      });
      handleConfirmationOpen();
    }
  };

  const whenStartDateIsSmaller = !validLastDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    ""
  );
  const whenEndDateNotEmpty =
    startDate > endDate ? (
      <InlineMessage error="Should be greater than or equal to Start date" />
    ) : (
      whenStartDateIsSmaller
    );
  const whenValidStartDate =
    startDate < currentDate ? (
      <InlineMessage error="Can't be a past date" />
    ) : (
      ""
    );
  const whenStartDate = !validStartDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    whenValidStartDate
  );
  return (
    <div className="dashboard-main">
      <div>
        <Confirmation
          open={confirmation}
          handleClose={handleConfirmationClose}
          title="Are you sure you want to dis-associate the subscriber?"
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onConfirm={() => {
            changeStatus(subscriberId, associateStatus);
            handleConfirmationClose();
          }}
          onCancel={() => {
            adminDetails();
            handleConfirmationClose();
          }}
        />
        <Modal
          open={openModal}
          onClose={handleModlaClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form>
              <div className="modal-header">
                <div
                  className="modal-header-left"
                  data-testid="internal-user-modal"
                >
                  Associate Subscriber
                </div>
                <div
                  className="modal-header-right"
                  onClick={() => {
                    handleClose();
                    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                  style={{ cursor: "pointer" }}
                ></div>
              </div>
              <div className="modal-body manage-subscriber-body">
                <Grid container spacing={2}>
                  <Grid item xs={6} className="desktop-date-picker">
                    <label htmlFor="from">Associated From</label>
                    <MandatoryField />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control"
                        inputFormat="MM/DD/YYYY"
                        data-testid="startdate"
                        minDate={moment(new Date()).format("YYYY-MM-DD")}
                        max="9999-12-31"
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                          e && e._d
                            ? setStartDate(moment(e._d).format("YYYY-MM-DD"))
                            : setStartDate("");
                        }}
                        renderInput={(params) => (
                          <TextField id="from" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    {!startDate ? <InlineMessage error="" /> : whenStartDate}
                  </Grid>
                  <Grid item xs={6} className="desktop-date-picker">
                    <label htmlFor="from">Associated Till</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control"
                        inputFormat="MM/DD/YYYY"
                        data-testid="enddate"
                        minDate={moment(startDate).format("YYYY-MM-DD")}
                        max="9999-12-31"
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                          e && e._d
                            ? setEndDate(moment(e._d).format("YYYY-MM-DD"))
                            : setEndDate("");
                        }}
                        renderInput={(params) => (
                          <TextField id="from" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    {endDate === "" ? "" : whenEndDateNotEmpty}
                  </Grid>
                </Grid>
              </div>
              <div className="modal-footer">
                <div className="modal-switch">
                  <div className="hide-switch"></div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    className="action-button mr-4"
                    data-testid="close-internal-modal"
                    onClick={() => {
                      handleModlaClose();
                      setEndDate("");
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    data-testid="save-button"
                    className="action-button"
                    type="button"
                    variant="contained"
                    disabled={!isValid()}
                    onClick={(e) => changeStatus(subscriberId, associateStatus)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="welcome-main">
            <div className="welcome-message">
              <h5>Manage Subscribers for {props.contentName || "NA"}</h5>
            </div>
            <Button
              variant="outlined"
              className="action-button"
              onClick={onCancel}
              data-testid="copyClogIcon"
            >
              Cancel
            </Button>
            <div className="welcome-widget-main">
              <div className="welcome-widgets csssss">
                <div>
                  <Popover
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                  >
                    <div className="expanded-filter">
                      <div className="filter-options">
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          subheader={
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                            >
                              Filters will be applied to your grid
                            </ListSubheader>
                          }
                        >
                          <Accordion
                            expanded={expanded === "role"}
                            onChange={filterAccordianHandleChange("role")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                ASSOCIATED
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="role"
                                options={[
                                  {
                                    value: 1,
                                    label: "Yes",
                                  },
                                  {
                                    value: 0,
                                    label: "No",
                                  },
                                ]}
                                onChange={onFilterRoleChangeHandler}
                                selectedValues={selectedApplicable}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "status"}
                            onChange={filterAccordianHandleChange("status")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Status
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="status"
                                options={[
                                  { value: 1, label: "Active" },
                                  { value: 0, label: "Inactive" },
                                ]}
                                onChange={onFilterStatusChangeHandler}
                                selectedValues={selectedStatus}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </List>
                      </div>
                      <div className="filter-buttons-div">
                        <Button
                          data-testid="clear-filter"
                          variant="outlined"
                          className="filter-buttons action-button"
                          onClick={clearFilterHandler}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          className="filter-buttons action-button"
                          onClick={() => {
                            applyFilterHandler();
                            handleClose();
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>

                <AddEditSubscriberModal
                  contentId={contentId}
                  adminDetails={adminDetails}
                  adminTable={adminTable}
                  onClick={props.onClick}
                  bundleSubscriberStatus={props.bundleSubscriberStatus}
                  bundlepublishStatuss={publishStatuss}
                  catalogPublishStatus={catalogPublishStatus}
                />

                <Tooltip placement="bottom" title="Filter">
                  <FilterAltOutlinedIcon onClick={handleClick} />
                </Tooltip>
                <Tooltip placement="bottom" title="Export to Excel">
                  <IosShareRoundedIcon
                    data-testid="IosShareRoundedIcon"
                    onClick={handleExport}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="manage-subscriber-table">
            <DataTable
              adminDetails={adminDetails}
              uniqueKey="userId"
              onCheckboxChange={(e, value) => {
                const { checked } = e.target;

                if (value === "all") {
                  setAdminTableData((items) => {
                    SelectionFilter(items, "multiple", checked, "userId");
                    return items.map((data) => {
                      return {
                        ...data,
                        isChecked: checked,
                      };
                    });
                  });
                } else {
                  setAdminTableData((items) => {
                    return items.map((user) => {
                      let userRecord = { ...user };
                      if (user.userId === value) {
                        SelectionFilter(user, "single", checked, "userId");
                        userRecord.isChecked = checked;
                      }
                      return userRecord;
                    });
                  });
                }
              }}
              columns={selectedColumns}
              query={query}
              handleChangePage={handleChangePageNumber}
              adminTable={adminTable}
              totalPages={totalPages}
              pageSize={pageSize}
              onSearch={onSearchHandler}
              onPageSizeChange={pageSizeChangeHandler}
              onToggle={onToggle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscriber;
