import { Button, Checkbox, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../../Modal/Modal.css";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { UserSignupSchema } from "../../ValidationSchema/ValidationSchema";
import ApiServices from "../../../services/ApiServices";
import { toast } from "react-toastify";
import { ADMIN_USER } from "../../../constants/api-constants";
import { formatDate } from "../../../utilities/formatter";
import PhoneNumberTextbox from "../../PhoneNumberTextbox/PhoneNumberTextbox";
import InlineMessage from "../../InlineMessage/InlineMessage";
import { useLoading } from "../../../hooks/useLoader";
import MultiSelectDropDown from "../../MultiSelectDropDown/MultiSelectDropDown";
import { MandatoryField } from "../../MandatoryFieldsIcon/MandatoryField";
import filterRoles from "../../../utilities/filterRoles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Confirmation from "../../Confirmation/Confirmation";
import { exitConfirmationMessage } from "../../../screens/Distributors/constants";
import { usePageMode } from "../../../hooks/usePageMode";
import BusinessPhoneNumberTextbox from "../../PhoneNumberTextbox/BusinessPhoneNumberTextbox";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/useAuth";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { SystemUsersEnum } from "../../../constants/SystemUsers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const EditAdminModal = (props) => {
  const auth = useAuth();
  const { userRoleId, masterData, fromSettings } = props;
  const history = useHistory();
  const { showLoader } = useLoading();
  const page = usePageMode();
  const { data, isEditMode } = props;
  const [open, setOpen] = React.useState(false);
  const [distributorTypeOptions, setDistributorTypeOptions] = useState([]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const options = allRoles && allRoles.map((item) => item.name);
  const [selected, setSelected] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const isAllSelected =
    options && options.length > 0 && selected.length === options.length;

  const handleOpen = () => {
    setRoles(data?.roles);
    loadDropdownOptions();
    setOpen(true);
  };
  const handleClose = () => {
    clearAll();
    page.setDirty(false);
    setOpen(false);
    setOpenConfirmation(false);
  };
  const [state, setState] = useState({
    addFax: false,
    addForm: false,
    registrationDate: new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
  });

  const setRoles = (rolesData) => {
    if (rolesData) {
      const userData = rolesData.map((item) => item.name);
      setSelected(userData);
      formik.setFieldValue("roles", userData);
    }
  };

  const handleCancel = () => {
    setSelectedRoles([]);
    setState({
      addFax: false,
      addMobile: false,
      addForm: false,
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  // Formik
  const formik = useFormik(initialFormik());

  useEffect(() => {
    page.setDirty(formik.values.dirty);
  }, [formik.values.dirty]);

  const handleEditModalCancelClick = () => {
    if (formik.dirty === true) {
      setOpenConfirmation(true);
    } else if (open === true || props.location.pathname.includes("/users/")) {
      setOpen(false);
      setEditMode(false);
      history.push("/users");
    }
  };
  useEffect(() => {
    if (open) {
      loadUserDetail();
    }
  }, [open]);

  useEffect(() => {
    if (fromSettings) {
      handleOpen();
    }
  }, [fromSettings]);

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      showLoader(true);
      let roles = allRoles.filter((element) => selected.includes(element.name));
      const response = await ApiServices.httpPut(ADMIN_USER, {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.primaryEmail,
        secondaryEmail: formik.values.secondaryEmail,
        phoneNumber: formik.values.phoneNumber,
        secondaryPhoneNumber: formik.values.secondaryPhoneNumber,
        userTypeId: Number(formik.values.userTypeId),
        userId: formik.values.userId,
        countryId: 1,
        mobile: formik.values.mobile,
        fax1: formik.values.fax1,
        mobile1: formik.values.mobile1,
        fax: formik.values.fax,
        roles: roles,
        distributorTypes: formik.values.distributorType.map((dt) => {
          return {
            distributorTypeId: dt.value,
            name: dt.label,
          };
        }),
        enterpriseTypes: formik.values.enterpriseType.map((et) => {
          return {
            enterpriseTypeId: et.value,
            name: et.label,
          };
        }),
      });
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        handleClose();
        setEditMode(false);
        clearAll();
        toast.success(response.responseStatus.description);
        props.onEditSuccess(response.responseStatus.isSuccess);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const clearAll = () => {
    formik.values.firstName = "";
    formik.values.lastName = "";
    formik.values.primaryEmail = "";
    formik.values.secondaryEmail = "";
    formik.values.phoneNumber = "";
    formik.values.secondaryPhoneNumber = "";
    formik.values.userTypeId = null;
  };

  const isValid = () => {
    return (
      formik.values.firstName &&
      !formik.errors.firstName &&
      formik.values.lastName &&
      !formik.errors.lastName &&
      formik.values.primaryEmail &&
      !formik.errors.primaryEmail &&
      !formik.errors.secondaryEmail &&
      formik.values.phoneNumber &&
      !formik.errors.phoneNumber &&
      !formik.errors.mobile &&
      !formik.errors.fax1 &&
      !formik.errors.mobile1 &&
      !formik.errors.fax &&
      !formik.errors.secondaryPhoneNumber &&
      !formik.errors.roles
    );
  };

  const loadUserDetail = async () => {
    showLoader(true);
    const response = await ApiServices.httpGet(`${ADMIN_USER}/${data.userId}`);
    showLoader(false);
    const user = response.result;
    formik.setValues({
      firstName: user.firstName,
      lastName: user.lastName,
      primaryEmail: user.email,
      secondaryEmail: user.secondaryEmail,
      phoneNumber: user.phoneNumber,
      secondaryPhoneNumber: user.secondaryPhoneNumber,
      userTypeId: user.userTypeId,
      userId: user.userId,
      mobile: user.mobile,
      fax1: user.fax1,
      mobile1: user.mobile1,
      fax: user.fax,
      systemGeneratedId: user.systemGeneratedId,
      createdOn: formatDate(user.createdOn),
      roles: user.roles,
      distributorType: user.distributorTypes.map((dt) => {
        return {
          label: dt.name,
          value: dt.distributorTypeId,
        };
      }),
      enterpriseType: user.enterpriseTypes.map((et) => {
        return {
          label: et.name,
          value: et.distributorTypeId,
        };
      }),
    });
    formik.resetForm();
  };

  const loadDropdownOptions = async () => {
    let role = Number(localStorage.getItem("selectedRole"));
    setAllRoles(filterRoles(role, masterData?.result?.roles));
    setDistributorTypeOptions(
      masterData?.result?.distributorTypes.map((opt) => {
        return {
          value: opt.distributorTypeId,
          label: opt.name,
        };
      })
    );
    setEnterpriseTypeOptions(
      masterData?.result?.enterpriseTypes.map((opt) => {
        return {
          value: opt.enterpriseTypeId,
          label: opt.name,
        };
      })
    );
    formik.setValues({
      ...formik.values,
      distributorType: props.data.distributorTypes.map((dt) => {
        return {
          label: dt.name,
          value: dt.distributorTypeId,
        };
      }),
      enterpriseType: props.data.enterpriseTypes.map((et) => {
        return {
          label: et.name,
          value: et.distributorTypeId,
        };
      }),
    });
  };

  const handleChangeDropDown = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue(
        "roles",
        selected.length === options.length ? [] : options
      );
      return;
    }
    setSelected(value);
    formik.setFieldValue("roles", value);
  };

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const renderMultiSelectRolesDropdown = () => {
    return (
      <div sx={{ width: "100%" }}>
        <label htmlFor="rolesDropdown">Roles</label>
        <MandatoryField />
        <Select
          displayEmpty
          disabled={!isEditMode}
          data-testid="rolesDropdown"
          labelId="mutiple-select-label"
          className={
            isEditMode
              ? "form-select form-control select-user roles-dropdown "
              : "form-select form-control select-user roles-dropdown-disabled"
          }
          multiple
          title={selected}
          value={selected}
          onChange={handleChangeDropDown}
          renderValue={(selectedItem) => {
            if (selected.length === 0) {
              return "Select Roles";
            }
            return selectedItem.join(", ");
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <>
      <Confirmation
        open={openConfirmation}
        title={`Confirmation`}
        description={exitConfirmationMessage}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          handleClose();
          setOpenConfirmation(false);
        }}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Exit"
      />

      {loadEditIcon()}

      <Modal
        open={open}
        onClose={() => {
          handleCancel();
          handleEditModalCancelClick();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <form>
            <div className="modal-header">
              <div className="modal-header-left">{`${
                isEditMode ? "Edit" : "View"
              } User`}</div>
              <div
                className="modal-header-right"
                onClick={() => {
                  handleCancel();
                  handleEditModalCancelClick();
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseOutlinedIcon />
              </div>
            </div>
            <div className="modal-body">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label htmlFor="uniqueId">System Generated ID</label>
                  <MandatoryField />
                  <input
                    name="uniqueId"
                    className="form-label form-control"
                    type="text"
                    disabled
                    value={formik.values.systemGeneratedId}
                  />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="registrationDate">Registration Date</label>
                  <MandatoryField />
                  <input
                    name="registrationDate"
                    className="form-label form-control"
                    type="text"
                    disabled
                    value={formik.values.createdOn}
                  />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <label htmlFor="firstName">First Name</label>
                  <MandatoryField />
                  <input
                    id="firstName"
                    name="firstName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter last name"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.firstName}
                    disabled={!isEditMode}
                    maxLength={25}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.firstName} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="lastName">Last Name</label>
                  <MandatoryField />
                  <input
                    id="lastName"
                    name="lastName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter last name"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.lastName}
                    disabled={!isEditMode}
                    maxLength={25}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.lastName} />
                </Grid>
                {auth.getRole() === SystemUsersEnum.ADMIN ||
                auth.getRole() === SystemUsersEnum.SUPERADMIN ? (
                  <Grid item xs={4} p={2}>
                    <label htmlFor="primaryEmail">Primary Email Address</label>
                    <MandatoryField />
                    {!editMode &&
                      isEditMode &&
                      formik.values.primaryEmail !== "" && (
                        <span onClick={handleEditClick}>
                          <CreateOutlinedIcon className="cus-height-createOutlinedIcon" />
                        </span>
                      )}
                    <>
                      {editMode ? (
                        <div>
                          <input
                            id="primaryEmail"
                            name="primaryEmail"
                            value={formik.values.primaryEmail}
                            onChange={formik.handleChange}
                            className="form-label form-control"
                            type="text"
                            placeholder="Enter Primary Email Address"
                            disabled={!isEditMode}
                          />
                          <InlineMessage error={formik.errors.primaryEmail} />
                        </div>
                      ) : (
                        <a
                          className="primary-email-url"
                          href={`mailto:${formik.values.primaryEmail}`}
                        >
                          {formik.values.primaryEmail}
                        </a>
                      )}
                    </>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={4}>
                  <label htmlFor="secondaryEmail">
                    Secondary Email Address
                  </label>
                  <input
                    id="secondaryEmail"
                    className="form-label form-control"
                    type="email"
                    name="secondaryEmail"
                    placeholder="Enter secondary email address"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.secondaryEmail}
                    disabled={!isEditMode}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.secondaryEmail} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="phoneNumber">Business Phone Number</label>
                  <MandatoryField />
                  <BusinessPhoneNumberTextbox
                    id="phoneNumber"
                    className="form-label form-control"
                    name="phoneNumber"
                    placeholder="Enter Business Phone Number"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.setFieldValue("phoneNumber", e);
                    }}
                    value={formik.values.phoneNumber}
                    disabled={!isEditMode}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.phoneNumber} />
                </Grid>
                {!data.secondaryPhoneNumber && (
                  <Grid item xs={4}>
                    <div
                      className="add-item"
                      onClick={() =>
                        setState({ ...state, addForm: !state.addForm })
                      }
                    >
                      <h5>
                        {!state.addForm
                          ? "+ Add Another Number"
                          : "- Remove Another Number"}
                      </h5>
                    </div>
                  </Grid>
                )}

                {altNumberSection()}
                <Grid item xs={4}>
                  <label htmlFor="mobile">Mobile</label>
                  <PhoneNumberTextbox
                    id="mobile"
                    className="form-label form-control"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.mobile}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={formik.errors.mobile} />
                </Grid>

                {!data.mobile1 && (
                  <Grid item xs={4}>
                    <div
                      data-testid="ash"
                      className="add-item"
                      onClick={() =>
                        setState({ ...state, addMobile: !state.addMobile })
                      }
                    >
                      <h5>
                        {!state.addMobile
                          ? "+ Add Another Mobile"
                          : "- Remove Another Mobile"}
                      </h5>
                    </div>
                  </Grid>
                )}

                {altMobileSection()}

                <Grid item xs={4}>
                  <label htmlFor="fax">Fax</label>
                  <PhoneNumberTextbox
                    id="fax"
                    className="form-label form-control"
                    name="fax"
                    placeholder="Enter Fax"
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.fax}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={formik.errors.fax} />
                </Grid>
                {!data.fax1 && (
                  <Grid item xs={4}>
                    <div
                      className="add-item"
                      onClick={() =>
                        setState({ ...state, addFax: !state.addFax })
                      }
                    >
                      <h5>
                        {!state.addFax
                          ? "+ Add Another Fax"
                          : "- Remove Another Fax"}
                      </h5>
                    </div>
                  </Grid>
                )}

                {altFaxSection()}
                <Grid item xs={12}>
                  <Grid item xs={4}>
                    {renderMultiSelectRolesDropdown()}
                  </Grid>
                  {selectedRoles?.some((r) => r.userTypeId === 3) && (
                    <Grid item xs={4}>
                      <label htmlFor="Roles">Distributor Type</label>
                      <MultiSelectDropDown
                        name="distributorType"
                        id="distributorType"
                        value={formik.values.distributorType}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            distributorType: value,
                          });
                        }}
                        disabled={!isEditMode}
                        options={distributorTypeOptions}
                      />
                    </Grid>
                  )}
                  {selectedRoles?.some((r) => r.userTypeId === 4) && (
                    <Grid item xs={4}>
                      <label htmlFor="Roles">Enterprise Type</label>
                      <MultiSelectDropDown
                        name="enterpriseType"
                        id="enterpriseType"
                        value={formik.values.enterpriseType}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            enterpriseType: value,
                          });
                        }}
                        disabled={!isEditMode}
                        options={enterpriseTypeOptions}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="modal-buttons">
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  onClick={() => {
                    handleCancel();
                    handleEditModalCancelClick();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="action-button"
                  disabled={!isEditMode || !isValid()}
                  onClick={onUpdate}
                >
                  Update Details
                </Button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );

  function initialFormik() {
    return {
      initialValues: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        primaryEmail: data?.email,
        secondaryEmail: data?.secondaryEmail,
        phoneNumber: data?.phoneNumber,
        secondaryPhoneNumber: data?.secondaryPhoneNumber || "",
        userTypeId: userRoleId === 1 ? 0 : 2,
        userId: data?.userId,
        mobile: data?.mobile || "",
        mobile1: data?.mobile1 || "",
        fax: data?.fax || "",
        fax1: data?.fax1 || "",
        systemGeneratedId: data?.systemGeneratedId,
        createdOn: formatDate(data?.createdOn),
        roles: data?.roles,
        distributorType: [],
        enterpriseType: [],
        dirty: false,
      },
      enableReinitialize: true,
      validationSchema: UserSignupSchema,
    };
  }

  function altFaxSection() {
    return (
      (state.addFax || data.fax1) && (
        <Grid item xs={4}>
          <label htmlFor="fax1">Alternate Fax</label>
          <PhoneNumberTextbox
            id="fax1"
            className="form-label form-control"
            name="fax1"
            placeholder="Enter Another Fax"
            onChange={(e) => {
              formik.setFieldValue("dirty", true);
              formik.handleChange(e);
            }}
            value={formik.values.fax1}
            disabled={!isEditMode}
          />
          <InlineMessage error={formik.errors.fax1} />
        </Grid>
      )
    );
  }

  function altMobileSection() {
    return (
      (state.addMobile || data.mobile1) && (
        <Grid item xs={4}>
          <label htmlFor="mobile1">Alternate Mobile</label>
          <PhoneNumberTextbox
            id="mobile1"
            className="form-label form-control"
            name="mobile1"
            placeholder="Enter Another Mobile Number"
            onChange={(e) => {
              formik.setFieldValue("dirty", true);
              formik.handleChange(e);
            }}
            value={formik.values.mobile1}
            disabled={!isEditMode}
          />
          <InlineMessage error={formik.errors.mobile1} />
        </Grid>
      )
    );
  }

  function altNumberSection() {
    return (
      (state.addForm || data.secondaryPhoneNumber) && (
        <Grid item xs={4}>
          <label htmlFor="secondaryPhoneNumber">Business 2 Phone Number</label>
          <BusinessPhoneNumberTextbox
            id="secondaryPhoneNumber"
            className="form-label form-control"
            name="secondaryPhoneNumber"
            placeholder="Enter Business 2 Phone Number"
            onChange={(e) => {
              formik.setFieldValue("dirty", true);
              formik.setFieldValue("secondaryPhoneNumber", e);
            }}
            value={formik.values.secondaryPhoneNumber}
            disabled={!isEditMode}
          />
          <InlineMessage error={formik.errors.secondaryPhoneNumber} />
        </Grid>
      )
    );
  }

  function loadEditIcon() {
    const whenEditMode = !fromSettings ? (
      <img
        className="edit-button"
        src="/images/edit_enable.png"
        alt="view-icon"
        onClick={handleOpen}
        data-testid="EditIcon"
        width={20}
      />
    ) : null;
    return isEditMode ? (
      whenEditMode
    ) : (
      <img
        className="view-button"
        src="/images/view_enable.png"
        alt="view-icon"
        onClick={handleOpen}
        data-testid="EditIcon"
        width={20}
      />
      // <VisibilityIcon onClick={handleOpen} data-testid="EditIcon" />
    );
  }
};

export default EditAdminModal;
