import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import "./ConfirmSignup.css";
import React, { useEffect, useState } from "react";
import MultiSelectDropDown from "../../common/MultiSelectDropDown/MultiSelectDropDown";
import PhoneNumberTextbox from "../../common/PhoneNumberTextbox/PhoneNumberTextbox";
import SearchableDropDown from "../../common/SearchableDropDown/SearchableDropdown";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiServices from "../../services/ApiServices";
import {
  ADD_DISTRIBUTOR,
  ADMIN_USER,
  ENTERPRISE_GROUP_USER,
  UPDATE_SIGNUP,
} from "../../constants/api-constants";
import { formatDate } from "../../utilities/formatter";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  execeptionMessage,
  DistConfirmationMessage,
  EGConfirmationMessage,
} from "../../constants/common";
import { useLoading } from "../../hooks/useLoader";
import { AssociationStatusEnum } from "../../common/Modal/AssociationStatusEnum";
import ConfirmationWithRejectReason from "../../common/ConfirmationWithRejectReason/ConfirmationWithRejectReason";
import Confirmation from "../../common/Confirmation/Confirmation";
import { Link } from "react-router-dom";
import { usePageMode } from "../../hooks/usePageMode";
import { useMasterData } from "../../hooks/useMasterData";
import { useApprovalContext } from "../ApprovalRequests/Approval.context";
import { SystemUsersEnum } from "../../constants/SystemUsers";

const ConfirmSignup = (props) => {
  const { showLoader } = useLoading();
  const page = usePageMode();
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [carrierRepresents, setCarrierRepresents] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userData, setUserData] = useState(null);
  const [expanded, setExpanded] = useState("panel1");

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [actionName, setActionName] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const isAllSelected =
    roleOptions &&
    roleOptions.length > 0 &&
    selectedRoles.length === roleOptions.length;
  const { id, requestId } = useParams();
  const masterDataContext = useMasterData();
  const approvalContext = useApprovalContext();
  const [isEditMode, setIsEditMode] = useState(approvalContext.isEditMode);
  const [warningConfirmationMessage, setWarningConfirmationMessage] =
    useState("");
  const [showWarningConfirmation, setShowWarningConfirmation] = useState(false);

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setActionName(actionName);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        showLoader(true);
        const response = masterDataContext.masterDataValue;

        setEnterpriseTypeOptions(
          response.result.enterpriseTypes.map((item) => {
            return { value: item.enterpriseTypeId, label: item.name };
          })
        );
        setAreaOptions(response.result.areas);
        setCarrierRepresents(response.result.carrierRepresents);
        setCarriers(response.result.carriers);
        setIndustry(response.result.industryGroups);
        setTechnology(response.result.technologies);
        setReasonOptions(
          response.result.masterData.map((option) => {
            return {
              value: option.masterDataId,
              name: option.name,
            };
          })
        );
        setRoleOptions(
          response.result.roles.map((option) => {
            return {
              value: option.roleId,
              name: option.name,
              isDefault: option.isDefault,
              roleUserType: option.roleTypes[0]?.userType,
            };
          })
        );

        // Load Basic details
        const userDetail = (await ApiServices.httpGet(`${ADMIN_USER}/${id}`))
          .result;
        const whenUserTypeNot3 =
          userDetail?.userTypeId === 4 ? ENTERPRISE_GROUP_USER : 0;
        const serviceUrl =
          userDetail?.userTypeId === 3 ? ADD_DISTRIBUTOR : whenUserTypeNot3;

        const basicResponse = await ApiServices.httpGet(`${serviceUrl}/${id}`);
        showLoader(false);
        setSelectedRoles(basicResponse.result.roles.map((role) => role.roleId));
        setUserData(basicResponse.result);
      } catch (e) {
        showLoader(false);
      }
    }

    loadData();
  }, []);
  /*istanbul ignore next */
  const onEdit = () => {
    setIsEditMode(true);
  };

  /*istanbul ignore next */
  const updateApproval = async (
    associatonStatus,
    rejectReason,
    additionalDetails
  ) => {
    try {
      showLoader(true);
      const payload = {
        approvalId: Number(requestId),
        userId: Number(id),
        status: associatonStatus,
        rejections:
          associatonStatus === AssociationStatusEnum.Rejected
            ? [
                {
                  rejectReasonId: Number(rejectReason),
                  additionalDetails: additionalDetails,
                },
              ]
            : [],
        roles: selectedRoles.map((role) => {
          return {
            roleId: role,
          };
        }),
      };
      const response = await ApiServices.httpPost(UPDATE_SIGNUP, payload);
      page.setDirty(false);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
      return response;
    } catch (err) {
      toast.error(execeptionMessage);
      return err;
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const performApproval = async () => {
    setActionName(AssociationStatusEnum.Approved);
    await updateApproval(AssociationStatusEnum.Approved);
    setTimeout(() => {
      history.push("/approvals");
    }, 3000);
  };
  /*istanbul ignore next */
  const onApprove = async () => {
    let singleRole =
      selectedRoles.length === 1
        ? roleOptions.find((item) => item.value === selectedRoles[0])
        : null;
    if (singleRole?.isDefault) {
      if (singleRole.roleUserType === SystemUsersEnum.DISTRIBUTOR)
        setWarningConfirmationMessage(DistConfirmationMessage);

      if (singleRole.roleUserType === SystemUsersEnum.ENTERPRISE)
        setWarningConfirmationMessage(EGConfirmationMessage);
      setShowWarningConfirmation(true);
    } else {
      performApproval();
    }
  };
  /*istanbul ignore next */
  const handleRoleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(
        selectedRoles.length === roleOptions.length
          ? []
          : roleOptions.map((item) => item.value)
      );
      return;
    }
    setSelectedRoles(value);
  };

  const onReject = () => {
    setActionName(AssociationStatusEnum.Rejected);
    handleConfirmationOpen(
      AssociationStatusEnum.Rejected,
      "Are you sure you want to reject this request?"
    );
  };

  return (
    <div className="approval-container">
      <div className="approval-basic-details">
        <div>
          <div className="approval-page-title">Basic Information</div>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="distributor-breadcrumbs"
          >
            <Link underline="hover" color="inherit" to="/approvals">
              Approval
            </Link>

            <Typography color="text.primary" className="last-node">
              Basic Information
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="modal-footer">
          {/* <ToastContainer closeButton={true} position="top-right" /> */}

          <Button
            className="action-button mr-4"
            variant="contained"
            onClick={() => history.push("/approvals")}
          >
            Cancel
          </Button>

          {!isEditMode && (
            <Button
              className="action-button mr-4"
              variant="contained"
              onClick={onEdit}
            >
              Edit
            </Button>
          )}

          <Button
            className="action-button mr-4"
            variant="contained"
            color="success"
            onClick={onApprove}
            disabled={!isEditMode || !(selectedRoles.length > 0)}
          >
            Approve
          </Button>

          <Button
            className="action-button mr-4"
            variant="contained"
            color="error"
            onClick={onReject}
            disabled={!isEditMode || !(selectedRoles.length > 0)}
          >
            Reject
          </Button>
        </div>
      </div>

      <Grid container spacing={2} paddingLeft={4} marginTop={2}>
        <Grid item xs={3} p={2}>
          <div>Full Name</div>
          <div className="approval-basic-info">
            {userData?.firstName + " " + userData?.lastName}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Email</div>
          <div className="approval-basic-info">{userData?.email}</div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>System Generated ID</div>
          <div className="approval-basic-info">
            {userData?.systemGeneratedId}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Registration Date</div>
          <div className="approval-basic-info">
            {formatDate(userData?.createdOn)}
          </div>
        </Grid>
      </Grid>

      <div className="parent-div">
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>Basic Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <div>
              <div className="modal-body">
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={4} p={2}>
                      <label htmlFor="systemGeneratedId">
                        System Generated Id
                      </label>
                      <input
                        id="systemGeneratedId"
                        name="systemGeneratedId"
                        className="form-label form-control"
                        type="text"
                        placeholder="Enter First Name"
                        disabled
                        value={userData?.systemGeneratedId}
                        maxLength={25}
                      />
                    </Grid>
                    <Grid item xs={4} p={2}>
                      <label htmlFor="createdOn">Registration Date</label>
                      <input
                        id="createdOn"
                        name="createdOn"
                        className="form-label form-control"
                        type="text"
                        placeholder="Enter Last Name"
                        disabled
                        maxLength={25}
                        value={formatDate(userData?.createdOn)}
                      />
                    </Grid>
                    <Grid item xs={4} p={2}>
                      <label
                        id="label-for-role-dropdown"
                        htmlFor="roles-dropdown"
                      >
                        Roles
                      </label>
                      <MandatoryField />
                      <Select
                        displayEmpty
                        data-testid="rolesDropdown"
                        id="roles-dropdown"
                        name="roles-dropdown"
                        labelId="label-for-role-dropdown"
                        className="form-select form-control select-user roles-dropdown"
                        multiple
                        disabled={!isEditMode}
                        value={selectedRoles}
                        title={selectedRoles}
                        onChange={handleRoleChange}
                        renderValue={(selectedItems) => {
                          if (selectedRoles.length === 0) {
                            return "Select Roles";
                          }
                          return roleOptions
                            .filter((item) =>
                              selectedItems.includes(item.value)
                            )
                            .map((item) => item.name)
                            .join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="all">
                          <ListItemIcon>
                            <Checkbox
                              checked={isAllSelected}
                              indeterminate={
                                selectedRoles.length > 0 &&
                                selectedRoles.length < roleOptions.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {roleOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            label={option.name}
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  selectedRoles.indexOf(option.value) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="firstName">First Name</label>
                    <MandatoryField />
                    <input
                      id="firstName"
                      name="firstName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter First Name"
                      disabled
                      maxLength={25}
                      value={userData?.firstName}
                    />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="lastName">Last Name</label>
                    <MandatoryField />
                    <input
                      id="lastName"
                      name="lastName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      disabled
                      maxLength={25}
                      value={userData?.lastName}
                    />
                  </Grid>

                  {userData?.userTypeId === 3 && (
                    <Grid item xs={4} p={2} className="blue-field-section">
                      <label htmlFor="distributorType">Distributor Type</label>
                      {/* <MandatoryField /> */}
                      <MultiSelectDropDown
                        name="distributorType"
                        id="distributorType"
                        disabled
                        value={userData?.distributorTypes?.map((item) => {
                          return {
                            value: item.distributorTypeId,
                            label: item.name,
                          };
                        })}
                        options={enterpriseTypeOptions}
                      />
                    </Grid>
                  )}

                  {userData?.userTypeId === 4 && (
                    <Grid item xs={4} p={2} className="blue-field-section">
                      <label htmlFor="distributorType">Enterprise Type</label>
                      {/* <MandatoryField /> */}
                      <MultiSelectDropDown
                        name="distributorType"
                        id="distributorType"
                        disabled
                        value={userData?.enterpriseTypes?.map((item) => {
                          return {
                            value: item.enterpriseTypeId,
                            label: item.name,
                          };
                        })}
                        options={enterpriseTypeOptions}
                      />
                    </Grid>
                  )}

                  {props?.routeData?.userTypeId === 7 && (
                    <Grid
                      item
                      sx={4}
                      p={2}
                      className="blue-field-section serachable-dropdown"
                    >
                      <label htmlFor="DistributorIDEnterpriseID">
                        Distributor ID / Enterprise ID
                      </label>
                      <MandatoryField />
                      <SearchableDropDown
                        // options={
                        //   edTypes && edTypes.allEDUsers ? edTypes.allEDUsers : []
                        // }
                        name="DistributorIDEnterpriseID"
                        id="DistributorIDEnterpriseID"
                        // setData={onChangeDropDown}
                        placeholder="Select Enterprise/Distributor Id"
                        disabled
                      />
                    </Grid>
                  )}

                  <Grid item xs={8} p={2} className="blue-field-section">
                    <label htmlFor="primaryEmail">Primary Email Address</label>

                    <MandatoryField />
                    <a
                      className="primary-email-url"
                      href={`mailto:${userData?.email}`}
                    >
                      {userData?.email}
                    </a>
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section"></Grid>

                  <Grid item xs={8} p={2} className="blue-field-section">
                    <label htmlFor="secondaryEmail">
                      Secondary Email Address
                    </label>
                    <input
                      id="secondaryEmail"
                      name="secondaryEmail"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Secondary Email Address"
                      disabled
                      value={userData?.secondaryEmail}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="areas">Brand Name</label>

                    <input
                      id="lastName"
                      name="lastName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      disabled
                      maxLength={25}
                      value={userData?.brandName}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="carriers">Carriers</label>

                    <MultiSelectDropDown
                      id="carriers"
                      name="carriers"
                      isMulti
                      disabled
                      value={userData?.carriers?.map((res) => {
                        return {
                          value: res.carrierId,
                          label: res.name,
                        };
                      })}
                      options={carriers.map((res) => {
                        return {
                          value: res.carrierId,
                          label: res.name,
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="areas">Major Areas</label>

                    <MultiSelectDropDown
                      id="areas"
                      name="areas"
                      isMulti
                      disabled
                      value={userData?.areas?.map((res) => {
                        return {
                          value: res.areaId,
                          label: res.name,
                        };
                      })}
                      options={areaOptions.map((res) => {
                        return {
                          value: res.areaId,
                          label: res.name,
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="producers">Number of producers</label>
                    <input
                      id="producers"
                      name="producers"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Number of producers"
                      disabled
                      value={userData?.producers}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="carrierRepresents">
                      Carriers represented
                    </label>

                    <MultiSelectDropDown
                      id="carrierRepresents"
                      name="carrierRepresents"
                      menuShouldBlockScroll
                      isMulti
                      value={userData?.carrierRepresents?.map((res) => {
                        return {
                          value: res.carrierRepresentId,
                          label: res.name,
                        };
                      })}
                      options={carrierRepresents.map((res) => {
                        return {
                          value: res.carrierRepresentId,
                          label: res.name,
                        };
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="technologies">
                      Technology being used now
                    </label>

                    <MultiSelectDropDown
                      id="technologies"
                      name="technologies"
                      disabled
                      value={userData?.technologies?.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      options={technology.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="businessYear">Years in Business</label>
                    <input
                      id="businessYear"
                      name="businessYear"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Years in Business"
                      disabled
                      value={userData?.businessYear}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="annualRevenue">Annual revenue</label>

                    <select
                      id="annualRevenue"
                      name="annualRevenue"
                      className="form-control form-select"
                      disabled
                      value={userData?.annualRevenueId}
                    >
                      <option>Select Revenue</option>
                      <option value={1}>0-100k</option>
                      <option value={2}>101 to 500k</option>
                      <option value={3}>500k to 1,250,000</option>
                      <option value={4}>1,250,000 to 2,500,000</option>
                      <option value={5}>2,500,000 to 5,000,000</option>
                      <option value={6}>5,000,000 to 10,000,000</option>
                      <option value={7}>10,000,000 to 25,000,000"</option>
                      <option value={8}> &#62; 25,000,000</option>
                    </select>
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="numberOfEmployees">
                      Number of employees
                    </label>
                    <input
                      id="numberOfEmployees"
                      name="numberOfEmployees"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Number of employees"
                      disabled
                      value={userData?.numberOfEmployees}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="numberOfOwners">
                      Number of principals / owners
                    </label>
                    <input
                      id="numberOfOwners"
                      name="numberOfOwners"
                      className="form-label form-control"
                      type="text"
                      value={userData?.numberOfOwners}
                      placeholder="Enter Number of principals / owners"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="websiteUrl">Website URL</label>
                    <input
                      id="websiteUrl"
                      name="websiteUrl"
                      // onChange={(e) => {
                      //   formik.handleChange(e);
                      // }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Website URL"
                      disabled
                      value={userData?.websiteUrl}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="industryGroups">
                      Industry Groups/ Membership
                    </label>
                    <MultiSelectDropDown
                      id="industryGroups"
                      name="industryGroups"
                      isMulti
                      value={userData?.industryGroups?.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      options={industry.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <MandatoryField />
                    <PhoneNumberTextbox
                      id="phoneNumber"
                      className="form-label form-control"
                      name="phoneNumber"
                      placeholder="Enter Business Phone Number"
                      value={userData?.phoneNumber}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="secondaryPhoneNumber">
                      Secondary Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="secondaryPhoneNumber"
                      className="form-label form-control"
                      name="secondaryPhoneNumber"
                      placeholder="Enter Phone Number"
                      value={userData?.secondaryPhoneNumber}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="businessName">Business Name</label>
                    <input
                      id="businessName"
                      name="businessName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Business Name"
                      value={userData?.businessName}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="taxID">Tax Id</label>
                    <input
                      id="taxID"
                      name="taxID"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Tax ID"
                      value={userData?.taxID}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <label htmlFor="mobile">Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile"
                      className="form-label form-control"
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      value={userData?.mobile}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="mobile1">Alternate Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile1"
                      className="form-label form-control"
                      name="mobile1"
                      placeholder="Enter Alternate Mobile Number"
                      value={userData?.mobile1}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <label htmlFor="fax">Fax</label>
                    <PhoneNumberTextbox
                      id="fax"
                      className="form-label form-control"
                      name="fax"
                      placeholder="Enter Fax"
                      value={userData?.fax}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="mobile1">Alternate Fax</label>
                    <PhoneNumberTextbox
                      id="fax1"
                      className="form-label form-control"
                      name="fax1"
                      placeholder="Enter Alternate Fax"
                      value={userData?.fax1}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContact">Billing First Name </label>
                    <input
                      id="billingContact"
                      name="billingContact"
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing First Name"
                      value={userData?.billingContact}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContactLastName">
                      Billing Last Name{" "}
                    </label>
                    <input
                      id="billingContactLastName"
                      name="billingContactLastName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing Last Name"
                      value={userData?.billingContactLastName}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingPhoneNumber">
                      Billing Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="billingPhoneNumber"
                      className="form-label form-control"
                      name="billingPhoneNumber"
                      placeholder="Enter Billing Phone Number"
                      value={userData?.billingPhoneNumber}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine1">
                      Billing Address Line 1
                    </label>
                    <input
                      id="billingAddressLine1"
                      name="billingAddressLine1"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 1 "
                      value={userData?.billingAddressLine1}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine2">
                      Billing Address Line 2
                    </label>
                    <input
                      id="billingAddressLine2"
                      name="billingAddressLine2"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 2"
                      value={userData?.billingAddressLine2}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="zip">Zip Code</label>
                    <input
                      id="zip"
                      name="zip"
                      className="form-label form-control"
                      type="text"
                      maxLength={5}
                      placeholder="Enter Zip Code"
                      value={userData?.zipCode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      id="city"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter City"
                      value={userData?.city}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="state">State</label>
                    <input
                      id="state"
                      name="state"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter State"
                      disabled
                      value={userData?.state}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="countryId">Country</label>
                    <select
                      id="countryId"
                      name="countryId"
                      className="form-control form-select"
                      disabled
                      value={userData?.countryId}
                    >
                      <option value={1}>USA</option>
                      <option value={2}>Canada</option>
                    </select>
                  </Grid>
                </Grid>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Confirmation
          open={showWarningConfirmation}
          title={warningConfirmationMessage}
          handleClose={() => {
            /*istanbul ignore next */
            setShowWarningConfirmation(false);
            setWarningConfirmationMessage("");
          }}
          onCancel={() => {
            /*istanbul ignore next */
            setShowWarningConfirmation(false);
            setWarningConfirmationMessage("");
          }}
          onConfirm={() => {
            /*istanbul ignore next */
            setShowWarningConfirmation(false);
            setWarningConfirmationMessage("");
            performApproval();
          }}
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
        />
        <ConfirmationWithRejectReason
          open={confirmation}
          handleClose={handleConfirmationClose}
          title={confirmationMessage}
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Reject"
          showReason={true}
          actionName={actionName}
          options={reasonOptions}
          onConfirm={async (e, rejectReason, additionalDetails) => {
            await updateApproval(
              AssociationStatusEnum.Rejected,
              rejectReason,
              additionalDetails
            );
            setTimeout(() => {
              handleConfirmationClose();
              history.push("/approvals");
            }, 3000);
          }}
          onCancel={() => {
            handleConfirmationClose();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmSignup;
