import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../Modal/Modal.css";
import "../Modal/UploadUserModal.css";
import React, { useState, useRef } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Link } from "react-router-dom";
import { useLoading } from "../../hooks/useLoader";
import { toast } from "react-toastify";
import ApiServices from "../../services/ApiServices";
import {
  BULK_UPLOAD_CONTENT_SUBSCRIBERS,
  DISTRIBUTOR_INTERNAL_USER_BULK_UPLOAD,
  MASS_UPLOAD_CONTENT,
  SUBSCRIBER_BULK_UPLOAD,
} from "../../constants/api-constants";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { GetAllSelectedIds } from "../../utilities/SelectionFilter";

// import template from "../../templates/Distributer_Internal_User_Bulk_Upload.xlsx"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};
let response1;
const UploadUserModal = (props) => {
  const { bulkUploadTemplate } = props;
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(null);
  const { showLoader } = useLoading();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFile(null);
    setResult(null);
    setOpen(false);
  };

  const aRef = useRef("");
  const handleUpload = async () => {
    if (window.location.pathname === "/subscribers") {
      await subscriberUpload(showLoader, file, aRef, setResult, props);
    } else if (props.from === "mass-upload-content") {
      await massUploadContent(
        showLoader,
        file,
        props,
        handleClose,
        aRef,
        setResult
      );
    } else if (props.from === "content-manage-subscribers") {
      await contentManageSubscribersUpload(
        showLoader,
        file,
        aRef,
        setResult,
        props
      );
    } else {
      await commonUpload(showLoader, props, file, aRef, setResult);
    }
  };

  const getFileExtension = (fname) => {
    return fname
      .slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1)
      .toLowerCase();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxFileSize =
      props.from === "mass-upload-content" ? 5000000 : 2000000;
    if (selectedFile.size > maxFileSize) {
      toast.error(
        `File size should below ${
          props.from === "mass-upload-content" ? "5mb" : "2mb"
        }`
      );
      return;
    }
    const fileExtensions = ["xls", "xlsx"];
    let ext = getFileExtension(selectedFile.name);
    if (!fileExtensions.includes(ext)) {
      toast.error("Please select xls/xlsx file");
      return;
    }

    setFile(e.target.files[0]);
    setResult(null);
  };

  const downloadHandler = (e) => {
    ExportToExcelHandler(
      result.file.fileContents,
      result.file.fileDownloadName
    );
  };

  const downloadTemplate = () => {
    let link = document.createElement("a");
    let filePath = bulkUploadTemplate;
    link.href = filePath;
    link.download = filePath.substring(filePath.lastIndexOf("/") + 1);
    link.click();
  };

  return (
    <>
      {!props.from && props.from !== "content-manage-subscribers" && (
        <Tooltip placement="bottom" title={"Bulk Upload Users"}>
          <CloudUploadOutlinedIcon
            data-testid="CloudUploadOutlinedIcon"
            onClick={handleOpen}
          />
        </Tooltip>
      )}
      {props.from === "content-manage-subscribers" && (
        <Tooltip placement="bottom" title={"Bulk Upload Content Subscribers"}>
          {props.isSelected ? (
            <CloudUploadOutlinedIcon
              data-testid="CloudUploadOutlinedIconEnabled"
              onClick={handleOpen}
            />
          ) : (
            <CloudUploadOutlinedIcon
              data-testid="CloudUploadOutlinedIconDisabled"
              style={{ backgroundColor: "#83b0c7" }}
            />
          )}
        </Tooltip>
      )}
      {props.from === "mass-upload-content" && (
        <Button
          data-testid="mass-upload-content-btn"
          className="action-button"
          type="button"
          variant="contained"
          onClick={handleOpen}
        >
          Mass Upload
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="file-upload-modal">
          <div className="modal-header">
            <div className="modal-header-left">
              {props.title ? props.title : "Bulk Upload Users"}
            </div>
            <div className="modal-header-right">
              <CloseOutlinedIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="modal-body file-upload-body">
            <div className="text-center">
              <img src="/images/export.png" alt="export" />
              <div className="file-upload">
                <input
                  ref={aRef}
                  className="file-upload-input"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileChange}
                  data-testid="file-upload-input"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p>Drag files to upload, or browse.</p>
              <p>
                File size should below{" "}
                {props.from === "mass-upload-content" ? "5mb" : "2mb"} and .xls
                / .xlsx
              </p>
              <div>
                <h6 className="file-name uploaded-file text-align-center-upload-modal">
                  File Name :{" "}
                  {file?.name ? `${file?.name}` : "Please Select a file"}
                </h6>
              </div>
            </div>
          </div>
          <div
            className={`${
              props.from === "mass-upload-content" && "mass-upload-content"
            } modal-footer my-modal-footer`}
          >
            <div
              className="modal-switch"
              style={{
                marginBottom: props.from === "mass-upload-content" && "10px",
              }}
            >
              {props.from === "mass-upload-content" &&
                "Please upload the file or you can download the template "}

              <Link data-testid="downloadTemp" onClick={downloadTemplate}>
                {props.from === "mass-upload-content"
                  ? "here."
                  : "Download Template"}
              </Link>
            </div>
            <div modal-footer>
              <Button
                variant="outlined"
                className="action-button"
                onClick={() => {
                  handleClose();
                  setFile(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="action-button"
                onClick={handleUpload}
                disabled={!file || result}
              >
                Upload
              </Button>
            </div>
          </div>
          {result && file && (
            <div className="modal-body tabular-body">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>TOTAL</TableCell>
                    <TableCell>SUCCESS</TableCell>
                    <TableCell>ERROR</TableCell>
                    <TableCell>DOWNLOAD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="1">
                    <TableCell>{result.totalRecord}</TableCell>
                    <TableCell>{result.totalSuccess}</TableCell>
                    <TableCell>{result.totalError}</TableCell>
                    <TableCell>
                      {result.totalRecord !== result.totalSuccess && (
                        <Link data-testid="download" onClick={downloadHandler}>
                          Download
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UploadUserModal;
async function commonUpload(showLoader, props, file, aRef, setResult) {
  try {
    showLoader(true);
    const formData = new FormData();
    formData.append("UserId", props.userId);
    formData.append("File", file);
    formData.append("FileName", file.name);
    response1 = await ApiServices.httpPostFile(
      DISTRIBUTOR_INTERNAL_USER_BULK_UPLOAD,
      formData
    );

    showLoader(false);
    if (response1.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response1.result);

      if (response1.result?.totalSuccess > 0) {
        props.onBulkUpload();
      }
    } else {
      toast.error(response1.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}

async function contentManageSubscribersUpload(
  showLoader,
  file,
  aRef,
  setResult,
  props
) {
  try {
    showLoader(true);
    let ids = GetAllSelectedIds("contentId");
    const formData = new FormData();
    for (let i = 0; i < ids.length; i++) {
      formData.append("ContentIds", ids[i]);
    }
    formData.append("File", file);
    response1 = await ApiServices.httpPostFile(
      BULK_UPLOAD_CONTENT_SUBSCRIBERS,
      formData
    );

    showLoader(false);
    if (response1.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response1.result);

      if (response1.result?.totalSuccess > 0) {
        //to refresh just using apply filters with old data
        props.setRefresh(props.contentQuery);
      }
    } else {
      toast.error(response1.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}

async function massUploadContent(
  showLoader,
  file,
  props,
  handleClose,
  aRef,
  setResult
) {
  try {
    showLoader(true);
    const formData = new FormData();
    formData.append("File", file);
    formData.append("FileName", file.name);
    formData.append("UserId", props.userId);
    const response = await ApiServices.httpPostFile(
      MASS_UPLOAD_CONTENT,
      formData
    );
    showLoader(false);
    props.getContentList("openRequestPopup");
    handleClose();
    if (response.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response.result);
    } else {
      toast.error(response.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}

async function subscriberUpload(showLoader, file, aRef, setResult, props) {
  try {
    showLoader(true);
    const formData = new FormData();
    formData.append("File", file);
    formData.append("FileName", file.name);
    const response = await ApiServices.httpPostFile(
      SUBSCRIBER_BULK_UPLOAD,
      formData
    );
    showLoader(false);
    if (response.responseStatus?.isSuccess) {
      aRef.current.value = null;
      setResult(response.result);

      if (response.result?.totalSuccess > 0) {
        props.distributorsDetails();
      }
    } else {
      toast.error(response.responseStatus?.error);
    }
  } catch (event) {
    showLoader(false);
    toast.error("Internal server error");
  }
}
