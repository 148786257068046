import { Button, Grid } from "@mui/material";
import "./CreateEnterpriseModal.css";
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import MultiSelectDropDown from "../../MultiSelectDropDown/MultiSelectDropDown";
import { formatDate } from "../../../utilities/formatter";
import PhoneNumberTextbox from "../../PhoneNumberTextbox/PhoneNumberTextbox";
import BusinessPhoneNumberTextbox from "../../PhoneNumberTextbox/BusinessPhoneNumberTextbox";
import { useHistory } from "react-router";
import { usePageMode } from "../../../hooks/usePageMode";
import InlineMessage from "../../InlineMessage/InlineMessage";
import { getServiceInstance } from "../../../services/UserServices/UserService";
import { useLoading } from "../../../hooks/useLoader";
import SearchableDropDown from "../../SearchableDropDown/SearchableDropdown";
import { useAuth } from "../../../hooks/useAuth";
import { useMasterData } from "../../../hooks/useMasterData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material/";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import { SystemUsersEnum, FrequencyEnum } from "../../../constants/SystemUsers";
import filterRoles from "../../../utilities/filterRoles";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MandatoryField } from "../../MandatoryFieldsIcon/MandatoryField";
import {
  PARENT_ENTERPRISE,
  UPLOAD_LOGO,
  STOP_SUBSCRIPTION,
  RENEWAL_HISTORY,
} from "../../../constants/api-constants";
import ApiServices from "../../../services/ApiServices";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { AssociationStatusEnum } from "../AssociationStatusEnum";
import FooterButtons from "../../FooterButtons/FooterButtons";
import { brandLogoAcceptedFormats } from "../../../screens/Distributors/constants";
import FileUploader from "../../FileUploader/FileUploader";
import {
  fetchImageAsBase64,
  getFileExtension,
} from "../../../utilities/common";
import CurrencyTextbox from "../../CurrencyTextbox/CurrencyTextbox";
import { REGULAR_EXPRESSION } from "../../../constants/regular-expressions";
import DataTable from "../../../screens/Tables/DataTable/DataTable";
import { EGRenewalHistoryColumns } from "../../../common/TableColumnModal/DefaultDataColumns";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};
/*istanbul ignore next */
const CreateEnterpriseModal = (props) => {
  const {
    permissions,
    PermissionEnum,
    basicDetailPermission,
    associationDetailsPermission,
    // publisherDetailsPermission,
  } = props;
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const history = useHistory();
  const auth = useAuth();
  const page = usePageMode();
  const { showLoader } = useLoading();
  const permissionId = auth.getPermissionId();
  const userService = getServiceInstance(props.routeData?.userTypeId);
  const [oldAssociatedData, setOldAssociatedData] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [type, setType] = useState("");
  const [showAnotherNumber, setShowAnotherNumber] = useState(false);
  const [showAnotherMobile, setShowAnotherMobile] = useState(false);
  const [showAnotherFax, setShowAnotherFax] = useState(false);
  const [distributorTypeOptions, setDistributorTypeOptions] = useState([]);
  const [annualRevenues, setAnnualRevenues] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [career, setCareer] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(
    props.formData.status > 0 && props.isEditMode
  );
  const [edTypes, setEDTypes] = useState([]);
  const [parentDropdown, setParentDropdown] = useState([]);
  const [selectedParentValue, setSelectedParentValue] = useState(null);
  const [memberShip, setMembership] = useState(null);
  const [createNewAssociation, setCreateNewAssociation] = useState(false);
  const [logo, setLogo] = useState("");
  const [renewalhistoryPopupConfirmation, setRenewalhistoryPopupConfirmation] =
    useState(false);
  const [renewalHistoryList, setRenewalHistoryList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const masterDataContext = useMasterData();

  const formik = useFormik({
    initialValues: props.formData,
    enableReinitialize: true,
    validationSchema: props?.routeData?.schema,
    dirty: false,
  });

  useEffect(() => {
    page.setDirty(formik.values.dirty);
  }, [formik.values.dirty]);

  useEffect(() => {
    setIsEditMode((prevState) => {
      return formik.values.status > 0 && prevState;
    });
  }, [formik.values.status]);

  useEffect(() => {
    if (props.formData.userId > 0) {
      formik.setValues(props.formData);
      let data = parentDropdown.filter(
        (x) => x.userId === props.formData.parentUser?.parentUserId
      );
      if (data.length > 0) {
        setSelectedParentValue([{ id: data[0].userId, label: data[0].name }]);
      } else {
        setSelectedParentValue([{ id: 0, label: "" }]);
      }

      setMembership(
        props.formData.parentUser?.parentUserId
          ? `INCHANTED_${props.formData.parentUser?.parentUserId}`
          : ""
      );
      setRoles(props.formData.roles);
      if (props.formData.associateDistributors) {
        props.formData.associateDistributors.forEach((element, index) => {
          getSubscriptionTypeDataHandler(
            element.distributorUserId.value,
            index
          );
        });
      }
    }
  }, [props.formData, props.formData.userId, parentDropdown]);

  const [associationPopupConfirmation, setAssociationPopupConfirmation] =
    useState(false);
  const [associationPopupDeletion, setAssociationPopupDeletion] =
    useState(false);
  const [associationPopupRenew, setAssociationPopupRenew] = useState(false);
  const [associateDistributorObject, setAssociateDistributorObject] = useState(
    {}
  );
  const [expanded, setExpanded] = React.useState(defaultExpandedValue(history));
  const [distributorData, setDistributorData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const options = allRoles?.map((item) => item.name);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options && options.length > 0 && selected.length === options.length;

  const [membershipItems, setMembershipItems] = useState([]);
  const [buyValues, setBuyValues] = useState({});

  const apiExpirationDate = associateDistributorObject?.expirationDate;
  const expirationDate = new Date(apiExpirationDate);
  const formattedExpirationDate = moment(expirationDate).format("MM/DD/YYYY");

  const apiStartDate = associateDistributorObject?.startDate;
  const startDate = new Date(apiStartDate);
  const formattedStartDate = moment(startDate).format("MM/DD/YYYY");

  const renewalhistory = (item) => {
    setRenewalhistoryPopupConfirmation(true);
    getRenewalHistory(item);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleChangeDropDown = (event) => {
    formik.setFieldValue("dirty", true);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue(
        "roles",
        selected.length === options.length ? [] : options
      );
      return;
    }
    setSelected(value);
    formik.setFieldValue("roles", value);
  };

  const frequencyOptions = [
    {
      value: 1,
      name: "Monthly",
      months: 1,
    },
    {
      value: 2,
      name: "Quarterly",
      months: 3,
    },
    {
      value: 3,
      name: "Semi-Annual",
      months: 6,
    },
    {
      value: 4,
      name: "Annual",
      months: 12,
    },
  ];

  const handleCancelClick = () => {
    history.push(props.routeData?.listRoute);
  };

  const createrenewRecord = async () => {
    try {
      showLoader(true);
      let payload = {
        status: associateDistributorObject.associatonStatus,
        enterpriseGroupId: associateDistributorObject.enterpriseUserId,
        requestId: associateDistributorObject.requestId,
      };
      const response = await userService.renewUser(payload);
      if (response.responseStatus.isSuccess) {
        page.setDirty(false);
        page.setEditMode(false);
        setIsEditMode(false);
        toast.success(response.responseStatus.description);
        await props.getEditData(formik.values.userId);
        setIsEditMode(false);
      } else {
        toast.error(response.responseStatus.error);
        showLoader(false);
      }
    } catch (event) {
      console.log("error", event);
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const getSubscriptionfees = (frequency, subscription, index) => {
    if (!frequency || !subscription) return 0;
    let arrObj1 = formik.values.associateDistributors[
      index
    ].subscriptionTypes.find((obj) => obj.value === parseInt(subscription));

    let arrObj2 = frequencyOptions.find(
      (obj) => obj.value === parseInt(frequency)
    );
    return arrObj1?.baseValue * arrObj2?.months || 0;
  };

  const setRoles = (rolesData) => {
    if (rolesData) {
      const userData = rolesData.map((item) => item.name);
      setSelected(userData);
      formik.setFieldValue("roles", userData);
    }
  };
  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
  };

  const getdaysDifference = (endDate) => {
    let dateOne = moment(new Date());
    let dateTwo = moment(new Date(endDate));
    let result = dateTwo.diff(dateOne, "days");
    return result;
  };

  useEffect(() => {
    setMasterData();
    getDistributorDropdownData();
    populateDropdownParent();
  }, []);

  useEffect(() => {
    populateStateOptions(formik.values.countryId || 1);
  }, [formik.values.countryId, allStateOptions]);

  useEffect(() => {
    if (formik.values.associateDistributors) {
      setOldAssociatedData(formik.values.associateDistributors);
    }
    if (
      formik.values.distributorType?.filter(
        (e) => e.label === "Agents / Broker"
      ).length === 1
    ) {
      setType("all");
    } else if (
      formik.values?.distributorType?.filter(
        (e) => e.label === "Agents / Broker"
      ).length === 1 &&
      formik.values.distributorType?.length === 1
    ) {
      setType("agent");
    } else setType("other");
  }, [formik.values.distributorType, formik.values.associateDistributors]);

  const setMasterData = async () => {
    try {
      showLoader(true);
      const response = await userService.populateDropdownOptions();
      showLoader(false);
      setAllRoles(filterRoles(props.routeData.userTypeId, response.roles));
      setDistributorTypeOptions(response.userTypeOptions);
      setCountryOptions(response.countries);
      setAllStateOptions(response.states);
      setAnnualRevenues(response.annualRevenues);
      setAreaOptions(response.areas);
      setCareer(response.carrierRepresents);
      setIndustry(response.industryGroups);
      setTechnology(response.technologies);

      if (auth.getRole() === 1 || auth.getRole() === 2) {
        let allEDTypes = await userService.populateEDDropdown();
        setEDTypes(allEDTypes);
      } else if (auth.getRole() === 4) {
        setEDTypes({
          allEDUsers: [
            {
              value: currentUser.userId,
              label: `${currentUser.firstName} ${currentUser.lastName} (${currentUser.systemGeneratedId})`,
            },
          ],
        });
        formik.setFieldValue("parentUserId", edTypes.allEDUsers[0]);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const getDistributorDropdownData = async () => {
    try {
      showLoader(true);
      const response = await userService.getAsscoiateDistributors();
      setDistributorData(response);
    } catch (e) {
      showLoader(false);
    }
  };

  const createDistributor = async (isNext) => {
    try {
      if (!formik.isValid) {
        toast.error("Please enter required values.");
        return;
      }
      showLoader(true);
      let payload = formik.values;
      let roles = allRoles.filter((element) => selected.includes(element.name));
      payload.roles = roles;
      const response = await userService.createUser(payload);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        page.setDirty(false);
        await props.getEditData(response.result.userId);
        page.setEditMode(false);
        setIsEditMode(payload.status > 0 && page.isEditMode);
        props.onNext(isNext, response.result);
        executeScroll();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const updateDistributor = async (isNext) => {
    try {
      showLoader(true);
      page.setDirty(false);
      let payload = formik.values;
      if (!payload.countryId || payload.countryId === "0") {
        payload.countryId = 0;
        payload.stateId = 0;
      }
      let roles = [];
      if (formik.values.userId) {
        roles = props.formData.roles;
      } else {
        roles = allRoles.filter((element) => selected.includes(element.name));
      }
      if (
        formik.values.logoGcpFileUrl &&
        auth.getCurrentUser().userId === formik.values.userId
      ) {
        await fetchImageAsBase64(formik.values.logoGcpFileUrl).then((data) => {
          auth.setBrandImage(data);
        });
      } else {
        auth.setBrandImage(null);
      }
      payload.roles = roles;
      const response = await userService.updateUser(payload);
      showLoader(false);
      if (response?.responseStatus?.code === 200) {
        page.setEditMode(false);
        setIsEditMode(false);
        setEditMode(false);
        if (isNext) {
          props.onNext(isNext, response.result);
        } else {
          props.onNext(false, response.result);
        }
        toast.success(response.responseStatus.description);
        executeScroll();
      } else {
        toast.error(response.responseStatus.description);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const myRef = useRef(null);
  const executeScroll = () =>
    myRef?.current?.firstChild?.scrollIntoView({
      behavior: "smooth",
    });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onChangeDropDown = (newValue, index) => {
    formik.setFieldValue(
      `associateDistributors[${index}].distributorUserId`,
      newValue
    );
    if (newValue) getSubscriptionTypeDataHandler(newValue.value, index);
  };

  const getSubscriptionTypeDataHandler = async (distributorId, index) => {
    try {
      let response = await userService.getSubscriptionType(distributorId);
      formik.setFieldValue(
        `associateDistributors[${index}].subscriptionTypes`,
        response
      );
    } catch (e) {
      //
    }
  };

  const renderRenewalHistoryConfirmationPopup = () => {
    return (
      <Modal
        open={renewalhistoryPopupConfirmation}
        onClose={() => {
          setRenewalhistoryPopupConfirmation(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            onClick={() => {
              setRenewalhistoryPopupConfirmation(false);
            }}
            style={{
              cursor: "pointer",
              justifyContent: "end",
              display: "flex",
              marginTop: "5px",
            }}
          >
            <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{
              mb: 2,
              mt: -2,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Renewal History
          </Typography>

          <div>
            <DataTable
              paginationNotRequired={true}
              checkboxesNotRequired={true}
              serachBarNotRequired={true}
              columns={EGRenewalHistoryColumns}
              adminTable={renewalHistoryList}
            />
          </div>
        </Box>
      </Modal>
    );
  };
  const renderConfirmationPopup = () => {
    return (
      <Modal
        open={true}
        onClose={() => {
          setAssociationPopupConfirmation(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{ mb: 2, mt: 2, fontWeight: 600, textAlign: "center" }}
          >
            Register an existing relationship or Create a new one?
          </Typography>

          <div className="action-btn-modal mt-3">
            <Button
              // disabled={!formik.values.userId}
              className="action-button"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                setAssociationPopupConfirmation(false);
                setCreateNewAssociation(true);
              }}
              data-testid="create-new"
            >
              Create New
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="action-button ml-4"
              onClick={(e) => {
                setAssociationPopupConfirmation(false);
                addAsscoiation();
              }}
              data-testid="add-exisitng"
            >
              Add to existing
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  const renderDeletionPopup = (index) => {
    return (
      <Modal
        open={associationPopupDeletion}
        onClose={() => {
          setAssociationPopupDeletion(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            sx={{ mb: 2, mt: 2, fontWeight: 600, textAlign: "center" }}
          >
            Are you sure you want to delete this association?
          </Typography>

          <div className="action-btn-modal mt-3">
            <Button
              className="action-button mr-4"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                setAssociationPopupDeletion(false);
              }}
              data-testid="delete-no"
            >
              No
            </Button>
            <Button
              className="action-button"
              variant="contained"
              color="primary"
              onClick={(e) => {
                setAssociationPopupDeletion(false);
                deleteAssociatedDist(index);
              }}
              data-testid="delete-yes"
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };
  const renderRenewPopup = (index) => {
    let distributorBrandName = distributorData.find(
      (x) => x.value === associateDistributorObject?.distributorUserId?.value
    )?.brandName;
    return (
      <Modal
        open={true}
        onClose={() => {
          setAssociationPopupRenew(false);
        }}
        BackdropComponent={null}
        className="modal-shadow"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Typography
            id="modal-modal-description"
            sx={{ mb: 2, mt: 2, fontWeight: 600, textAlign: "center" }}
          >
            You are about to renew your subscription for {distributorBrandName}{" "}
            from {formattedStartDate} to {formattedExpirationDate}. Are you sure
            you want to continue?
          </Typography>
          <div className="action-btn-modal mt-3">
            <Button
              className="action-button mr-4"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                setAssociationPopupRenew(false);
              }}
              data-testid="delete-no"
            >
              No
            </Button>
            <Button
              className="action-button"
              variant="contained"
              color="primary"
              onClick={(e) => {
                createrenewRecord();
                setAssociationPopupRenew(false);
              }}
              data-testid="delete-yes"
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  const sendIconSection = (
    isEditMode,
    index,
    item,
    sendAssociatedDistApproval
  ) => {
    return (
      isEditMode && (
        <SendOutlinedIcon
          data-testid={`sendIcon${index}`}
          className={`send-icon-svg ${
            !item.requestId ||
            item.isStopped ||
            item.associatonStatus === AssociationStatusEnum.Approved ||
            item.associatonStatus === AssociationStatusEnum.Pending
              ? "disabledBtnState"
              : ""
          }`}
          onClick={() => {
            if (
              item.requestId &&
              (item.associatonStatus === AssociationStatusEnum.Draft ||
                item.associatonStatus === AssociationStatusEnum.Rejected ||
                item.associatonStatus === AssociationStatusEnum.Delete)
            ) {
              item.associatonStatus = AssociationStatusEnum.Pending;
              sendAssociatedDistApproval(item);
            }
          }}
        />
      )
    );
  };

  const deleteIconSection = (
    isEditMode,
    index,
    item,
    setAssociationPopupDeletion
  ) => {
    return (
      isEditMode && (
        <DeleteOutlineOutlinedIcon
          data-testid={`deleteicon${index}`}
          className={`delete-icon-svg ${
            item.isStopped ||
            item.associatonStatus === AssociationStatusEnum.Pending ||
            item.associatonStatus === AssociationStatusEnum.Approved ||
            item.isSubscriberAssociate
              ? "disabledBtnState"
              : ""
          }`}
          onClick={() => {
            if (
              item.associatonStatus === AssociationStatusEnum.Pending ||
              item.associatonStatus === AssociationStatusEnum.Approved ||
              item.isSubscriberAssociate
            ) {
              setAssociationPopupDeletion(false);
            } else {
              setAssociationPopupDeletion(true);
            }
          }}
        />
      )
    );
  };
  const fillStatus = (item) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const notApporvedActive =
      item.associatonStatus === AssociationStatusEnum.Rejected
        ? "Rejected/Inactive"
        : "Draft";
    const notPending =
      item.associatonStatus === AssociationStatusEnum.Approved
        ? approvedStatusType(item, today)
        : notApporvedActive;
    const notExpired =
      item.associatonStatus === AssociationStatusEnum.Pending
        ? "Pending"
        : notPending;
    return isExpiredStatus(item, today) ? "Expired" : notExpired;
  };

  const addAsscoiation = () => {
    const associationInitialValues = {
      distributorUserId: { value: 0, label: "" },
      associationId: "",
      frequencyType: 0,
      expirationDate: "",
      distributorSubscriptionId: 0,
      associatonStatus: 0,
      requestId: 0,
      subscriptionTypes: [],
      isRecurring: false,
      isNewAssociation: false,
    };
    let newData = [
      ...formik.values.associateDistributors,
      associationInitialValues,
    ];
    formik.setFieldValue("associateDistributors", newData);
  };

  const handleAssociationChanges = async (e, index, field) => {
    if (field === "expirationDate") {
      let d = e?._d ? e._d : "";
      await formik.setFieldValue(
        `associateDistributors[${index}].${field}`,
        moment(d).format("YYYY-MM-DD")
      );
    } else if (field === "isRecurring") {
      await formik.setFieldValue(
        `associateDistributors[${index}].${field}`,
        e.target.checked
      );
    } else if (field === "frequencyType") {
      await formik.setFieldValue(
        `associateDistributors[${index}].${field}`,
        Number(e.target.value)
      );
      let addMonths = FrequencyEnum[Number(e.target.value)].count;
      let today = new Date();
      let expDate = new Date(today.setMonth(today.getMonth() + addMonths));
      expDate.setDate(expDate.getDate() - 1);
      formik.setFieldValue(
        `associateDistributors[${index}].expirationDate`,
        moment(expDate).format("YYYY-MM-DD")
      );
    } else if (field === "distributorSubscriptionId") {
      await formik.setFieldValue(
        `associateDistributors[${index}].${field}`,
        e.target.value
      );
      await formik.setFieldValue(
        `associateDistributors[${index}].frequencyType`,
        0
      );
      await formik.setFieldValue(
        `associateDistributors[${index}].expirationDate`,
        null
      );
    } else {
      await formik.setFieldValue(
        `associateDistributors[${index}].${field}`,
        e.target.value
      );
    }
  };

  const deleteAssociatedDist = (index) => {
    let allValues = formik.values.associateDistributors;
    let newValues = allValues?.filter((el, i) => i !== index);
    formik.setFieldValue("associateDistributors", newValues);
  };

  const populateDropdownParent = async () => {
    try {
      // Populate parent enterprise dropdown options
      const data = await ApiServices.httpGet(`${PARENT_ENTERPRISE}`);
      setParentDropdown(data.result);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const sendAssociatedDistApproval = async (item) => {
    showLoader(true);
    let payload = {
      distributorUserId: item.distributorUserId.value,
      associationId: item.associationId,
      frequencyType: item.frequencyType,
      expirationDate: item.expirationDate,
      distributorSubscriptionId: item.distributorSubscriptionId,
      requestId: item.requestId,
      enterpriseUserId: item.enterpriseUserId,
      associatonStatus: item.associatonStatus,
      isRecurring: item.isRecurring,
      isNewAssociation: item.isNewAssociation,
    };
    let response = await userService.sendAssociatedDistApproval(payload);
    showLoader(false);
    if (response?.responseStatus?.isSuccess) {
      toast.success("Request Sent!");
    } else {
      toast.error(response.responseStatus.error);
    }
  };

  const changeSetBuyValues = (value, item, fieldName) => {
    let newValues = {};
    if (fieldName === "frequency") {
      newValues = {
        ...buyValues,
        [item.distributorSubscriptionId]: {
          ...buyValues[item.distributorSubscriptionId],
          frequency: value,
          // isRecurring: value,
          baseValue: item.baseValue * FrequencyEnum[value].count,
        },
      };
    } else if (fieldName === "isRecurring") {
      newValues = {
        ...buyValues,
        [item.distributorSubscriptionId]: {
          ...buyValues[item.distributorSubscriptionId],
          isRecurring: value,
        },
      };
    }
    setBuyValues(newValues);
  };

  const renderSubscriptionBuyItems = (item, i) => {
    return (
      <Grid item xs={4} p={1}>
        <Card variant="outlined" className="membership-card">
          <CardContent>
            <div className="items">
              <Typography
                sx={{ fontSize: 18 }}
                color="text.primary"
                gutterBottom
              >
                Subscription Type : <strong>{item.name}</strong>
              </Typography>
            </div>
            <div className="items">
              <label htmlFor="frequencyType">Frequency</label>
              <select
                data-testid={`membership${i}`}
                id="frequencyType"
                name="frequencyType"
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  changeSetBuyValues(e.target.value, item, "frequency");
                }}
                value={buyValues?.[item.distributorSubscriptionId]?.frequency}
                className="form-control form-select"
              >
                {item.frequency.map((el) => {
                  return (
                    <option key={FrequencyEnum[el].name} value={el}>
                      {FrequencyEnum[el].name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="items">
              <Typography
                sx={{ fontSize: 18 }}
                color="text.primary"
                gutterBottom
              >
                Total Value :
                <strong>
                  {buyValues?.[item.distributorSubscriptionId]?.baseValue ||
                    item.baseValue *
                      (FrequencyEnum?.[item.frequency[0]]?.count ?? 1)}
                </strong>
              </Typography>
            </div>
            <div className="items">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      data-testid={`recurring${i}`}
                      value={
                        buyValues?.[item.distributorSubscriptionId]?.isRecurring
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        changeSetBuyValues(
                          e.target.checked,
                          item,
                          "isRecurring"
                        );
                      }}
                    />
                  }
                  label="Recurring"
                />
              </FormGroup>
            </div>
          </CardContent>
          <CardActions>
            <Button
              disabled={!formik.isValid}
              data-testid={`buyNow${i}`}
              className="buy-btn"
              variant="contained"
              size="large"
              onClick={() => {
                buyMembership(item, buyValues[item.distributorSubscriptionId]);
              }}
            >
              Buy Now
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const renderCreateNewAssociation = () => {
    return (
      <>
        <Grid container className="blue-field-section">
          <Grid
            item
            xs={4}
            p={1}
            className="serachable-dropdown my-serachable-dropdown"
          >
            <label htmlFor="distributorGroupDropDown">Distributor Name</label>
            <SearchableDropDown
              options={distributorData || []}
              name="distributorGroupDropDown"
              id="distributorGroupDropDown"
              setData={onChangeCreateNew}
              value={true}
              placeholder="Search and Select Distributor"
              disabled={!isEditMode}
            />
          </Grid>
        </Grid>
        <Grid container>
          {membershipItems?.map((item, i) => {
            return renderSubscriptionBuyItems(item, i);
          })}
        </Grid>
      </>
    );
  };

  const onChangeCreateNew = async (selectedDistributor, i) => {
    if (!selectedDistributor) {
      setMembershipItems([]);
      return;
    }

    let response = await userService.getSubscriptionTypeMembership(
      selectedDistributor.value
    );
    let resp = response && response.result ? response.result : [];
    setMembershipItems(resp);
  };

  const buyMembership = async (item, updatedValues) => {
    let userId = 0;
    if (!formik.values.userId) {
      userId = await createDistributor();
    }
    showLoader(true);
    let payload = {
      enterpriseUserId: formik.values.userId || userId,
      distributorUserId: item.userId,
      distributorSubscriptionId: item.distributorSubscriptionId,
      isRecurring: updatedValues?.isRecurring || true,
      associationId: `PYMNT${item.userId}`,
      frequencyType: parseInt(updatedValues?.frequency || item.frequency[0]),
      isNewAssociation: true,
      expirationDate: moment().add(
        FrequencyEnum[updatedValues?.frequency || item.frequency[0]].count,
        "M"
      ),
    };
    let response = await userService.buyMembership(payload);

    if (response && response.responseStatus.isSuccess && userId) {
      toast.success("Payment Successful");
      setTimeout(() => {
        showLoader(false);
        window.location.href = `${props.routeData.addRoute}/${userId}`;
      }, 1000);
    } else if (response && response.responseStatus.isSuccess && !userId) {
      toast.success("Payment Successful");
      showLoader(false);
      window.location.reload();
    } else {
      toast.error(response.responseStatus.error);
    }
  };

  const getRenewalHistory = async (item) => {
    try {
      showLoader(true);
      let payload = {
        requestId: item.requestId,
        enterpriseUserId: item.enterpriseUserId,
      };
      let response = await ApiServices.httpPost(RENEWAL_HISTORY, payload);
      if (response.responseStatus.isSuccess) {
        let renewalList = response.result.map((x) => {
          return {
            // "requestId": x.requestId,
            // "enterpriseUserId": x.requestId,
            // "distributorUserId": x.requestId,
            distributorName: x.distributorName,
            associationId: x.associationId,
            // "frequencyType": x.requestId,
            expirationDate: x.expirationDate,
            startDate:
              x.associatonStatus === AssociationStatusEnum.Approved
                ? x.startDate
                : null,
            // "stoppedDate": x.requestId,
            // "associationStartDate": x.requestId,
            // "distributorSubscriptionId": x.requestId,
            // "rejectReason": x.requestId,
            isRecurring: x.isRecurring,
            // "isStopped": x.requestId,
            // "isCreate": x.requestId,
            // "associatonStatus": x.requestId,
            // "isNewAssociation": x.requestId,
            // "transaction": x.requestId,
            // "approvalId": x.requestId,
            // "subscriptionStatus": x.requestId,
            subscriptionName: x.subscriptionName,
            frequencyText: x.frequencyText,
            subscriptionFees: x.subscriptionFees,
            // "associatonStatusText": x.requestId,
            // "isSubscriberAssociate": x.requestId,
            // "createdOn": x.requestId,
            // "updatedOn": x.requestId,
            statusText: fillStatus(x),
          };
        });

        setRenewalHistoryList(renewalList);
      } else {
        toast.success(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };
  const stopSubscription = async (item, index) => {
    showLoader(true);
    try {
      let payload = {
        status: item.associatonStatus,
        requestId: item.requestId,
        enterpriseUserId: item.enterpriseUserId,
      };
      let response = await ApiServices.httpPost(STOP_SUBSCRIPTION, payload);
      if (response.responseStatus.isSuccess) {
        formik.setFieldValue(`associateDistributors[${index}].isStopped`, true);
        toast.success(response.responseStatus.description);
      } else {
        toast.success(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  const renderDistributorItem = (item, index) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      <div className={`association${index}`}>
        <Grid container className="blue-field-section">
          <Grid item xs={10} p={1} className="inner-container">
            <Grid container>
              <Grid
                item
                xs={6}
                p={1}
                mt={0}
                className="serachable-dropdown search-dropdown-existing"
              >
                <label htmlFor="distributorDropDown">Distributor Name</label>
                <span style={{ color: "red", marginLeft: "0px" }}>*</span>
                <SearchableDropDown
                  options={distributorData || []}
                  name="enterpriseGroupIdDropdown"
                  id="enterpriseGroupIdDropdown"
                  setData={onChangeDropDown}
                  value={
                    formik.values.associateDistributors && [
                      formik.values.associateDistributors[index]
                        .distributorUserId,
                    ]
                  }
                  index={index}
                  placeholder="Search and Select Distributor"
                  disabled={
                    !isEditMode ||
                    item.isStopped ||
                    item.associatonStatus === AssociationStatusEnum.Pending ||
                    item.associatonStatus === AssociationStatusEnum.Approved
                  }
                />
              </Grid>
              <Grid item xs={3} p={1} className="inner-container">
                <label htmlFor="associationId">
                  Unique Identification Number
                </label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <input
                  id="associationId"
                  name="associationId"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      inputValue.match(REGULAR_EXPRESSION.ALPHANUMERICREGEX) ||
                      inputValue === ""
                    ) {
                      formik.setFieldValue("dirty", true);
                      handleAssociationChanges(e, index, "associationId");
                    }
                  }}
                  className="form-label form-control"
                  type="text"
                  value={
                    formik.values.associateDistributors[index].associationId
                  }
                  placeholder="Enter UIN"
                  disabled={
                    formik.values.associateDistributors[index]
                      ?.isNewAssociation ||
                    !isEditMode ||
                    item.isStopped ||
                    item.associatonStatus === AssociationStatusEnum.Pending ||
                    item.associatonStatus === AssociationStatusEnum.Approved
                  }
                  maxLength={20}
                />
              </Grid>
              <Grid item xs={3} p={1} className="inner-container">
                <label htmlFor="distributorSubscriptionId">
                  Subscription Type
                </label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <select
                  id="distributorSubscriptionId"
                  name="distributorSubscriptionId"
                  onChange={(e) => {
                    formik.setFieldValue("dirty", true);
                    handleAssociationChanges(
                      e,
                      index,
                      "distributorSubscriptionId"
                    );
                  }}
                  value={
                    formik.values.associateDistributors[index]
                      .distributorSubscriptionId
                  }
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    item.isStopped ||
                    item.associatonStatus === AssociationStatusEnum.Pending ||
                    item.associatonStatus === AssociationStatusEnum.Approved
                  }
                >
                  <option selected="true" disabled="true" value="0">
                    Select Membership
                  </option>
                  {item.subscriptionTypes &&
                    item.subscriptionTypes.length > 0 &&
                    item.subscriptionTypes.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                </select>
              </Grid>
              <Grid item xs={3} p={1} className="inner-container">
                <label htmlFor="frequencyType">Frequency</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <select
                  id="frequencyType"
                  name="frequencyType"
                  onChange={(e) => {
                    formik.setFieldValue("dirty", true);
                    handleAssociationChanges(e, index, "frequencyType");
                  }}
                  value={
                    formik.values.associateDistributors[index]?.frequencyType
                  }
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    item.isStopped ||
                    item.associatonStatus === AssociationStatusEnum.Pending ||
                    item.associatonStatus === AssociationStatusEnum.Approved
                  }
                >
                  <option selected="true" disabled="true" value="0">
                    Select Frequency
                  </option>
                  {frequencyOptions
                    .filter((option) =>
                      item.subscriptionTypes
                        ?.find(
                          (st) =>
                            Number(st.value) ===
                            Number(item.distributorSubscriptionId)
                        )
                        ?.frequency.includes(Number(option.value))
                    )
                    .map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      );
                    })}
                </select>
              </Grid>
              {item.associatonStatus === AssociationStatusEnum.Approved && (
                <Grid item xs={3} p={2} className="inner-container">
                  <label htmlFor="membershipStartDate">Start Date</label>
                  <input
                    className="form-label form-control"
                    type="text"
                    value={formatDate(
                      formik.values.associateDistributors[index].startDate
                    )}
                    disabled
                  />
                </Grid>
              )}
              <Grid
                item
                xs={3}
                p={1}
                className="inner-container desktop-date-picker"
              >
                <label htmlFor="membershipExpirationDate">
                  Expiration Date
                </label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disablePast
                    className="datepicker-assoc form-label form-control date-field"
                    inputFormat="MM/DD/YYYY"
                    value={
                      formik.values.associateDistributors[index].expirationDate
                    }
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      handleAssociationChanges(e, index, "expirationDate");
                    }}
                    renderInput={(params) => (
                      <TextField id="startDate" {...params} />
                    )}
                    disabled
                    placeholder="Enter Membership Expiration Date"
                  />
                </LocalizationProvider>
                <InlineMessage
                  error={
                    isEditMode &&
                    formik.errors &&
                    formik.errors.associateDistributors &&
                    formik.errors.associateDistributors[index]
                      ? formik.errors.associateDistributors[index]
                          .expirationDate
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={3} p={1} className="inner-containers">
                <label htmlFor="associationId">Subscription Fees</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <CurrencyTextbox
                  id="associationId"
                  name="associationId"
                  value={getSubscriptionfees(
                    formik.values?.associateDistributors[index]?.frequencyType,
                    formik.values?.associateDistributors[index]
                      ?.distributorSubscriptionId,
                    index
                  )}
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2} className="inner-containerss">
                <label htmlFor="associationId">Recurring</label>
                <FormControlLabel
                  className={
                    !isEditMode
                      ? "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm border-cus-cus-disabled"
                      : "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm"
                  }
                  label={<Typography>Is Recurring</Typography>}
                  checked={
                    formik.values.associateDistributors[index].isRecurring
                  }
                  name="Available Public"
                  data-testid="preview-available-checkbox"
                  onChange={(e) => {
                    handleAssociationChanges(e, index, "isRecurring");
                    page.setDirty(true);
                  }}
                  control={
                    <Checkbox
                      disabled={
                        !isEditMode ||
                        item.isStopped ||
                        item.associatonStatus ===
                          AssociationStatusEnum.Pending ||
                        item.associatonStatus === AssociationStatusEnum.Approved
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={3} p={2} className="inner-container">
                <label htmlFor="membershipStatus">Status</label>
                <input
                  className="form-label form-control"
                  type="text"
                  value={fillStatus(item)}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            p={1}
            className="inner-container container-inner"
          >
            <Grid item xs={0} p={1} className=" blue-field-section">
              {getdaysDifference(item.expirationDate) <=
                Number(
                  masterDataContext.masterDataValue.result.settings.find(
                    (x) => x.key === "Enterprise_Association_RenewButton_Days"
                  ).value
                ) &&
                isEditMode &&
                !item.isStopped &&
                item.associatonStatus === AssociationStatusEnum.Approved &&
                item.isFutureSubscription === false &&
                item.requestId > 0 && (
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setAssociationPopupRenew(true);
                      setAssociateDistributorObject(item);
                    }}
                  >
                    Renew Now
                  </Button>
                )}

              {isEditMode &&
                item.requestId > 0 &&
                (item.associatonStatus === AssociationStatusEnum.Approved ||
                  item.associatonStatus === AssociationStatusEnum.Pending) && (
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      stopSubscription(item, index);
                    }}
                    disabled={!isEditMode || item.isStopped}
                  >
                    Stop
                  </Button>
                )}
            </Grid>

            {item.requestId > 0 &&
              (item.associatonStatus === AssociationStatusEnum.Approved ||
                item.associatonStatus === AssociationStatusEnum.Pending) && (
                <Grid item xs={0} p={1} className=" blue-field-section">
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      renewalhistory(item);
                    }}
                  >
                    Renewal History
                  </Button>
                </Grid>
              )}
            <Grid
              item
              xs={0}
              sx={{ p: 1, m: 1 }}
              className="association-action-btns"
            >
              {deleteIconSection(
                isEditMode,
                index,
                item,
                setAssociationPopupDeletion
              )}
              {associationPopupRenew && renderRenewPopup(index)}
              {associationPopupDeletion && renderDeletionPopup(index)}
              {sendIconSection(
                isEditMode,
                index,
                item,
                sendAssociatedDistApproval
              )}
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid className="custom-grid-spacing"></Grid>
      </div>
    );
  };

  const renderMultiSelectRolesDropdown = () => {
    return (
      <div sx={{ width: "100%" }}>
        <label htmlFor="rolesDropdown">Roles</label>
        <MandatoryField />
        <Select
          displayEmpty
          disabled={formik.values.userId || !isEditMode}
          data-testid="rolesDropdown"
          labelId="mutiple-select-label"
          className={
            formik.values?.systemGeneratedId
              ? "form-select form-control select-user roles-dropdown-disabled"
              : "form-select form-control select-user roles-dropdown"
          }
          multiple
          value={selected}
          title={selected}
          onChange={handleChangeDropDown}
          renderValue={(selectedItem) => {
            if (selected.length === 0) {
              return "Select Roles";
            }
            return selectedItem.join(", ");
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  const onCancelHandler = () => {
    if (
      auth.getRole() === SystemUsersEnum.ENTERPRISE ||
      auth.getRole() === SystemUsersEnum.ENTERPRISE_INTERNAL
    ) {
      props.getEditData(formik.values.userId);
      setIsEditMode(false);
    } else if (
      props?.routeData?.userTypeId === 1 ||
      props?.routeData?.userTypeId === 2 ||
      props?.routeData?.userTypeId === 4 ||
      props?.routeData?.userTypeId === 7
    ) {
      handleCancelClick();
    } else {
      formik.setFieldValue("associateDistributors", oldAssociatedData);
      setIsEditMode(false);
    }
  };

  const onEditHandler = () => {
    setIsEditMode(true);
    setShowAnotherNumber(true);
    setShowAnotherFax(true);
    setShowAnotherMobile(true);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size; // Get the file size in bytes
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (fileSize > maxSizeInBytes) {
      // File size exceeds the maximum allowed size
      toast.error(`File size > 2 MB. Please upload a file less than 2MB`);
      e.target.value = null; // Reset the file input field
      setLogo("");
      return;
    }
    let ext = getFileExtension(selectedFile.name);
    ext = `image/${ext}`;
    if (!brandLogoAcceptedFormats.includes(ext.toLowerCase())) {
      e.target.value = null;
      toast.error(`Please select a valid file`);
      setLogo("");
      return;
    }
    setLogo(e.target.files[0]);
    const formData = new FormData();
    formData.append("File", selectedFile);
    showLoader(true);
    try {
      const response = await ApiServices.httpPostFile(UPLOAD_LOGO, formData);
      showLoader(false);
      if (response.responseStatus?.isSuccess) {
        formik.setFieldValue("logoGcpPath", response.result.gcpFilePath);
        formik.setFieldValue("logoGcpFileUrl", response.result.gcpFileUrl);
      } else {
        toast.error(response.responseStatus?.error);
      }
    } catch (err) {
      showLoader(false);
    }
  };
  const onDeleteFileHandler = () => {
    formik.setFieldValue("logoGcpPath", "");
    formik.setFieldValue("logoGcpFileUrl", "");
    setLogo("");
  };

  return (
    <>
      {/* Basic Details Section starts here */}
      {basicDetailPermission.includes(PermissionEnum.LIST) && (
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>Basic Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="modal-body" ref={myRef}>
                <Grid container spacing={2} className="blue-field-section">
                  {formik.values.id && (
                    <>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="systemGeneratedId">
                          System Generated Id
                        </label>
                        <input
                          id="systemGeneratedId"
                          name="systemGeneratedId"
                          onChange={formik.handleChange}
                          className="form-label form-control"
                          type="text"
                          placeholder="System Generated Id"
                          value={formik.values?.systemGeneratedId}
                          disabled
                          maxLength={25}
                        />
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="createdOn">Registration Date</label>
                        <input
                          id="createdOn"
                          name="createdOn"
                          onChange={formik.handleChange}
                          value={formatDate(formik.values.createdOn)}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Registration Date"
                          disabled
                          maxLength={25}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4} p={2}>
                    {renderMultiSelectRolesDropdown()}
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="brandName">Brand Name</label>
                    <MandatoryField />
                    <input
                      id="brandName"
                      name="brandName"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                        formik.setFieldValue("businessName", e.target.value);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Brand Name"
                      value={formik.values.brandName}
                      disabled={!isEditMode}
                    />
                    <InlineMessage error={formik.errors.brandName} />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="firstName">First Name</label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <input
                      id="firstName"
                      name="firstName"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                        formik.setFieldValue("billingContact", e.target.value);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter First Name"
                      value={formik.values.firstName}
                      disabled={!isEditMode}
                      maxLength={25}
                    />
                    <InlineMessage error={formik.errors.firstName} />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="lastName">Last Name</label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <input
                      id="lastName"
                      name="lastName"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                        formik.setFieldValue(
                          "billingContactLastName",
                          e.target.value
                        );
                      }}
                      value={formik.values.lastName}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      disabled={!isEditMode}
                      maxLength={25}
                    />
                    <InlineMessage error={formik.errors.lastName} />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="distributorType">
                      {props.routeData?.userTypeLabel}
                    </label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <MultiSelectDropDown
                      name="distributorType"
                      id="distributorType"
                      value={formik.values.distributorType}
                      onChange={(value) => {
                        formik.setValues({
                          ...formik.values,
                          distributorType: value,
                        });
                      }}
                      options={distributorTypeOptions}
                      disabled={!isEditMode}
                    />
                    <InlineMessage
                      error={
                        formik.errors.distributorType &&
                        props?.routeData?.distTypeError
                      }
                    />
                  </Grid>

                  {primaryEmailAdmin(
                    auth,
                    isEditMode,
                    editMode,
                    formik,
                    handleEditClick
                  )}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="application" className="file-upload-label">
                      <span>Logo </span>
                      {logoText(logo, formik)}
                    </label>
                    <FileUploader
                      accept={brandLogoAcceptedFormats}
                      onSelectFile={handleFileChange}
                      onDeleteFile={onDeleteFileHandler}
                      disabled={!isEditMode}
                    />
                    {formik.values.logoGcpPath !== "" && (
                      <div>{formik.values.logoGcpPath}</div>
                    )}
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="secondaryEmail">
                      Secondary Email Address
                    </label>
                    <input
                      id="secondaryEmail"
                      name="secondaryEmail"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.secondaryEmail}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Secondary Email Address"
                      disabled={!isEditMode}
                    />
                    <InlineMessage error={formik.errors.secondaryEmail} />
                  </Grid>

                  {["agent", "all"].includes(type) && (
                    <>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="areas">Major Areas</label>

                        <MultiSelectDropDown
                          id="areas"
                          name="areas"
                          value={formik.values.areas}
                          isMulti
                          options={areaOptions}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              areas: value,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="producers">Number of producers</label>
                        <input
                          id="producers"
                          name="producers"
                          onChange={formik.handleChange}
                          value={formik.values.producers}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of producers"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.producers} />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="carrierRepresents">
                          Carriers represented
                        </label>

                        <MultiSelectDropDown
                          id="carrierRepresents"
                          name="carrierRepresents"
                          value={formik.values.carrierRepresents}
                          isClearable={() => setViewForm(!viewForm)}
                          menuShouldBlockScroll
                          isMulti
                          options={career}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              carrierRepresents: value,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="industryGroups">
                          Industry Groups/ Membership
                        </label>
                        <MultiSelectDropDown
                          id="industryGroups"
                          name="industryGroups"
                          value={formik.values.industryGroups}
                          isMulti
                          options={industry}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              industryGroups: value,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="technologies">
                          Technology being used now
                        </label>

                        <MultiSelectDropDown
                          id="technologies"
                          name="technologies"
                          value={formik.values.technologies}
                          isMulti
                          options={technology}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              technologies: value,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="businessYear">Years in Business</label>
                        <input
                          id="businessYear"
                          name="businessYear"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.businessYear}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Years in Business"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.businessYear} />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="websiteUrl">Website URL</label>
                        <input
                          id="websiteUrl"
                          name="websiteUrl"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.websiteUrl}
                          className="form-label form-control"
                          type="url"
                          placeholder="Enter Website URL"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.websiteUrl} />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="annualRevenue">Annual revenue</label>

                        <select
                          id="annualRevenue"
                          name="annualRevenue"
                          value={formik.values.annualRevenue}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-control form-select"
                          disabled={!isEditMode}
                        >
                          <option value="0">Select Revenue</option>
                          {annualRevenues?.map((res) => {
                            return (
                              <option
                                key={res.name}
                                value={res.annualRevenueId}
                              >
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="numberOfEmployees">
                          Number of employees
                        </label>
                        <input
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfEmployees}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of employees"
                          disabled={!isEditMode}
                        />
                        <InlineMessage
                          error={formik.errors.numberOfEmployees}
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="numberOfOwners">
                          Number of principals / owners
                        </label>
                        <input
                          id="numberOfOwners"
                          name="numberOfOwners"
                          value={formik.values.numberOfOwners}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of principals / owners"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.numberOfOwners} />
                      </Grid>
                    </>
                  )}

                  {type === "other" && (
                    <>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="busineesYear">Years in Business </label>
                        <input
                          id="businessYear"
                          name="businessYear"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.businessYear}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Years in Business"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.businessYear} />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="websiteUrl">Website URL</label>
                        <input
                          id="websiteUrl"
                          value={formik.values.websiteUrl}
                          name="websiteUrl"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Website URL"
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="annualRevenue">Annual revenue</label>
                        <select
                          id="annualRevenue"
                          name="annualRevenue"
                          value={formik.values.annualRevenue}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-control form-select"
                          disabled={!isEditMode}
                        >
                          <option value="0">Select Revenue</option>
                          {annualRevenues?.map((res) => {
                            return (
                              <option
                                key={res.name}
                                value={res.annualRevenueId}
                              >
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="numberOfEmployees">
                          Number of employees
                        </label>
                        <input
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfEmployees}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of employees"
                          disabled={!isEditMode}
                        />
                        <InlineMessage
                          error={formik.errors.numberOfEmployees}
                        />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="numberOfOwners">
                          Number of principals / owners
                        </label>
                        <input
                          id="numberOfOwners"
                          name="numberOfOwners"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfOwners}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of principals / owners"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.numberOfOwners} />
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="industryGroups">
                          Industry Groups/ Membership
                        </label>
                        <MultiSelectDropDown
                          id="industryGroups"
                          name="industryGroups"
                          value={formik.values.industryGroups}
                          isMulti
                          options={industry}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              industryGroups: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4} p={2}>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <BusinessPhoneNumberTextbox
                      id="phoneNumber"
                      className="form-label form-control"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.setFieldValue("phoneNumber", e);
                      }}
                      value={formik.values.phoneNumber}
                      disabled={!isEditMode}
                      phoneCode={countryOptions
                        ?.filter(
                          (el) =>
                            el.countryId === Number(formik.values.countryId)
                        )
                        .map((item) => item.phoneCode)}
                    />
                    <InlineMessage error={formik.errors.phoneNumber} />
                  </Grid>

                  {addSecPhoneNumSection()}

                  {secPhoneNumSection()}

                  {props.routeData.addRoute === "/subscriber" ? null : (
                    <>
                      <Grid item xs={4} p={2}>
                        <label htmlFor="businessName">Business Name</label>
                        <input
                          id="businessName"
                          name="businessName"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik?.values?.businessName}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Business Name"
                          disabled={!isEditMode}
                        />
                        {/* <InlineMessage error={formik.errors.producers} /> */}
                      </Grid>

                      <Grid item xs={4} p={2}>
                        <label htmlFor="taxID">Tax Id</label>
                        <input
                          id="taxID"
                          name="taxID"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik?.values?.taxID}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Tax ID"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.taxID} />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4} p={2}>
                    <label htmlFor="mobile">Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile"
                      className="form-label form-control"
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.mobile}
                      disabled={!isEditMode}
                      phoneCode={countryOptions
                        ?.filter(
                          (el) =>
                            el.countryId === Number(formik.values.countryId)
                        )
                        .map((item) => item.phoneCode)}
                    />
                    <InlineMessage error={formik.errors.mobile} />
                  </Grid>
                  {addAltMobileSection()}

                  {altMobileSection()}

                  <Grid item xs={4} p={2}>
                    <label htmlFor="fax">Fax</label>
                    <PhoneNumberTextbox
                      id="fax"
                      className="form-label form-control"
                      name="fax"
                      placeholder="Enter Fax"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.fax}
                      disabled={!isEditMode}
                      phoneCode={countryOptions
                        ?.filter(
                          (el) =>
                            el.countryId === Number(formik.values.countryId)
                        )
                        .map((item) => item.phoneCode)}
                    />
                    <InlineMessage error={formik.errors.fax} />
                  </Grid>

                  {addAltFaxSection()}

                  {altFaxSection()}

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContact">Billing First Name </label>
                    <input
                      id="billingContact"
                      name="billingContact"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.billingContact}
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing First Name"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContactLastName">
                      Billing Last Name{" "}
                    </label>
                    <input
                      id="billingContactLastName"
                      name="billingContactLastName"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.billingContactLastName}
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing Last Name"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingPhoneNumber">
                      Billing Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="billingPhoneNumber"
                      className="form-label form-control"
                      name="billingPhoneNumber"
                      placeholder="Enter Billing Phone Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.billingPhoneNumber}
                      disabled={!isEditMode}
                      phoneCode={countryOptions
                        ?.filter(
                          (el) =>
                            el.countryId === Number(formik.values.countryId)
                        )
                        .map((item) => item.phoneCode)}
                    />
                    <InlineMessage error={formik.errors.billingPhoneNumber} />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine1">
                      Billing Address Line 1
                    </label>
                    <input
                      id="billingAddressLine1"
                      name="billingAddressLine1"
                      value={formik.values.billingAddressLine1}
                      className="form-label form-control"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      type="text"
                      placeholder="Enter Billing Address Line 1 "
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine2">
                      Billing Address Line 2
                    </label>
                    <input
                      id="billingAddressLine2"
                      name="billingAddressLine2"
                      value={formik.values.billingAddressLine2}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 2"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="zip">Zip Code</label>
                    <input
                      id="zip"
                      name="zip"
                      className="form-label form-control"
                      type="text"
                      maxLength={5}
                      placeholder="Enter Zip Code"
                      value={formik.values.zip}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      disabled={!isEditMode}
                    />
                    <InlineMessage error={formik.errors.zip} />
                  </Grid>
                  {/* <Grid item xs={4}></Grid> */}
                  <Grid item xs={4} p={2}>
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      id="city"
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter City"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="countryId">Country</label>
                    <select
                      id="countryId"
                      name="countryId"
                      value={formik.values?.countryId}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-control form-select"
                      disabled={!isEditMode}
                    >
                      {countryOptions?.map((res) => {
                        return (
                          <option key={res.countryId} value={res.countryId}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="stateId">State/Province</label>
                    <select
                      id="stateId"
                      name="stateId"
                      value={formik.values.stateId}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter State"
                      disabled={!isEditMode}
                    >
                      <option>Select State</option>
                      {stateOptions?.map((res) => {
                        return (
                          <option key={res.name} value={res.stateId}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>

                  {/* <Grid container spacing={2} mt={2}> */}
                  <Grid
                    item
                    xs={4}
                    p={2}
                    className="serachable-dropdown search-dropdown"
                  >
                    <label htmlFor="enterpriseGroupIdDropdown">
                      Parent Enterprise Group (if any)
                    </label>
                    <SearchableDropDown
                      options={parentDropdown
                        .filter((item) => item.userId !== formik.values.userId)
                        .map((res) => {
                          return {
                            label: res.name,
                            id: res.userId,
                          };
                        })}
                      name="enterpriseGroupIdDropdown"
                      id="enterpriseGroupIdDropdown"
                      setData={(value) => {
                        setMembership(value.id ? `INCHANTED_${value.id}` : "");
                        setSelectedParentValue([value]);
                        formik.setValues({
                          ...formik.values,
                          parentUser: {
                            ...formik.values.parentUser,
                            parentUserId: value.id,
                          },
                        });
                      }}
                      value={selectedParentValue}
                      placeholder="Select Parent Enterprise Group"
                      disabled={
                        !isEditMode ||
                        auth.getRole() === SystemUsersEnum.ENTERPRISE
                      }
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="enterpriseGroupIdDropdown">
                      Unique Identification Number
                    </label>
                    <input
                      disabled
                      id="membershipId"
                      name="membershipId"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Unique Identification Number"
                      value={memberShip}
                    />
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              </div>
            </div>
            <FooterButtons
              isEditMode={isEditMode}
              permissions={permissions}
              permissionId={permissionId}
              editPermission={
                formik.values.status > 0 &&
                basicDetailPermission.includes(PermissionEnum.UPDATE)
              }
              showUpdateNext={
                formik.values.userId > 0 && props?.routeData?.userTypeId !== 7
              }
              showSaveNext={
                !formik.values.userId && props?.routeData?.userTypeId !== 7
              }
              disableUpdateNext={
                !isEditMode || Object.keys(formik.errors).length > 0
              }
              disableSaveNext={!isEditMode || !formik.isValid}
              showUpdateDetail={formik.values.userId > 0}
              disableUpdateDetail={
                !isEditMode || Object.keys(formik.errors).length > 0
              }
              disableSaveDetail={!isEditMode || !formik.isValid}
              onCancel={onCancelHandler}
              onEdit={onEditHandler}
              onUpdateNext={() => updateDistributor(true)}
              onSaveNext={() => {
                createDistributor(true);
              }}
              onUpdate={() => updateDistributor(false)}
              onSave={() => {
                createDistributor(false);
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Association Details/Distributor and Publisher Management tab Section starts here */}
      {associationDetailsPermission.includes(PermissionEnum.LIST) && (
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>
              {/* Association Details */}
              Distributor and Publisher Management
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isEditMode && (
              <Grid container spacing={2} className="blue-field-section">
                <Grid item sx={12} p={2}>
                  <Typography className="add-association">
                    Want to add a Distributor?
                    <Tooltip placement="bottom" title="Add">
                      <AddOutlinedIcon
                        name="add-icon"
                        title="add-icon"
                        onClick={() => {
                          setAssociationPopupConfirmation(true);
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {createNewAssociation &&
              distributorData.length > 0 &&
              renderCreateNewAssociation()}
            {formik.values?.associateDistributors?.map((item, index) => {
              return renderDistributorItem(item, index);
            })}
            {renewalhistoryPopupConfirmation &&
              renderRenewalHistoryConfirmationPopup()}
            <FooterButtons
              isEditMode={isEditMode}
              permissions={permissions}
              permissionId={permissionId}
              editPermission={
                formik.values.status > 0 &&
                associationDetailsPermission.includes(PermissionEnum.UPDATE)
              }
              showUpdateNext={
                formik.values.userId > 0 && props?.routeData?.userTypeId !== 7
              }
              showSaveNext={
                !formik.values.userId && props?.routeData?.userTypeId !== 7
              }
              disableUpdateNext={
                !isEditMode || Object.keys(formik.errors).length > 0
              }
              disableSaveNext={!isEditMode || !formik.isValid}
              showUpdateDetail={formik.values.userId > 0}
              disableUpdateDetail={
                !isEditMode || Object.keys(formik.errors).length > 0
              }
              disableSaveDetail={!isEditMode || !formik.isValid}
              onCancel={onCancelHandler}
              onEdit={onEditHandler}
              onUpdateNext={() => updateDistributor(true)}
              onSaveNext={() => {
                createDistributor(true);
              }}
              onUpdate={() => updateDistributor(false)}
              onSave={() => {
                createDistributor(false);
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {associationPopupConfirmation && renderConfirmationPopup()}
    </>
  );

  function altFaxSection() {
    return showAnotherFax || props?.formData?.fax1 ? (
      <Grid item xs={4} p={2}>
        <label htmlFor="mobile1">Alternate Fax</label>
        <PhoneNumberTextbox
          id="fax1"
          className="form-label form-control"
          name="fax1"
          placeholder="Enter Alternate Fax"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.fax1}
          disabled={!isEditMode}
          phoneCode={countryOptions
            ?.filter((el) => el.countryId === formik.values.countryId)
            .map((item) => item.phoneCode)}
        />
        <InlineMessage error={formik.errors.fax1} />
      </Grid>
    ) : null;
  }

  function addAltFaxSection() {
    return (!props?.formData?.userId && !props?.formData?.fax1) ||
      (isEditMode && !props?.formData?.fax1) ? (
      <Grid item xs={4} p={2}>
        <div
          role="button"
          name="AddAnotherFax"
          className="add-item"
          data-testid="AddAnotherFax"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                fax1: "",
              });
            }
            setShowAnotherFax((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherFax ? "- Remove Another Fax" : "+ Add Another Fax"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function altMobileSection() {
    return showAnotherMobile || props?.formData?.mobile1 ? (
      <Grid item xs={4} p={2}>
        <label htmlFor="mobile1">Alternate Mobile</label>
        <PhoneNumberTextbox
          id="mobile1"
          className="form-label form-control"
          name="mobile1"
          placeholder="Enter Alternate Mobile Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.mobile1}
          disabled={!isEditMode}
          phoneCode={countryOptions
            ?.filter((el) => el.countryId === formik.values.countryId)
            .map((item) => item.phoneCode)}
        />
        <InlineMessage error={formik.errors.mobile1} />
      </Grid>
    ) : null;
  }

  function addAltMobileSection() {
    return (!props?.formData?.userId && !props?.formData?.mobile1) ||
      (isEditMode && !props?.formData?.mobile1) ? (
      <Grid item xs={4} p={2}>
        <div
          role="button"
          name="AddAnotherMobile"
          className="add-item"
          data-testid="AddAnotherMobile"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                mobile1: "",
              });
            }
            setShowAnotherMobile((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherMobile
                ? "- Remove Another Mobile"
                : "+ Add Another Mobile"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function secPhoneNumSection() {
    return showAnotherNumber || props?.formData?.secondaryPhoneNumber ? (
      <Grid item xs={4} p={2}>
        <label htmlFor="secondaryPhoneNumber">Secondary Phone Number</label>
        <BusinessPhoneNumberTextbox
          id="secondaryPhoneNumber"
          className="form-label form-control"
          name="secondaryPhoneNumber"
          placeholder="Enter Secondary Phone Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.setFieldValue("secondaryPhoneNumber", e);
          }}
          value={formik.values.secondaryPhoneNumber}
          disabled={!isEditMode}
          phoneCode={countryOptions
            ?.filter((el) => el.countryId === formik.values.countryId)
            .map((item) => item.phoneCode)}
        />
        <InlineMessage error={formik.errors.secondaryPhoneNumber} />
      </Grid>
    ) : null;
  }

  function addSecPhoneNumSection() {
    return (!props?.formData?.userId &&
      !props?.formData?.secondaryPhoneNumber) ||
      (isEditMode && !props?.formData?.secondaryPhoneNumber) ? (
      <Grid item xs={4} p={2}>
        <div
          role="button"
          name="AddAnotherNumber"
          className="add-item"
          data-testid="AddAnotherNumber"
          onClick={() => {
            if (showAnotherNumber) {
              formik.setValues({
                ...formik.values,
                secondaryPhoneNumber: "",
              });
            }
            setShowAnotherNumber((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherNumber
                ? "- Remove Another Number"
                : "+ Add Another Number"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }
};

export default CreateEnterpriseModal;

function logoText(logo, formik) {
  return logo || formik.values.logoGcpPath ? (
    <span className="filename">Replace File</span>
  ) : (
    <span className="filename">Choose File</span>
  );
}

function primaryEmailAdmin(
  auth,
  isEditMode,
  editMode,
  formik,
  handleEditClick
) {
  return auth.getRole() === SystemUsersEnum.ADMIN ||
    auth.getRole() === SystemUsersEnum.SUPERADMIN ? (
    <Grid item xs={4} p={2} className="blue-field-section">
      <label htmlFor="primaryEmail">Primary Email Address</label>
      <MandatoryField />
      {isEditMode && !editMode && formik.values.primaryEmail !== "" && (
        <span onClick={handleEditClick}>
          <CreateOutlinedIcon className="cus-height-createOutlinedIcon" />
        </span>
      )}

      {PrimaryEmailFormik(formik, editMode, isEditMode)}
    </Grid>
  ) : (
    <Grid item xs={4} p={2} className="blue-field-section">
      <label htmlFor="primaryEmail">Primary Email Address</label>
      <MandatoryField />
      {formik.values.id ? (
        <a
          className="primary-email-url"
          href={`mailto:${formik.values.primaryEmail}`}
        >
          {formik.values.primaryEmail}
        </a>
      ) : (
        <>
          <input
            id="primaryEmail"
            name="primaryEmail"
            value={formik.values.primaryEmail}
            onChange={formik.handleChange}
            className="form-label form-control"
            type="text"
            placeholder="Enter Primary Email Address"
          />
          <InlineMessage error={formik.errors.primaryEmail} />
        </>
      )}
    </Grid>
  );
}

function PrimaryEmailFormik(formik, editMode, isEditMode) {
  return formik.values.id ? (
    <>
      {editMode ? (
        <div>
          <input
            id="primaryEmail"
            name="primaryEmail"
            value={formik.values.primaryEmail}
            onChange={formik.handleChange}
            className="form-label form-control"
            type="text"
            placeholder="Enter Primary Email Address"
            disabled={!isEditMode}
          />
          <InlineMessage error={formik.errors.primaryEmail} />
        </div>
      ) : (
        <a
          className="primary-email-url"
          href={`mailto:${formik.values.primaryEmail}`}
        >
          {formik.values.primaryEmail}
        </a>
      )}
    </>
  ) : (
    <>
      <input
        id="primaryEmail"
        name="primaryEmail"
        value={formik.values.primaryEmail}
        onChange={formik.handleChange}
        className="form-label form-control"
        type="text"
        placeholder="Enter Primary Email Address"
        disabled={!isEditMode}
      />
      <InlineMessage error={formik.errors.primaryEmail} />
    </>
  );
}

function defaultExpandedValue(history) {
  return history.location.pathname.includes("renew") ? "panel2" : "panel1";
}

function approvedStatusType(item, today) {
  return moment(item.startDate).format("YYYY-MM-DD") >
    moment(today).format("YYYY-MM-DD")
    ? "Approved/Inactive"
    : "Approved/Active";
}

function isExpiredStatus(item, today) {
  return (
    item.isStopped ||
    (item.expirationDate &&
      moment(item.expirationDate).format("YYYY-MM-DD") <
        moment(today).format("YYYY-MM-DD"))
  );
}
