import * as Yup from "yup";
const schema = {
  name: Yup.string().required("").min(2, "Too Short!").max(150, "Too Long!"),
  latestVersion: Yup.string()
    .required("")
    .min(1, "Too Short!")
    .max(25, "Too Long!"),
  baseValue: Yup.number()
    .typeError("Must be greater than 0")
    .when(["contentType"], {
      is: (contentType) => {
        if (contentType === 2) {
          return true; // validate and check next condition
        } else {
          return false;
        }
      },
      then: (sch) => sch.positive("Must be greater than 0").required(""),
      otherwise: Yup.number().typeError("").notRequired(),
    }),
  frequency: Yup.string()
    .typeError("Select Frequency")
    .when(["contentType"], {
      is: (contentType) => {
        if (contentType === 2) {
          return true; // validate and check next condition
        } else {
          return false;
        }
      },
      then: (sch) =>
        sch.oneOf(["1", "2", "3", "4"], "Select Frequency").required(""),
      otherwise: Yup.string().typeError("").notRequired(),
    }),
};

export const BundleSchema = Yup.object().shape(schema);
