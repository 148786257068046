import React from "react";
import { LOGO_HEIGHT } from "../constants";

function TextBlock(props) {
  const { item } = props;
  return (
    <span
      style={{
        width: item.Width,
        height: item.Height - 5,
        position: "absolute",
        top: item.SetTop + 4 + LOGO_HEIGHT - 60,
        left: item.SetLeft + 10,
        fontFamily: item.FontFamily,
        fontSize: item.FontSize,
        fontStyle: item.FontStyle,
        fontWeight: 700, // item.FontWeight,
        textAlign: item.TextAlignment,
        color: "black",
      }}
    >
      {item.Text}
    </span>
    // <span style=" width: @item.Width; height: @item.Height;position: absolute;top: @item.SetTop;left: @item.SetLeft;font-size:@fontSize">@item.Text</span>
  );
}

export default TextBlock;
