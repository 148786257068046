import React from "react";

export const VideoPreview = (props) => {
  const { selectedDocument, fileName } = props;
  const videoUrl = selectedDocument[0].uri.replace(
    "data:application/pdf",
    "data:video/mp4"
  );

  return (
    <div>
      <div className="viewer-header">
        <div
          id="file-name"
          data-testid="file-name"
          className="viewer-file-name"
        >
          {fileName}
        </div>
      </div>
      {selectedDocument && selectedDocument.length > 0 ? (
        <div className="pt-3">
          <video controls src={videoUrl} width="100%" height="auto">
            Your browser does not support the audio element.
          </video>
        </div>
      ) : (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      )}
    </div>
  );
};
