import React from "react";
import { number } from "yup";
import { LOGO_HEIGHT } from "../constants";

Line.propTypes = {
  top: number,
  left: number,
  height: number,
  width: number,
};

function Line(props) {
  const { line } = props;
  const whenXNotEqual = line.X1 > line.X2
    ? line.X1 - line.X2
    : line.X2 - line.X1;
  const width =
    line.X1 === line.X2
      ? 1
      : whenXNotEqual;
  const whenYNotEqual = line.Y1 > line.Y2
    ? line.Y1 - line.Y2
    : line.Y2 - line.Y1;
  const height =
    line.Y1 === line.Y2
      ? 1
      : whenYNotEqual;
  return (
    <div
      style={{
        position: "absolute",
        top: props.isBgImage
          ? `${line.Y1}px`
          : `${line.Y1 + LOGO_HEIGHT - 60}px`,
        left: props.isBgImage ? `${line.X1}px` : `${line.X1 + 10}px`,
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: line.Stroke,
      }}
    ></div>
  );
}

export default Line;
