import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MultiSelectDropDown from "../../../../common/MultiSelectDropDown/MultiSelectDropDown";
import { useFormik } from "formik";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AddEditCatalog.css";
import { a11yProps } from "../../../../common/TabPanel/a11yProps";
import TabPanel from "../../../../common/TabPanel/TabPanel";
import { CatalogSchema } from "../../../../common/ValidationSchema/CatalogSchema";
import {
  CATALOG,
  CATALOG_ASSOCIATED_CONTENT,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
} from "../../../../constants/api-constants";
import ApiServices from "../../../../services/ApiServices";
import { toast } from "react-toastify";
import { getFrequency, handleStateChange } from "../../../../utilities/common";
import { MODE, TABS } from "../../../../constants/common";
import { useCatalogContext } from "../Catalog.context";
import { useHistory, useParams } from "react-router";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import TagsInput from "../../../../common/TagsInput/TagsInput";
import {
  contentTypeOptions,
  DocumentType,
  ContentPublishStatus,
  publishCatalogConfirmationMessage,
} from "../../constants";
import { formatDate } from "../../../../utilities/formatter";
import CurrencyTextbox from "../../../../common/CurrencyTextbox/CurrencyTextbox";
import { useLoading } from "../../../../hooks/useLoader";
import { useMasterData } from "../../../../hooks/useMasterData";
import ListContent from "../ListContent/ListContent";
import { usePageMode } from "../../../../hooks/usePageMode";
import ManageSubscriber from "../../Content/ManageSubscriber/ManageSubscriber";
import Select from "@mui/material/Select";
import Confirmation from "../../../../common/Confirmation/Confirmation";

function AddEditCatalog(props) {
  const {
    id,
    distributorId,
    catalogId,
    mode,
    onSave,
    onNext,
    setSelectedSubTab,
    onEdit,
    query,
    onView,
    anchorEl,
    setAnchorEl,
    signUpApproval,
    setContentId,
    setContentMode,
  } = props;
  const history = useHistory();
  const page = usePageMode();
  const catalogContext = useCatalogContext();
  const { subTabId } = useParams();
  const timerId = useRef(null);
  const [selectedTab, setSelectedTab] = useState(TABS.CATALOG_INFORMATION);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { showLoader } = useLoading();
  const [contentList, setContentList] = useState([]);
  const [showEditAction, setShowEditAction] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [request, setRequest] = useState(null);
  const masterDataContext = useMasterData();

  const formik = useFormik({
    initialValues: {
      name: "",
      userId: id,
      lineOfBusinessList: [],
      countryId: 1,
      stateId: 1,
      latestVersion: "",
      marketingDescription: "",
      keywords: "",
      createdOn: null,
      updatedOn: null,
      numberOfEnrolledUser: 0,
      isPublic: false,
      isPreview: false,
      isDisplayPublic: false,
      oneTimeCost: 0,
      subscriptions: [],
      baseValue: 0,
      actualValue: 0,
      frequency: 0,
      status: 0,
      isRecurring: false,
      isFavorite: false,
      publishStatus: 0,
      contentId: 0,
      documentType: DocumentType.CATALOG,
      contentType: 0,
      previewType: 0,
      content: {},
      states: [],
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: CatalogSchema,
  });

  const [lobOptions, setLobOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    stateNames &&
    stateNames.length > 0 &&
    selected.length === stateNames.length - 1;
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  const frequencyOptions = [
    { value: 1, name: "Monthly" },
    { value: 2, name: "Quarterly" },
    { value: 3, name: "Semi Annual" },
    { value: 4, name: "Annual" },
  ];

  useEffect(() => {
    loadDropdownOptions();
  }, []);

  useEffect(() => {
    if (catalogId > 0) {
      getContentDetail();
      getContentList();
      // Hide Edit button for enterprise user
      if (history.location.pathname.includes("enterprisegroup")) {
        setShowEditAction(false);
      }
    } else {
      formik.resetForm();
      formik.setValues({
        ...formik.values,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
      });
      setSelectedKeywords([]);

      if (props.permissions?.includes("Update")) {
        setShowEditAction(true);
      } else {
        setShowEditAction(false);
      }
    }
    formik.validateForm();

    return () => {
      //Clearing a timeout
      clearTimeout(timerId.current);
    };
  }, [catalogId, subTabId]);

  useEffect(() => {
    setSelectedSubTab(subTabId);
    setSelectedTab(subTabId || TABS.CONTENT_INFORMATION);
  }, [subTabId]);

  useEffect(() => {
    if (catalogContext.copy) {
      formik.setValues({
        ...formik.values,
        publishStatus: 0,
        status: 0,
      });
    }
  }, [catalogContext.copy]);

  useEffect(() => {
    setIsEditMode(mode === MODE.EDIT || mode === MODE.ADD);
  }, [mode]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        catalogContext.setCatalogMode(MODE.LIST);
        catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
        history.push(`/distributor/${formik.values.userId}`);
      }
    };
  }, [history]);

  const loadDropdownOptions = async () => {
    showLoader(true);
    const response = masterDataContext.masterDataValue;
    showLoader(false);

    // LOB
    setLobOptions(
      response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          label: r.name,
        };
      })
    );
    // Countries
    setCountryOptions(response.result.countries);
    // States
    setAllStateOptions(response.result.states);
    showLoader(true);
    const subscriptionTypes = await ApiServices.httpGet(
      `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${id}`
    );
    showLoader(false);

    setSubscriptionOptions(
      subscriptionTypes?.result?.map((r) => {
        return {
          value: r.distributorSubscriptionId,
          label: r.name,
        };
      }) || []
    );
  };
  /*istanbul ignore next */
  const handleChangeDropDown = (event) => {
    const selectedStates = handleStateChange(
      event,
      stateNames,
      selected,
      setSelected,
      formik.values.states
    );
    formik.setFieldValue("states", selectedStates);
  };

  const getContentList = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${CATALOG_ASSOCIATED_CONTENT}/${id}/${catalogId}/${JSON.stringify(
          query
        )}`
      );
      showLoader(false);
      setContentList(adminData?.result?.records);
      props.setSelectionColumns();
    } catch (e) {
      showLoader(false);
    }
  };
  /*istanbul ignore next */
  const handleHandleChange = (event, newValue) => {
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("catalog")
      )}${TABS.CATALOG}/${newValue}`
    );
  };
  /*istanbul ignore next */
  const getContentDetail = async (value) => {
    try {
      const cId = value || catalogId;
      showLoader(true);
      const response = await ApiServices.httpGet(`${CATALOG}/${cId}`);
      showLoader(false);

      if (response.responseStatus.isSuccess) {
        timerId.current = setTimeout(() => {
          page.setDirty(false);
        }, 100);

        let savedStates = response.result.states?.map((el) => el.name);
        setSelected(savedStates?.length ? savedStates : ["Not Applicable"]);
        formik.setValues({
          ...formik.values,
          contentId: response.result.contentId,
          documentType: response.result.documentType,
          name: response.result.name,
          userId: response.result.userId,
          lineOfBusinessList: response.result.lineOfBusinessList.map((item) => {
            return { value: item.lineOfBusinessId, label: item.name };
          }),
          countryId: response.result.countryId,
          states: savedStates,
          latestVersion: response.result.latestVersion,
          marketingDescription: response.result.marketingDescription,
          keywords: response.result.contentKeywordList
            .map((item) => item.keyword)
            .join(","),
          numberOfEnrolledUser: response.result.numberOfEnrolledUser || 0,
          isPublic: response.result.isPublic,
          isPreview: response.result.isPreview,
          isDisplayPublic: response.result.isDisplayPublic,
          publishStatus: catalogContext.copy
            ? false
            : response.result.publishStatus,
          publishContentId: response.result.publishContentId || 0,
          createdOn: response.result.createdOn,
          updatedOn: response.result.updatedOn,
          mode: catalogContext.copy ? null : mode,
          status: response.result.status,
          oneTimeCost: response.result?.oneTimeCost?.toFixed(2) || 0,
          baseValue: response.result?.baseValue?.toFixed(2),
          isRecurring: response.result.isRecurring,
          frequency: response.result.frequency,
          contentType: response.result.contentType,
          reason: response.result.reason,
          previewType: response.result.previewType,
          content: response.result.content,
          actualValue: (
            response.result.baseValue * getFrequency(response.result.frequency)
          ).toFixed(2),
          subscriptions: response.result.subscriptions.map((r) => {
            return { value: r.distributorSubscriptionId, label: r.name };
          }),
        });
        setSelectedKeywords(
          response.result.contentKeywordList.map((item) => item.keyword)
        );
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      console.log("C");
      toast.error("Internal server error");
    }
  };

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
    let stateNameOptions = result && result.map((item) => item.name);
    stateNameOptions = [...stateNameOptions, "Not Applicable"];
    setStateNames(stateNameOptions);
  };

  useEffect(() => {
    populateStateOptions(formik.values.countryId);
  }, [formik.values.countryId, allStateOptions]);
  /*istanbul ignore next */
  const createContent = async (event, actionName) => {
    try {
      showLoader(true);
      let payload = { ...formik.values };
      if (catalogId > 0) {
        payload.contentId = catalogId;
      } else if (catalogContext.copy) {
        payload.contentId = catalogContext.sourceCatalogId;
      }
      let selectedStates = stateOptions?.filter((el) =>
        formik.values.states?.includes(el.name)
      );
      payload.states = selectedStates.includes("Not Applicable")
        ? []
        : selectedStates;

      payload.lineOfBusinessList = formik.values.lineOfBusinessList.map(
        (item) => ({
          lineOfBusinessId: item.value,
        })
      );
      payload.subscriptions = formik.values.subscriptions.map((item) => {
        return { distributorSubscriptionId: item.value };
      });
      payload.frequency = parseInt(payload.frequency);

      // republish and publish scenarios starts here
      let status;
      const publishStatus = formik.values.publishStatus;
      status = statusOnAction(actionName, publishStatus, status);
      payload.publishStatus = status;
      payload.publishContentId = formik.values.publishContentId || 0;
      payload.isEditAction = actionName === "Publish" ? false : true;
      // republish and publish scenarios ends here

      if (contentList) {
        let savedContentIds = contentList.map((el) => el.contentId);
        payload.content = {
          contentId: catalogId,
          userId: id,
          associateContentIds: [...savedContentIds],
        };
      }
      payload.countryId = Number(formik.values.countryId);
      payload.reason = formik.values.reason;
      payload.previewType = Number(formik.values.previewType);
      page.setDirty(false);

      await requestOnAction(actionName, payload);

      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const makeRequest = async (payload, actionName) => {
    const response =
      catalogId === 0 || catalogContext.copy
        ? await ApiServices.httpPost(CATALOG, payload)
        : await ApiServices.httpPut(CATALOG, payload);
    if (response.responseStatus?.isSuccess) {
      catalogContext.resetCopyCatalog();
      toast.success(response.responseStatus.description);
      if (actionName === "Next") {
        onNext(
          catalogId > 0 ? "Update" : "Save",
          response.result.contentId,
          "catalog"
        );
      } else {
        onSave("catalog", response.result);
      }
    } else {
      toast.error(response.responseStatus.error);
    }
  };
  /*istanbul ignore next */
  const setActualValue = (input, from) => {
    let baseVal = 0;
    let freqVal = 0;
    if (from === "frequency") {
      baseVal =
        formik.values.baseValue && formik.values.baseValue > 0
          ? parseFloat(formik.values.baseValue)
          : 0;
      freqVal = input ? getFrequency(input) : 0;
    } else {
      baseVal = input && input > 0 ? parseFloat(input) : 0;
      freqVal = formik.values.frequency
        ? getFrequency(formik.values.frequency)
        : 0;
    }
    let newVal = baseVal * freqVal;
    formik.setFieldValue("actualValue", newVal?.toFixed(2) || 0);
  };

  const handleCancelClick = () => {
    catalogContext.setSelectedTab(TABS.CATALOG);
    catalogContext.setCatalogMode(MODE.LIST);

    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("catalog")
      )}${TABS.CATALOG}`
    );
  };

  return (
    <div className="welcome">
      <Confirmation
        open={openConfirmation}
        title="Warning"
        description={publishCatalogConfirmationMessage}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={async () => {
          await makeRequest(request.payload, request.actionName);
          setOpenConfirmation(false);
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />
      <div className="addedit-content-main">
        <Tabs
          value={selectedTab}
          onChange={handleHandleChange}
          aria-label="Distributor Tabs"
          className="content-tabs"
        >
          <Tab
            label="Catalog Information"
            value={TABS.CATALOG_INFORMATION}
            className="content-sub-tabs"
            {...a11yProps("Catalog Information", "CatalogInformation")}
          />
          <Tab
            label="List of Content"
            value={TABS.CONTENT_LIST}
            className="content-sub-tabs"
            {...a11yProps("List of Content", "ContentList")}
          />
          <Tab
            label="Manage Subscribers"
            value={TABS.ENROLLED_USERS}
            className="content-sub-tabs"
            {...a11yProps("Manage Subscribers", "ManageSubscribers")}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={TABS.CATALOG_INFORMATION}>
          <Grid item xs={12}>
            <div className="welcome-main">
              <div className="welcome-message">
                <Typography className="montserrat-16-600">
                  Basic Information
                </Typography>
              </div>
              <div>
                {mode === MODE.VIEW && showEditAction && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={() => {
                      onEdit(formik.values, formik.values.documentType);
                    }}
                    disabled={
                      formik.values.publishStatus ===
                        ContentPublishStatus.MODIFIED &&
                      formik.values.status === 0
                    }
                  >
                    Edit
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  data-testid="copyBleIcon"
                  onClick={(e) => {
                    createContent(e, "Exit");
                  }}
                  disabled={!formik.isValid || !isEditMode}
                >
                  {catalogId === 0 || catalogContext.copy ? "Save" : "Update"} &
                  Exit
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  onClick={(e) => {
                    createContent(e, "Next");
                  }}
                  disabled={!formik.isValid || !isEditMode}
                >
                  {catalogId === 0 || catalogContext.copy ? "Save" : "Update"} &
                  Next
                </Button>

                <Button
                  data-testid="publish-btn"
                  variant="contained"
                  type="submit"
                  className="action-button mr-4"
                  disabled={
                    (signUpApproval?.approvalId > 0 &&
                      signUpApproval?.status !== 1) ||
                    !contentList?.length ||
                    !formik.isValid ||
                    !isEditMode
                  }
                  onClick={(e) => {
                    createContent(e, "Publish");
                  }}
                >
                  Publish
                </Button>
                <Button
                  data-testid="cancel-action-catalog"
                  variant="outlined"
                  className="action-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Grid>
          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} p={2}>
                <label htmlFor="name">Catalog Name</label>
                <MandatoryField />
                <input
                  id="name"
                  name="name"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.name}
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Catalog Name"
                  autocomplete="off"
                  maxLength={150}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.name} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="userId">Owner Id</label>
                <input
                  id="userId"
                  name="userId"
                  value={distributorId}
                  className="form-label form-control"
                  type="text"
                  placeholder="Owner Id"
                  maxLength={25}
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lineOfBusinessList">LOB</label>
                <MultiSelectDropDown
                  name="lineOfBusinessList"
                  id="lineOfBusinessList"
                  value={formik.values.lineOfBusinessList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      lineOfBusinessList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={lobOptions}
                  disabled={!isEditMode}
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="countryId">Country</label>
                <select
                  id="countryId"
                  name="countryId"
                  data-testid="country-dropdown"
                  value={formik.values?.countryId}
                  onChange={(e) => {
                    formik.setFieldValue("dirty", true);
                    formik.handleChange(e);
                    setSelected([]);
                  }}
                  className="form-control form-select"
                  disabled={!isEditMode}
                >
                  {countryOptions?.map((res) => {
                    return (
                      <option key={res.countryId} value={res.countryId}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="stateId">State/Province</label>
                <Select
                  displayEmpty
                  data-testid="states-dropdown"
                  id="stateId"
                  name="stateId"
                  labelId="mutiple-select-label"
                  className="form-select form-control select-user roles-dropdown"
                  multiple
                  onChange={(e) => {
                    handleChangeDropDown(e);
                    page.setDirty(true);
                  }}
                  value={selected}
                  disabled={!isEditMode}
                  renderValue={(selectedItem) => {
                    if (selected.length === 0) {
                      return "Select State";
                    }
                    return selectedItem.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < stateNames.length - 1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={isAllSelected ? "Unselect All" : "Select All"}
                    />
                  </MenuItem>
                  {stateNames.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          disabled={formik.values.states?.includes(
                            "Not Applicable"
                          )}
                          checked={selected.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="latestVersion">Latest Version</label>
                <MandatoryField />
                <input
                  id="latestVersion"
                  name="latestVersion"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.latestVersion}
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Latest Version"
                  autocomplete="off"
                  maxLength={25}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.latestVersion} />
              </Grid>
              {formik.values.reason && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="reason">Reason for Deactivation</label>
                  <input
                    id="reason"
                    name="reason"
                    value={formik.values.reason}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter reason"
                    autocomplete="off"
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="createdDate">Created Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.createdOn)}
                  className="form-label form-control"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedDate">Last Updated Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.updatedOn)}
                  className="form-label form-control"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={3}
                p={2}
                className="tag-input tag-input-search-keyword"
              >
                <label htmlFor="keywords">Search Keywords</label>
                <TagsInput
                  id="keywords"
                  name="keywords"
                  value={selectedKeywords}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      keywords: value.join(),
                    });
                    setSelectedKeywords(value);
                    page.setDirty(true);
                  }}
                  placeHolder="Enter Keyword then a Comma OR Enter key to add each additional keyword(s)"
                  className={!isEditMode && "disabled"}
                  disabled={!isEditMode}
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="marketingDescription">
                  Marketing Description
                </label>
                <div className="textarea-container">
                  <textarea
                    id="marketingDescription"
                    name="marketingDescription"
                    onChange={(e) => {
                      formik.handleChange(e);
                      page.setDirty(true);
                    }}
                    value={formik.values.marketingDescription}
                    className="form-label form-control bs-cus scrollable-input-content"
                    type="text"
                    placeholder="Enter Marketing Description"
                    autoComplete="off"
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED
                    }
                  />
                </div>
                <InlineMessage error={formik.errors.marketingDescription} />
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} p={2}>
                {showEditAction && (
                  <Accordion>
                    <AccordionSummary
                      className="accordion-content-account-information"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="montserrat-16-600">
                        Account Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div
                          className="menu-bar"
                          style={{
                            display: "flex",
                            height: "100%",
                            marginLeft: "0",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="numberOfEnrolledUsers"
                              >
                                Number of Enrolled Users
                              </label>
                              <input
                                id="numberOfEnrolledUsers"
                                name="numberOfEnrolledUsers"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  page.setDirty(true);
                                }}
                                value={formik.values.numberOfEnrolledUser}
                                className="form-label form-control input-border"
                                type="text"
                                placeholder=""
                                maxLength={25}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                label={
                                  <Typography className="montserrat-14-500">
                                    Available to Public
                                  </Typography>
                                }
                                checked={formik.values.isPublic}
                                name="Available Public"
                                data-testid="available-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isPublic: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={<Checkbox disabled={!isEditMode} />}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                checked={formik.values.isDisplayPublic}
                                name="Display to Public"
                                label={
                                  <Typography className="montserrat-14-500">
                                    Display to Public
                                  </Typography>
                                }
                                data-testid="display-to-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isDisplayPublic: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={<Checkbox disabled={!isEditMode} />}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="catalogType"
                              >
                                Catalog Type(Free/Paid)
                              </label>
                              <select
                                id="catalogType"
                                name="catalogType"
                                value={formik.values.contentType}
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    contentType: parseInt(e.target.value),
                                  });
                                  page.setDirty(true);
                                }}
                                className="form-control form-select input-border"
                                disabled={!isEditMode}
                              >
                                <option value="0">Select Catalog Type</option>
                                {contentTypeOptions?.map((res) => {
                                  return (
                                    <option key={res.value} value={res.value}>
                                      {res.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </Grid>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="subscriptions"
                              >
                                Associated to Subscription Type
                              </label>
                              <MultiSelectDropDown
                                name="subscriptions"
                                id="subscriptions"
                                value={formik.values.subscriptions}
                                onChange={(value) => {
                                  formik.setValues({
                                    ...formik.values,
                                    subscriptions: value,
                                  });
                                  page.setDirty(true);
                                }}
                                options={subscriptionOptions}
                                disabled={!isEditMode}
                              />
                            </Grid>

                            {formik.values.contentType === 2 && (
                              <Grid item xs={3} p={2} mt={1}>
                                <label
                                  className="extra-padding-input montserrat-14-500 label-gap"
                                  htmlFor="oneTimeCost"
                                >
                                  One Time Cost
                                </label>
                                <CurrencyTextbox
                                  id="oneTimeCost"
                                  name="oneTimeCost"
                                  onChange={(e) => {
                                    formik.setFieldValue("oneTimeCost", e || 0);
                                    page.setDirty(true);
                                  }}
                                  value={formik.values.oneTimeCost}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                            )}

                            {formik.values.contentType === 2 && (
                              <Grid
                                item
                                xs={3}
                                p={2}
                                mt={1}
                                className="currency-text"
                              >
                                <label
                                  className="extra-padding-input montserrat-14-500 label-gap"
                                  htmlFor="baseValue"
                                >
                                  Base Value
                                </label>
                                {formik.values.contentType ===
                                  contentTypeOptions[1].value && (
                                  <MandatoryField />
                                )}
                                <CurrencyTextbox
                                  id="baseValue"
                                  name="baseValue"
                                  onChange={(e) => {
                                    formik.setFieldValue("baseValue", e || 0);
                                    setActualValue(e || 0, "baseValue");
                                    page.setDirty(true);
                                  }}
                                  value={formik.values.baseValue}
                                  disabled={!isEditMode}
                                />
                                <InlineMessage
                                  error={formik.errors.baseValue}
                                />
                              </Grid>
                            )}

                            {formik.values.contentType === 2 && (
                              <Grid item xs={3} p={2} mt={1}>
                                <label
                                  className="extra-padding-input montserrat-14-500 label-gap"
                                  htmlFor="frequency"
                                >
                                  Frequency
                                </label>
                                {formik.values.contentType ===
                                  contentTypeOptions[1].value && (
                                  <MandatoryField />
                                )}
                                <select
                                  id="frequency"
                                  name="frequency"
                                  value={formik.values.frequency}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "frequency",
                                      e.target.value
                                    );
                                    setActualValue(e.target.value, "frequency");
                                    page.setDirty(true);
                                  }}
                                  onBlur={(e) => {
                                    formik.setFieldValue(
                                      "frequency",
                                      e.target.value
                                    );
                                    setActualValue(e.target.value, "frequency");
                                  }}
                                  className="form-control form-select input-border"
                                  disabled={!isEditMode}
                                >
                                  <option value="0">Select Frequency</option>
                                  {frequencyOptions?.map((res) => {
                                    return (
                                      <option key={res.value} value={res.value}>
                                        {res.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <InlineMessage
                                  error={formik.errors.frequency}
                                />
                              </Grid>
                            )}

                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                checked={formik.values.isRecurring}
                                name="Display to Public"
                                data-testid="display-to-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isRecurring: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={
                                  <Checkbox
                                    disabled={
                                      !isEditMode ||
                                      formik.values.publishStatus ===
                                        ContentPublishStatus.PUBLISHED
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      fontFamily: "Montserrat",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Recurring Type
                                  </Typography>
                                }
                                labelPlacement="end"
                                disabled={
                                  !isEditMode ||
                                  formik.values.publishStatus ===
                                    ContentPublishStatus.PUBLISHED
                                }
                              />
                            </Grid>

                            {formik.values.contentType === 2 && (
                              <Grid item xs={3} p={2} mt={1}>
                                <label
                                  className="montserrat-14-500 label-gap"
                                  htmlFor="oneTimeCost"
                                >
                                  Actual Value
                                </label>
                                <CurrencyTextbox
                                  id="actualValue"
                                  name="actualValue"
                                  value={formik.values.actualValue}
                                  disabled={true}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={TABS.CONTENT_LIST}>
          <ListContent
            userId={id}
            distId={formik.values.userId}
            contentId={catalogId}
            query={query}
            contentList={contentList}
            showColumnPopup={props.showColumnPopup}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            publishStatus={
              formik.values.publishStatus === ContentPublishStatus.PUBLISHED
            }
            onCancelTableColumnSelection={props.onCancelTableColumnSelection}
            onSaveTableColumnSelection={props.onSaveTableColumnSelection}
            setSelectionColumns={props.setSelectionColumns}
            catalogName={formik.values.name}
            catalogPublishStatus={formik.values.publishStatus}
            setSelectedSubTab={setSelectedSubTab}
            setContentId={setContentId}
            setContentMode={setContentMode}
            onEdit={onEdit}
            onView={onView}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={TABS.ENROLLED_USERS}>
          <ManageSubscriber
            contentId={catalogId}
            contentName={formik.values.name}
            bundleSubscriberStatus={formik.values.status}
            catalogPublishStatus={formik.values.publishStatus}
            from="catalog"
          />
        </TabPanel>
      </div>
    </div>
  );
  /*istanbul ignore next */
  function statusOnAction(actionName, publishStatus, status) {
    if (actionName === "Publish") {
      if (publishStatus === ContentPublishStatus.PUBLISHED) {
        status = ContentPublishStatus.MODIFIED;
      } else if (
        !publishStatus ||
        publishStatus === ContentPublishStatus.DRAFT
      ) {
        status = ContentPublishStatus.PUBLISHED;
      }
    } else {
      status = publishStatus || ContentPublishStatus.DRAFT;
    }
    return status;
  }
  /*istanbul ignore next */
  async function requestOnAction(actionName, payload) {
    if (actionName === "Publish") {
      if (
        contentList.length > 0 &&
        contentList.some((item) => item.publishStatus !== 1)
      ) {
        // Show confirmation in case any unpublishd content associated
        setRequest({
          payload: payload,
          actionName: actionName,
        });

        setOpenConfirmation(true);
      } else {
        await makeRequest(payload, actionName);
      }
    } else {
      await makeRequest(payload, actionName);
    }
  }
}

export default AddEditCatalog;
