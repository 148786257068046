import * as Yup from "yup";
import { REGULAR_EXPRESSION } from "../../constants/regular-expressions";

const phoneWithExtensionSchema = Yup.string()
  .test("phone-extension", "Incorrect format", (value) => {
    if (!value) return true; // Allow empty value
    const [phonePart, extensionPart] = value.split(",");
    return (
      REGULAR_EXPRESSION.PHONE_NUMBER.test(phonePart) &&
      (!extensionPart || /^\d{2,6}$/.test(extensionPart))
    );
  })
  .required("");

const secondaryPhoneWithExtensionSchema = Yup.string()
  .test("combined-validation", "Invalid number", (value, context) => {
    const phoneNumberRegex = REGULAR_EXPRESSION.PHONE_NUMBER;
    const [phonePart, extensionPart] = value ? value.split(",") : [null, null];
    const primaryPhoneNumber = context.parent.phoneNumber;

    if (!value) {
      return true;
    } // Allow empty value
    else if (phonePart === primaryPhoneNumber) {
      return false; // Must be different from primary phone number
    } else if (
      !phoneNumberRegex.test(phonePart) ||
      (extensionPart && !/^\d{2,6}$/.test(extensionPart))
    ) {
      return false; // Invalid format
    } else {
      return true; // Valid
    }
  })
  .nullable(true);

const CommonSchema = {
  mobile: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  fax: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  mobile1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  fax1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  distributorType: Yup.array().min(1, "Select Distributor").required(""),
  primaryEmail: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  secondaryEmail: Yup.string().email("Invalid email").nullable(true),
  phoneNumber: phoneWithExtensionSchema,
  secondaryPhoneNumber: secondaryPhoneWithExtensionSchema,
  billingPhoneNumber: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  zip: Yup.string()
    .matches(REGULAR_EXPRESSION.ZIP_CODE, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits")
    .nullable(true),
  websiteUrl: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Must be a valid url")
    .nullable(true),
  numberOfEmployees: Yup.string()
    .matches(REGULAR_EXPRESSION.ZIP_CODE, "Must be only digits")
    .nullable(true),
  numberOfOwners: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  businessYear: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  producers: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  taxID: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
};

const associateSchema = Yup.object().shape({
  distributorUserId: Yup.object().shape({
    value: Yup.number().moreThan(0, "Associated distributor is required"),
  }),
  associationId: Yup.string()
    .matches(REGULAR_EXPRESSION.ALPHANUMERIC, "Must be alpha numberic")
    .required("Association Id is required"),
  // distributorSubscriptionId: Yup.string(),
  frequencyType: Yup.string()
    .equals(["1", "2", "3", "4"], "Select Frequency")
    .required("Select Frequency"),
  distributorSubscriptionId: Yup.number()
    .typeError("Invalid Subscription ID")
    .required("")
    .moreThan(0, ""),
});

const distributorSubscriptionDomainsSchema = Yup.object().shape({
  name: Yup.string().required(""),
  baseValue: Yup.number()
    .positive("Must be greater than 0")
    .typeError("")
    .required(""),
  frequency: Yup.array().min(1, "Select Frequency"),
});

export const SignupSchema = Yup.object().shape({
  ...CommonSchema,
});

export const UserSignupSchema = Yup.object().shape({
  ...CommonSchema,
  roles: Yup.array().min(1, "Select Roles").required(""),
});

export const DistributorSchema = Yup.object().shape({
  ...CommonSchema,

  // roles: Yup.array().min(1, "Select Roles").required(""),
  distributorSubscriptionDomains: Yup.array().of(
    distributorSubscriptionDomainsSchema
  ),
  brandName: Yup.string().required("").nullable(true),
});

export const EnterpriseGroupUserSchema = Yup.object().shape({
  ...CommonSchema,
  associateDistributors: Yup.array().of(associateSchema),
  roles: Yup.array().min(1, "Select Roles").required(""),
  brandName: Yup.string().required("").nullable(true),
});

export const InternalUserSchema = Yup.object().shape({
  ...CommonSchema,
  roles: Yup.array().min(1, "Select Roles").required(""),
});

export const SubscriberSchema = Yup.object().shape({
  ...CommonSchema,
  subscription: Yup.object().shape({
    startDate: Yup.date().typeError("Invalid Date"),
    // .min(moment().subtract(1, "days"), "Start Date can't be a past date"),
    // endDate: Yup.date()
    //   .typeError("Invalid Date")
    //   .when("startDate", (startDate, schema) =>
    //     startDate
    //       ? schema.min(
    //           moment(startDate).add(1, "days"),
    //           "End Date should be greater than Start Date"
    //         )
    //       : schema
    //   ),
    // subscriptionId: Yup.number()
    //   .typeError("Invalid Subscription ID")
    //   .moreThan(0, ""),
  }),
  // egSubscriptions: Yup.array().of(
  // Yup.object().shape({
  // startDate: Yup.date().required("Start date is required"),
  // endDate: Yup.date()
  //   .min(Yup.ref("startDate"), "End Date should be greater than Start Date")
  //   .required("End date is required"),
  // })
  // ),
});
