import * as Yup from "yup";
import { REGULAR_EXPRESSION } from "../../constants/regular-expressions";
const schema = {
  typeOfContent: Yup.string().required("Please Select"),
  name: Yup.string().required("").min(2, "Too Short!").max(150, "Too Long!"),
  formNumber: Yup.string().when("typeOfContent", {
    is: "2",
    then: Yup.string().required("").nullable().max(150, "Too Long!"),
    otherwise: Yup.string().max(150, "Too Long!"),
  }),
  latestVersion: Yup.string()
    .required("")
    .min(1, "Too Short!")
    .max(25, "Too Long!"),
  webLink: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Incorrect Format")
    .nullable(true),
};

export const ContentSchema = Yup.object().shape(schema);
