import { useEffect, useState } from "react";
import { Button, Box, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { MandatoryField } from "../../../common/MandatoryFieldsIcon/MandatoryField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ApiServices from "../../../services/ApiServices";
import {
  ASSOCIATE_SUBSCRIBER,
  MANAGE_SUBSCRIBER_LIST,
  SUBSCRIBER_LIST_ID,
} from "../../../constants/api-constants";
import InlineMessage from "../../../common/InlineMessage/InlineMessage";
import { toast } from "react-toastify";
import MultiSelectCheckboxSearchableDropdown from "../../../common/Modal/MultiSelectCheckboxSearchableDropdown/MultiSelectCheckboxSearchableDropdown";
import { useLoading } from "../../../hooks/useLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 630,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AssociateSubscribers = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [subscriberList, setSubscriberList] = useState([]);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const validStartDate = moment(startDate, "YYYY-MM-DD", true).isValid();
  const validLastDate = moment(endDate, "YYYY-MM-DD", true).isValid();
  const { showLoader } = useLoading();
  const query = {
    searchText: "",
    SortType: "desc",
    SortColumn: "userId",
    IgnorePaging: true,
    Filter: {
      userStatus: [0, 1],
      ContentSubscriberStatus: [0, 1],
    },
  };

  useEffect(() => {
    dropDownData();
  }, []);
  /*istanbul ignore next */
  const dropDownData = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${MANAGE_SUBSCRIBER_LIST}/${props.selectedContentIds}/${JSON.stringify(
          query
        )}`
      );
      showLoader(false);
      const associatedSubscribers =
        adminData?.result?.records?.map((el) => el.userId) || [];
      const data = await ApiServices.httpGet(SUBSCRIBER_LIST_ID);
      const unassociatedSubscribers =
        data.result.filter(
          (subscriber) =>
            associatedSubscribers.filter((x) => x === subscriber.userId)
              .length < props.selectedContentIds.length
        ) || [];

      const options = unassociatedSubscribers.map((res) => {
        return {
          label: res.name,
          id: res.userId,
        };
      });

      setSubscriberList(options);
    } catch (error) {
      console.log("error", error);
    }
  };

  /*istanbul ignore next */
  const addMassSubscriptions = async () => {
    let payload = [];
    for (let i = 0; i < props.selectedContentIds.length; i++) {
      for (let j = 0; j < selectedValue.length; j++) {
        let data = {
          status: 1,
          contentId: props.selectedContentIds[i],
          subscriberId: selectedValue[j].id,
          associateFrom: startDate,
          associateTo: endDate === "" ? null : endDate,
        };
        payload.push(data);
      }
    }
    /*istanbul ignore next */
    const toggle = await ApiServices.httpPost(ASSOCIATE_SUBSCRIBER, payload);
    if (toggle.responseStatus.isSuccess) {
      toast.success(toggle.responseStatus.description);
      props.setClose();
      setEndDate("");
      props.clearSelection();
    } else {
      toast.error(toggle.responseStatus.description);
    }
  };
  /*istanbul ignore next */
  const isValid = () => {
    return (
      selectedValue.length &&
      startDate !== null &&
      startDate !== "" &&
      startDate >= currentDate &&
      // !validLastDate &&
      validStartDate &&
      (endDate ? startDate <= endDate : true) &&
      (endDate ? validLastDate : true)
    );
  };
  /*istanbul ignore next */
  const whenValidStartDate =
    startDate < currentDate ? (
      <InlineMessage error="Can't be a past date" />
    ) : (
      ""
    );
  /*istanbul ignore next */
  const whenStartDate = !validStartDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    whenValidStartDate
  );
  /*istanbul ignore next */
  const whenSDateExeeds = !validLastDate ? (
    <InlineMessage error="Invalid Date" />
  ) : (
    ""
  );
  /*istanbul ignore next */
  const whenEndDateNotEmpty =
    startDate > endDate ? (
      <InlineMessage error="Should be greater than or equal to Start date" />
    ) : (
      whenSDateExeeds
    );
  return (
    <div className="">
      <Modal
        open={true}
        onClose={() => props.setClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ border: "none" }}>
          <form>
            <div className="modal-header">
              <div
                className="modal-header-left"
                data-testid="internal-user-modal"
              >
                Associate Subscriber
              </div>
              <div
                className="modal-header-right"
                onClick={() => {
                  /*istanbul ignore next */
                  props.setClose();
                  setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                }}
                style={{ cursor: "pointer" }}
              ></div>
            </div>
            <div className="modal-body manage-subscriber-body">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className="manage-subs-dropdown subscriber-serachable-dropdown"
                >
                  <label htmlFor="searchSubscriber">
                    Search subscriber to associate
                  </label>
                  <MandatoryField />
                  <MultiSelectCheckboxSearchableDropdown
                    width="100%"
                    multiple={true}
                    setData={(value) => {
                      /*istanbul ignore next */
                      setSelectedValue(value);
                    }}
                    name="searchSubscriber"
                    value={selectedValue}
                    id="searchSubscriber"
                    className="multiple-associate-subscriber-dropdown"
                    options={subscriberList}
                    placeholder="Search and Select"
                    showSelectAllOption={true}
                  />
                  {!selectedValue.length && <InlineMessage error="" />}
                </Grid>
                <Grid item xs={6} className="desktop-date-picker">
                  <label htmlFor="from">Associated From</label>
                  <MandatoryField />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      disablePast
                      className="form-label form-control date-field"
                      inputFormat="MM/DD/YYYY"
                      data-testid="startdate"
                      minDate={moment(new Date()).format("YYYY-MM-DD")}
                      max="9999-12-31"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        /*istanbul ignore next */
                        e && e._d
                          ? setStartDate(moment(e._d).format("YYYY-MM-DD"))
                          : setStartDate("");
                      }}
                      renderInput={(params) => (
                        <TextField id="from" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                  {!startDate ? <InlineMessage error="" /> : whenStartDate}
                </Grid>
                <Grid item xs={6} className="desktop-date-picker">
                  <label htmlFor="from">Associated Till</label>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      disablePast
                      className="form-label form-control date-field"
                      inputFormat="MM/DD/YYYY"
                      data-testid="enddate"
                      minDate={moment(startDate).format("YYYY-MM-DD")}
                      max="9999-12-31"
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        /*istanbul ignore next */
                        e && e._d
                          ? setEndDate(moment(e._d).format("YYYY-MM-DD"))
                          : setEndDate("");
                      }}
                      renderInput={(params) => (
                        <TextField id="from" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                  {endDate === "" ? "" : whenEndDateNotEmpty}
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="modal-switch">
                <div className="hide-switch"></div>
              </div>
              <div>
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  data-testid="cancel-mass-association"
                  onClick={() => {
                    /*istanbul ignore next */
                    props.setClose();
                    setEndDate("");
                  }}
                >
                  Cancel
                </Button>

                <Button
                  data-testid="save-mass-association"
                  className="action-button"
                  type="button"
                  variant="contained"
                  disabled={!isValid()}
                  onClick={(e) => addMassSubscriptions()}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AssociateSubscribers;
