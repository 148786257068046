import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./ConfirmApproval.css";
import React, { useEffect, useState } from "react";
import MultiSelectDropDown from "../../common/MultiSelectDropDown/MultiSelectDropDown";
import PhoneNumberTextbox from "../../common/PhoneNumberTextbox/PhoneNumberTextbox";
import SearchableDropDown from "../../common/SearchableDropDown/SearchableDropdown";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiServices from "../../services/ApiServices";
import {
  APPROVAL_LIST,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
  ENTERPRISE_GROUP_USER,
  PARENT_DISTRIBUTOR,
  UPDATE_APPROVAL,
} from "../../constants/api-constants";
import { formatDate } from "../../utilities/formatter";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { toast } from "react-toastify";
import { execeptionMessage } from "../../constants/common";
import { useLoading } from "../../hooks/useLoader";
import { AssociationStatusEnum } from "../../common/Modal/AssociationStatusEnum";
import ConfirmationWithReason from "../../common/ConfirmationWithReason/ConfirmationWithReason";
import { Link } from "react-router-dom";
import { useApprovalContext } from "../ApprovalRequests/Approval.context";
import { usePageMode } from "../../hooks/usePageMode";
import { useMasterData } from "../../hooks/useMasterData";
import CurrencyTextbox from "../../common/CurrencyTextbox/CurrencyTextbox";
import { FrequencyEnum } from "../../constants/SystemUsers";

/*istanbul ignore next */
const ConfirmApproval = (props) => {
  const approvalContext = useApprovalContext();
  const { showLoader } = useLoading();
  const page = usePageMode();
  const [setMemberShip] = useState("");
  const [state, setState] = useState({});
  const [parentDropdown, setParentDropdown] = useState([]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([]);
  const [membershipId, setMembershipId] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [areaOptions, setAreaOptions] = useState([]);
  const [career, setCareer] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [isEditMode, setIsEditMode] = useState(approvalContext.isEditMode);
  const [selectedValue, setSelectedValue] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [associatonStatus, setAssociatonStatus] = useState(null);
  const [recurring, setRecurring] = useState(false);

  const [expirationDate, setExpirationDate] = useState(null);
  const [userData, setUserData] = useState({});
  const [expanded, setExpanded] = useState("panel1");
  const [isValid, setIsValid] = useState(true);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const { requestId } = useParams();
  const masterDataContext = useMasterData();

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const frequencyOptions = [
    {
      value: 1,
      label: "Monthly",
      months: 1,
    },
    {
      value: 2,
      label: "Quarterly",
      months: 3,
    },
    {
      value: 3,
      label: "Semi-Annual",
      months: 6,
    },
    {
      value: 4,
      label: "Annual",
      months: 12,
    },
  ];

  const getApprovalDetail = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${APPROVAL_LIST}/${requestId}`
      );
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        setState(response.result);
        setMembershipId(response.result.associationId);
        setSubscriptionType(response.result.distributorSubscriptionId);
        setAssociatonStatus(response.result.associatonStatus);
        setFrequency(response.result.frequencyType);
        setRecurring(response.result.isRecurring);
        setExpirationDate(response.result.expirationDate);
        setIsEditMode((prevState) => {
          return !response.result.isUserInactive && prevState;
        });
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (err) {
      toast.error(execeptionMessage);
    }
  };

  const updateApproval = async (associatonStatus, rejectReason) => {
    try {
      showLoader(true);
      const payload = {
        requestId: state.approvalId, //state.requestId,
        enterpriseUserId: state.enterpriseUserId,
        distributorUserId: state.distributorUserId,
        associationId: membershipId, // state.associationId,
        frequencyType: Number(frequency), // state.frequencyType,
        expirationDate: expirationDate,
        distributorSubscriptionId: subscriptionType, // state.distributorSubscriptionId,
        rejectReason:
          associatonStatus === AssociationStatusEnum.Rejected
            ? rejectReason
            : "",
        isRecurring: recurring,
        isCreate: true,
        associatonStatus: associatonStatus,
      };
      const response = await ApiServices.httpPost(UPDATE_APPROVAL, payload);
      page.setDirty(false);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
      return response;
    } catch (err) {
      toast.error(execeptionMessage);
      return err;
    }
  };

  const populateDropdownParent = async () => {
    try {
      const response = await ApiServices.httpGet(PARENT_DISTRIBUTOR);
      setParentDropdown(response.result);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const populateDropdownSubscriptionType = async () => {
    try {
      const response = await ApiServices.httpGet(
        `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${state.distributorUserId}`
      );
      setSubscriptionTypeOptions(
        response.result.map((item) => {
          return {
            value: item.distributorSubscriptionId,
            frequencyTypeOptions: item.frequency,
            label: item.name,
            baseValue: item.baseValue,
          };
        })
      );
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const setMasterData = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      showLoader(false);
      setEnterpriseTypeOptions(
        response.userTypeOptions.map((item) => {
          return { value: item.enterpriseTypeId, label: item.name };
        })
      );
      setAreaOptions(response.areas);
      setCareer(response.carrierRepresents);
      setIndustry(response.industryGroups);
      setTechnology(response.technologies);
    } catch (e) {
      showLoader(false);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleIsValid = (type, valid) => {
    if (type === "frequency") {
      setIsValid(
        (membershipId ? true : false) && subscriptionType > 0 && valid
      );
    } else if (type === "membershipId") {
      setIsValid(
        valid &&
          subscriptionType > 0 &&
          frequency > 0 &&
          getSubscriptionfees(frequency, subscriptionType) > 0 &&
          (expirationDate ? true : false)
      );
    } else {
      setIsValid(valid);
    }
  };

  const history = useHistory();

  useEffect(() => {
    setMasterData();
    getApprovalDetail();
  }, [requestId]);

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      userDetails();
      populateDropdownParent();
      populateDropdownSubscriptionType();
    }
  }, [state]);

  const userDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${ENTERPRISE_GROUP_USER}/${state.enterpriseUserId}`
    );
    showLoader(false);
    setUserData(data.result);
  };

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onApprove = async () => {
    await updateApproval(AssociationStatusEnum.Approved);
    setTimeout(() => {
      history.push("/approvals");
    }, 3000);
  };

  const onReject = () => {
    handleConfirmationOpen(
      "Reject",
      "Are you sure you want to reject this request?"
    );
  };

  const getSubscriptionfees = (frequency, subscriptionType) => {
    if (!frequency || !subscriptionType) return 0;
    let arrObj1 = subscriptionTypeOptions.find(
      (obj) => obj.value === parseInt(subscriptionType)
    );
    let arrObj2 = frequencyOptions.find(
      (obj) => obj.value === parseInt(frequency)
    );
    return arrObj1?.baseValue * arrObj2?.months || 0;
  };

  return (
    <div className="approval-container">
      <div className="approval-basic-details">
        <div>
          <div className="approval-page-title">Basic Information</div>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="distributor-breadcrumbs"
          >
            <Link underline="hover" color="inherit" to="/approvals">
              Approval
            </Link>

            <Typography color="text.primary" className="last-node">
              Basic Information
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="modal-footer">
          <Button
            className="action-button mr-4"
            variant="contained"
            onClick={() => history.push("/approvals")}
          >
            Cancel
          </Button>

          {!state.isUserInactive && isEditMode ? (
            <Button
              className="action-button mr-4"
              variant="contained"
              onClick={async () => {
                await updateApproval(AssociationStatusEnum.Pending);
                setTimeout(() => {
                  history.push("/approvals");
                }, 3000);
              }}
              disabled={!isValid}
            >
              Save
            </Button>
          ) : (
            <Button
              className="action-button mr-4"
              variant="contained"
              onClick={onEdit}
              disabled={state.isUserInactive}
            >
              Edit
            </Button>
          )}

          <Button
            className="action-button mr-4"
            variant="contained"
            color="success"
            onClick={onApprove}
            disabled={!(!state.isUserInactive && isEditMode && isValid)}
          >
            Approve
          </Button>

          <Button
            className="action-button mr-4"
            variant="contained"
            color="error"
            onClick={onReject}
            disabled={!(!state.isUserInactive && isEditMode && isValid)}
          >
            Reject
          </Button>
        </div>
      </div>

      <Grid container spacing={2} paddingLeft={4} marginTop={2}>
        <Grid item xs={3} p={2}>
          <div>Full Name</div>
          <div className="approval-basic-info">
            {userData?.firstName + " " + userData?.lastName}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Email</div>
          <div className="approval-basic-info">{userData?.email}</div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>System Generated ID</div>
          <div className="approval-basic-info">
            {userData?.systemGeneratedId}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Registration Date</div>
          <div className="approval-basic-info">
            {formatDate(userData?.createdOn)}
          </div>
        </Grid>
      </Grid>

      <div className="parent-div">
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>Basic Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <div>
              <div className="modal-body">
                <Grid container spacing={2} className="blue-field-section">
                  <>
                    <Grid item xs={4} p={2}>
                      <label htmlFor="systemGeneratedId">
                        System Generated Id
                      </label>
                      <input
                        id="systemGeneratedId"
                        name="systemGeneratedId"
                        className="form-label form-control"
                        type="text"
                        placeholder="Enter First Name"
                        disabled
                        value={userData?.systemGeneratedId}
                        maxLength={25}
                      />
                    </Grid>
                    <Grid item xs={4} p={2}>
                      <label htmlFor="createdOn">Registration Date</label>
                      <input
                        id="createdOn"
                        name="createdOn"
                        className="form-label form-control"
                        type="text"
                        placeholder="Enter Last Name"
                        disabled
                        maxLength={25}
                        value={formatDate(userData?.createdOn)}
                      />
                    </Grid>
                    <Grid item xs={4} p={2}></Grid>
                  </>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="firstName">First Name</label>
                    <MandatoryField />
                    <input
                      id="firstName"
                      name="firstName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter First Name"
                      disabled
                      maxLength={25}
                      value={userData?.firstName}
                    />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="lastName">Last Name</label>
                    <MandatoryField />
                    <input
                      id="lastName"
                      name="lastName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      disabled
                      maxLength={25}
                      value={userData?.lastName}
                    />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="distributorType">Enterprise Type</label>
                    <MandatoryField />
                    <MultiSelectDropDown
                      name="distributorType"
                      id="distributorType"
                      disabled
                      value={userData?.enterpriseTypes?.map((item) => {
                        return {
                          value: item.enterpriseTypeId,
                          label: item.name,
                        };
                      })}
                      options={enterpriseTypeOptions}
                    />
                  </Grid>

                  {props?.routeData?.userTypeId === 7 && (
                    <Grid
                      item
                      sx={4}
                      p={2}
                      className="blue-field-section serachable-dropdown"
                    >
                      <label htmlFor="DistributorIDEnterpriseID">
                        Distributor ID / Enterprise ID
                      </label>
                      <MandatoryField />
                      <SearchableDropDown
                        name="DistributorIDEnterpriseID"
                        id="DistributorIDEnterpriseID"
                        placeholder="Select Enterprise/Distributor Id"
                        disabled
                      />
                    </Grid>
                  )}

                  <Grid item xs={8} p={2} className="blue-field-section">
                    <label htmlFor="primaryEmail">Primary Email Address</label>
                    <MandatoryField />
                    <a
                      className="primary-email-url"
                      href={`mailto:${userData?.email}`}
                    >
                      {userData?.email}
                    </a>
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section"></Grid>
                  <Grid item xs={8} p={2} className="blue-field-section">
                    <label htmlFor="secondaryEmail">
                      Secondary Email Address
                    </label>
                    <input
                      id="secondaryEmail"
                      name="secondaryEmail"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Secondary Email Address"
                      disabled
                      value={userData?.secondaryEmail}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="areas">Major Areas</label>
                    <MultiSelectDropDown
                      id="areas"
                      name="areas"
                      isMulti
                      disabled
                      value={userData?.areas?.map((res) => {
                        return {
                          value: res.areaId,
                          label: res.name,
                        };
                      })}
                      options={areaOptions.map((res) => {
                        return {
                          value: res.areaId,
                          label: res.name,
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="producers">Number of producers</label>
                    <input
                      id="producers"
                      name="producers"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Number of producers"
                      disabled
                      value={userData?.producers}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="carrierRepresents">
                      Carriers represented
                    </label>
                    <MultiSelectDropDown
                      id="carrierRepresents"
                      name="carrierRepresents"
                      menuShouldBlockScroll
                      isMulti
                      value={userData?.carrierRepresents?.map((res) => {
                        return {
                          value: res.carrierRepresentId,
                          label: res.name,
                        };
                      })}
                      options={career.map((res) => {
                        return {
                          value: res.carrierRepresentId,
                          label: res.name,
                        };
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="industryGroups">
                      Industry Groups/ Membership
                    </label>
                    <MultiSelectDropDown
                      id="industryGroups"
                      name="industryGroups"
                      disabled
                      value={userData?.industryGroups?.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      options={industry.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="technologies">
                      Technology being used now
                    </label>
                    <MultiSelectDropDown
                      id="technologies"
                      name="technologies"
                      disabled
                      value={userData?.technologies?.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      options={technology.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="businessYear">Years in Business</label>
                    <input
                      id="businessYear"
                      name="businessYear"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Years in Business"
                      disabled
                      value={userData?.businessYear}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="annualRevenue">Annual revenue</label>
                    <select
                      id="annualRevenue"
                      name="annualRevenue"
                      className="form-control form-select"
                      disabled
                      value={userData?.annualRevenueId}
                    >
                      <option>Select Revenue</option>
                      <option value={1}>0-100k</option>
                      <option value={2}>101 to 500k</option>
                      <option value={3}>500k to 1,250,000</option>
                      <option value={4}>1,250,000 to 2,500,000</option>
                      <option value={5}>2,500,000 to 5,000,000</option>
                      <option value={6}>5,000,000 to 10,000,000</option>
                      <option value={7}>10,000,000 to 25,000,000"</option>
                      <option value={8}> &#62; 25,000,000</option>
                    </select>
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="numberOfEmployees">
                      Number of employees
                    </label>
                    <input
                      id="numberOfEmployees"
                      name="numberOfEmployees"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Number of employees"
                      disabled
                      value={userData?.numberOfEmployees}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="numberOfOwners">
                      Number of principals / owners
                    </label>
                    <input
                      id="numberOfOwners"
                      name="numberOfOwners"
                      className="form-label form-control"
                      type="text"
                      value={userData?.numberOfOwners}
                      placeholder="Enter Number of principals / owners"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="websiteUrl">Website URL</label>
                    <input
                      id="websiteUrl"
                      name="websiteUrl"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Website URL"
                      disabled
                      value={userData?.websiteUrl}
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="industryGroups">
                      Industry Groups/ Membership
                    </label>
                    <MultiSelectDropDown
                      id="industryGroups"
                      name="industryGroups"
                      isMulti
                      value={userData?.industryGroups?.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      options={industry.map((item) => {
                        return {
                          value: item.industryGroupId,
                          label: item.name,
                        };
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <MandatoryField />
                    <PhoneNumberTextbox
                      id="phoneNumber"
                      className="form-label form-control"
                      name="phoneNumber"
                      placeholder="Enter Business Phone Number"
                      value={userData?.phoneNumber}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="secondaryPhoneNumber">
                      Secondary Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="secondaryPhoneNumber"
                      className="form-label form-control"
                      name="secondaryPhoneNumber"
                      placeholder="Enter Phone Number"
                      value={userData?.secondaryPhoneNumber}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="businessName">Business Name</label>
                    <input
                      id="businessName"
                      name="businessName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Business Name"
                      value={userData?.businessName}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="taxID">Tax Id</label>
                    <input
                      id="taxID"
                      name="taxID"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Tax ID"
                      value={userData?.taxID}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <label htmlFor="mobile">Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile"
                      className="form-label form-control"
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      value={userData?.mobile}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="mobile1">Alternate Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile1"
                      className="form-label form-control"
                      name="mobile1"
                      placeholder="Enter Alternate Mobile Number"
                      value={userData?.mobile1}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <label htmlFor="fax">Fax</label>
                    <PhoneNumberTextbox
                      id="fax"
                      className="form-label form-control"
                      name="fax"
                      placeholder="Enter Fax"
                      value={userData?.fax}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="mobile1">Alternate Fax</label>
                    <PhoneNumberTextbox
                      id="fax1"
                      className="form-label form-control"
                      name="fax1"
                      placeholder="Enter Alternate Fax"
                      value={userData?.fax1}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContact">Billing First Name </label>
                    <input
                      id="billingContact"
                      name="billingContact"
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing First Name"
                      value={userData?.billingContact}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingContactLastName">
                      Billing Last Name{" "}
                    </label>
                    <input
                      id="billingContactLastName"
                      name="billingContactLastName"
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing Last Name"
                      value={userData?.billingContactLastName}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingPhoneNumber">
                      Billing Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="billingPhoneNumber"
                      className="form-label form-control"
                      name="billingPhoneNumber"
                      placeholder="Enter Billing Phone Number"
                      value={userData?.billingPhoneNumber}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine1">
                      Billing Address Line 1
                    </label>
                    <input
                      id="billingAddressLine1"
                      name="billingAddressLine1"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 1 "
                      value={userData?.billingAddressLine1}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="billingAddressLine2">
                      Billing Address Line 2
                    </label>
                    <input
                      id="billingAddressLine2"
                      name="billingAddressLine2"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 2"
                      value={userData?.billingAddressLine2}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="zip">Zip Code</label>
                    <input
                      id="zip"
                      name="zip"
                      className="form-label form-control"
                      type="text"
                      maxLength={5}
                      placeholder="Enter Zip Code"
                      value={userData?.zipCode}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      id="city"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter City"
                      value={userData?.city}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="state">State</label>
                    <input
                      id="state"
                      name="state"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter State"
                      disabled
                      value={userData?.state}
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <label htmlFor="countryId">Country</label>
                    <select
                      id="countryId"
                      name="countryId"
                      className="form-control form-select"
                      disabled
                      value={userData?.countryId}
                    >
                      <option value={1}>USA</option>
                    </select>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="blue-field-section">
                  <Grid item sx={4} p={2} className="serachable-dropdown">
                    <label htmlFor="enterpriseGroupIdDropdown">
                      Parent Distributor (if any)
                    </label>
                    <SearchableDropDown
                      options={parentDropdown?.map((res) => {
                        return {
                          label: res.name,
                          id: res.userId,
                        };
                      })}
                      name="enterpriseGroupIdDropdown"
                      id="enterpriseGroupIdDropdown"
                      setData={(value) => {
                        setMemberShip(`INCHANTED_${value.id}`);
                        setSelectedValue([value]);
                      }}
                      value={selectedValue}
                      disabled
                      placeholder="Select Parent Distributor"
                    />
                  </Grid>
                  <Grid item sx={4} p={2}>
                    <label htmlFor="enterpriseGroupIdDropdown">
                      Membership ID
                    </label>
                    <input
                      disabled
                      id="membershipId"
                      name="membershipId"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Membership ID"
                      value={userData?.associationId}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>
              Association Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} className="blue-field-section">
              <Grid item xs={4} p={2}>
                <label htmlFor="membershipId">
                  Unique Identification Number
                </label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <input
                  id="membershipId"
                  name="membershipId"
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Unique Identification Number"
                  disabled={
                    !isEditMode ||
                    associatonStatus === AssociationStatusEnum.Approved
                  }
                  maxLength={25}
                  value={membershipId}
                  onChange={(e) => {
                    page.setDirty(true);
                    setMembershipId(e.target.value);
                    handleIsValid(
                      "membershipId",
                      e.target.value ? true : false
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} p={2}>
                <label htmlFor="subscriptionType">Subscription Type</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <select
                  id="subscriptionType"
                  name="subscriptionType"
                  value={subscriptionType}
                  onChange={(e) => {
                    page.setDirty(true);
                    setSubscriptionType(Number(e.target.value));
                    setExpirationDate("");
                    setFrequency(0);
                    handleIsValid("subscriptionType", false);
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    associatonStatus === AssociationStatusEnum.Approved
                  }
                >
                  <option selected="true" disabled="true" value="0">
                    Select Membership
                  </option>
                  {subscriptionTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={4} p={2}>
                <label htmlFor="membershipId">Frequency</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <select
                  value={frequency}
                  onChange={(e) => {
                    page.setDirty(true);
                    let addMonths = FrequencyEnum[Number(e.target.value)].count;
                    let today = new Date();
                    let expDate = new Date(
                      today.setMonth(today.getMonth() + addMonths)
                    );
                    expDate.setDate(expDate.getDate() - 1);
                    setExpirationDate(moment(expDate).format("YYYY-MM-DD"));
                    setFrequency(e.target.value);
                    handleIsValid("frequency", true);
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    associatonStatus === AssociationStatusEnum.Approved
                  }
                >
                  <option selected="true" disabled="true" value="0">
                    Select Frequency
                  </option>
                  {frequencyOptions
                    .filter((option) =>
                      subscriptionTypeOptions
                        ?.find(
                          (st) => Number(st.value) === Number(subscriptionType)
                        )
                        ?.frequencyTypeOptions.includes(Number(option.value))
                    )
                    .map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
              </Grid>
              <Grid item xs={4} p={2}>
                <label htmlFor="associationId">Subscription Fees</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <CurrencyTextbox
                  id="associationId"
                  name="associationId"
                  value={getSubscriptionfees(frequency, subscriptionType)}
                  disabled
                />
              </Grid>

              <Grid item xs={3} p={2}>
                <label htmlFor="recurring">Recurring</label>
                <FormControlLabel
                  className={
                    "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm"
                  }
                  label={<Typography>Is Recurring</Typography>}
                  checked={recurring}
                  name="recurring"
                  data-testid="recurring-checkbox"
                  onChange={(e) => {
                    page.setDirty(true);
                    setRecurring(e.target.checked);
                  }}
                  control={<Checkbox />}
                  disabled={
                    !isEditMode ||
                    associatonStatus === AssociationStatusEnum.Approved
                  }
                />
              </Grid>

              <Grid item xs={3} p={2} className="desktop-date-picker">
                <label htmlFor="membershipId">Expiration Date</label>
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disablePast
                    className="form-label form-control date-field"
                    inputFormat="MM/DD/YYYY"
                    data-testid="startdate"
                    disabled
                    max="9999-12-31"
                    type="date"
                    value={
                      expirationDate === "" ? null : formatDate(expirationDate)
                    }
                    onChange={(e) => {
                      page.setDirty(true);
                      e && e._d
                        ? setExpirationDate(moment(e._d).format("YYYY-MM-DD"))
                        : setExpirationDate("");
                    }}
                    renderInput={(params) => (
                      <TextField id="from" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2} p={2}>
                <label htmlFor="membershipId">Association Type</label>
                <input
                  id="membershipId"
                  name="membershipId"
                  className="form-label form-control"
                  type="text"
                  placeholder="Association Type"
                  disabled={true}
                  value={
                    state?.isNewAssociation
                      ? "New Association"
                      : "Existing Association"
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <ConfirmationWithReason
          open={confirmation}
          handleClose={handleConfirmationClose}
          title={confirmationMessage}
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Reject"
          showReason={true}
          onConfirm={async (e, rejectReason) => {
            await updateApproval(AssociationStatusEnum.Rejected, rejectReason);
            setTimeout(() => {
              handleConfirmationClose();
              history.push("/approvals");
            }, 3000);
          }}
          onCancel={() => {
            handleConfirmationClose();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmApproval;
