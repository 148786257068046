import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import CheckBox from "./CheckBox/CheckBox";
import Line from "./Line/Line";
import RadioButton from "./RadioButton/RadioButton";
import TextBlock from "./TextBlock/TextBlock";
import TextBox from "./TextBox/TextBox";
import "./XFDLViewer.css";

function XFDLViewer(props) {
  const { document, fileName, formData, requiredFields } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const [canvas, setCanvas] = useState(document.Canvas[0]);

  useEffect(() => {
    setCanvas(document.Canvas[pageIndex]);
  }, [pageIndex, formData]);

  useEffect(() => {
    setPageIndex(0);
  }, [fileName]);
  /*istanbul ignore next */
  const onNextHandler = () => {
    setPageIndex(pageIndex + 1);
  };
  /*istanbul ignore next */
  const onPreviousHandler = () => {
    setPageIndex(pageIndex - 1);
  };

  const checkItemInArray = (name) => {
    let value =
      formData
        ?.filter((el) => el.key === name)
        ?.map((item) => item.value)?.[0] || "";
    return value;
  };

  const checkIsRequired = (name) => {
    let value =
      requiredFields
        ?.filter((el) => el.key === name)
        ?.map((item) => item.value)?.[0] || false;
    return Boolean(value);
  };

  const whenNotImgData = !canvas?.BackgroundRefImage ? (
    <div style={{ position: "absolute", top: 10, left: 10 }}>
      <img src="/images/AfaveLogo.png" alt="logo" height="35px" width="120px" />
    </div>
  ) : null;
  return (
    <>
      <div
        className="viewer-header"
        style={{ width: `${document.FormViewSize}px` }}
      >
        <div
          id="file-name"
          data-testid="file-name"
          className="viewer-file-name"
        >
          {fileName}
        </div>
        <div className="viewer-navigation">
          <IconButton
            variant="outlined"
            className="viewer-navigation-button"
            onClick={onPreviousHandler}
            disabled={pageIndex === 0}
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            variant="outlined"
            className="viewer-navigation-button"
            onClick={onNextHandler}
            disabled={pageIndex === document.TotalPages - 1}
          >
            <NavigateNext />
          </IconButton>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <div></div>
        {canvas && (
          <div
            className="viewer-container"
            style={{
              height: `${canvas.Height}px`,
              width: `${document.FormViewSize}px`, //"980px",
              background: `url("data:image/jpeg;base64,${canvas.BackgroundRefImage}") 0% 0% / cover no-repeat lightgray`,
            }}
          >
            {pageIndex === 0 && (
              <>
                {canvas.imageData ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 0,
                      height: 35,
                      width: 120,
                      backgroundImage: URL(
                        "data:image/png;base64",
                        canvas.imageData
                      ),
                    }}
                  ></div>
                ) : (
                  whenNotImgData
                )}
              </>
            )}

            {canvas.Lines.map((line) => (
              <Line key={line.Name} line={line} />
            ))}

            {canvas.TextBlocks.map((textBlock) =>
              textBlock.Width !== 0 && textBlock.Height !== 0 ? (
                <TextBlock
                  key={textBlock.Name}
                  item={textBlock}
                  isBgImage={canvas.BackgroundRefImage ? true : false}
                />
              ) : null
            )}

            {canvas.TextBoxes.map((textBox) => (
              <TextBox
                key={textBox.Name}
                item={textBox}
                defaultValue={checkItemInArray(textBox.Name)}
                isRequired={checkIsRequired(textBox.Name)}
                changeHandler={props.changeHandler}
                isBgImage={canvas.BackgroundRefImage ? true : false}
              />
            ))}

            {canvas.CheckBoxes.map((checkBox) => (
              <CheckBox
                key={checkBox.Name}
                item={checkBox}
                defaultValue={checkItemInArray(checkBox.Name)}
                isRequired={checkIsRequired(checkBox.Name)}
                checkBoxHandler={props.checkBoxHandler}
                isBgImage={canvas.BackgroundRefImage ? true : false}
              />
            ))}

            {canvas.RadioButtons.map((radio) => (
              <RadioButton
                key={radio.Name}
                item={radio}
                defaultValue={checkItemInArray(radio.Name)}
                isRequired={checkIsRequired(radio.Name)}
                changeHandler={props.changeHandler}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default XFDLViewer;
